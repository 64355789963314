import { thoughtIsReply } from "../../../../Firebase/ReplyUtilities"
import { PostMap } from "../../../../ReactContexts/PostContext"
import { TextPost } from "../../../../Types/types"

export default class AdminComputer {
  //   allThoughtsMap: PostMap
  //   constructor(allThoughtsMap: PostMap) {
  //     this.allThoughtsMap = allThoughtsMap ?? {}
  //   }

  static getTotalThoughts(allThoughtsMap: PostMap, filterOptions?: ThoughtFilterOptions) {
    return (
      Object.values(allThoughtsMap).filter(
        (thought: TextPost) => !filterOptions?.excludeReplies || !thoughtIsReply(thought)
      ).length ?? 0
    )
  }

  /**
   * This doesn't work yet
   * @param allThoughtsMap
   * @param filterOptions
   * @returns
   */
  static mostRecentTenThoughts(allThoughtsMap: PostMap, filterOptions?: ThoughtFilterOptions) {
    const filteredThoughts = Object.values(allThoughtsMap).filter(
      (thought: TextPost) => !filterOptions?.excludeReplies || !thoughtIsReply(thought)
    )

    const sorted = filteredThoughts.sort(timestampSorter)
    const recentTen = sorted.slice(0, 10)
    return recentTen
  }
}

interface ThoughtFilterOptions {
  excludeReplies?: true
}

const timestampSorter = (thoughtA: TextPost, thoughtB: TextPost): number => {
  return (thoughtB.timestamp ?? 0) - (thoughtA.timestamp ?? 0)
}
