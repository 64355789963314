// const sgMail = require("@sendgrid/mail")
import emailjs from "@emailjs/browser"
import { logEvent } from "firebase/analytics"
import { analytics } from "./Components/App"
import { thoughtIsReply } from "./Firebase/ReplyUtilities"
import { TextPost } from "./ReactContexts/PostContext"

// Triggers an emailjs function to send a beautiful invite. Pretty blank email, most of the formatting is done ahead of time.
// https://dashboard.emailjs.com/admin/templates/pij4u1w
export const sendBeautifulInvite = (toEmail: string, body: string) => {
  emailjs.send(
    "service_d0v34fj",
    "template_u7blj2i",
    {
      toEmail: toEmail,
      body: body,
    },
    "VFXAIIMaqEenjBrC9"
  )
}

/**
 *
 */
export const sendEmailIfFirstReply = (parentThought: TextPost, replyThought: TextPost) => {
  // Don't allow get notifications from yourself
  if (parentThought.authorId === replyThought.authorId) return
  //check that it's the first reply and that the parent thought is a root thought
  if (thoughtIsReply(parentThought)) return

  //if it is the first reply, send email
  return sendFirstReplyEmail(
    parentThought.authorName,
    replyThought.authorName,
    parentThought.text,
    replyThought.text,
    parentThought.authorEmail,
    replyThought.authorEmail,
    parentThought.id,
    replyThought.id
  )
}

export function sendFirstReplyEmail(
  toName: string = "anonymous",
  fromName: string = "anonymous",
  toThought: string = "text hidden",
  fromThought: string = "text hidden",
  toEmail: string,
  fromEmail: string = "forum",
  toIDthought: string,
  fromIDthought: string
) {
  if (toEmail && fromEmail)
    emailjs
      .send(
        "default_service",
        // Need to change template!
        "template_icmpkas",
        {
          toName,
          fromName,
          toThought,
          fromThought,
          toEmail,
          fromEmail,
          toIDthought,
          fromIDthought,
        },
        "VFXAIIMaqEenjBrC9"
      )
      .then(() => {
        // congratulateOnMatch()
      })

  logEvent(analytics, "send-reply-email", {
    timestamp: Date.now(),
    nameA: toName,
    nameB: fromName,
    thoughtA: toThought,
    thoughtB: fromThought,
    emailA: toEmail,
    emailB: fromEmail,
    IDthoughtA: toIDthought,
    IDthoughtB: fromIDthought,
  })
}
