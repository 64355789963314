import { Dispatch, useContext, useEffect, useState } from "react"
import PostContext, { TextPost } from "../../../../../ReactContexts/PostContext"
import "./ExpandedSection.css"
import getSuggestedThoughts, { SuggestedThoughtInfo } from "../../../GetSuggestedThoughts"
import { TabViewStatusOptions, ThoughtDisplayType } from "../../Thought"
import MegaColumn from "./MegaColumn/MegaColumn"
import { useParams } from "react-router-dom"
import RepliesTab from "../Components/RepliesTab"
import "./MegaColumn/MegaColumn.css"
import SuggestedThoughtsTab from "../Components/SuggestedThoughtsTab/SuggestedThoughtsTab"
import { getReplyThoughtsFromParent, thoughtIsReply } from "../../../../../Firebase/ReplyUtilities"
import { getRecordedSuggestionsPerThought } from "../../../../../Logic/SuggestionsPerThoughtLogic"
import { replyIsSuggestable } from "../../../../../Logic/Utilities"

export const numSuggestionsToShowAtFirst = 3
export const suggMax = 50

const ExpandedSection = ({
  thought,
  lineage,
  tabViewStatus,
  parentType,
  setTabViewStatus,
}: {
  thought: TextPost
  lineage: string[]
  setTabViewStatus: Dispatch<any>
  tabViewStatus: TabViewStatusOptions
  parentType: ThoughtDisplayType
}) => {
  const { posts } = useContext(PostContext)
  const { placeId } = useParams()

  // RElATED THOUGHTS CALCULATION
  const [relatedThoughts, setRelatedThoughts] = useState<SuggestedThoughtInfo[]>([])

  const recordedSuggs = getRecordedSuggestionsPerThought(thought, posts)

  const [numAdditionalSuggestions, setNumAdditionalSuggestions] = useState(10)
  const [suggestionsLoading, setSuggestionsLoading] = useState(true)
  const [replies, setReplies] = useState([])

  useEffect(() => {
    async function fetchReplies() {
      const fetchedReplies = await getReplyThoughtsFromParent(thought)
      setReplies(fetchedReplies)
    }

    fetchReplies()
  }, [thought])

  useEffect(() => {
    //We want to include seen thoughts here, just gray them out
    getSuggestedThoughts([thought], placeId).then((val) => {
      setRelatedThoughts(val.slice(0, numAdditionalSuggestions).slice(0, suggMax))
      setSuggestionsLoading(false)
    })
  }, [thought, posts, numAdditionalSuggestions])

  const defaultSuggestionsVisible = true
  return (
    <div
      key={"root-" + thought.id}
      className={"expanded-related-container " + parentType}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="header-row">
        <div
          className={tabViewStatus === "replies" ? "selected" : ""}
          onClick={() => setTabViewStatus("replies")}
        >
          Replies {replies.length}
        </div>
        {thoughtIsReply(thought) && !replyIsSuggestable(thought) ? (
          <></>
        ) : (
          <div
            className={tabViewStatus === "related-threads" ? "selected" : ""}
            onClick={() => setTabViewStatus("related-threads")}
          >
            Suggestions {recordedSuggs.length}
          </div>
        )}
      </div>
      {tabViewStatus === "replies" ? (
        <RepliesTab {...{ parentThought: thought, suggestions: recordedSuggs }} />
      ) : (
        <></>
      )}
      {tabViewStatus === "related-threads" ? (
        <SuggestedThoughtsTab
          {...{
            suggestedThoughts: recordedSuggs,
            thought,
            lineage,
            suggestionsLoading,
            numAdditionalSuggestions,
            setNumAdditionalSuggestions,
            defaultVisible: defaultSuggestionsVisible,
          }}
        />
      ) : (
        <></>
      )}
      {/* separate replies section about to be deprecated */}
      {tabViewStatus === "communal" ? (
        <MegaColumn
          {...{
            thought: thought,
            lineage: lineage,
            suggestedThoughts: relatedThoughts,
            suggestionsLoading: suggestionsLoading,
            numAdditionalSuggestions: numAdditionalSuggestions,
            setNumAdditionalSuggestions: setNumAdditionalSuggestions,
            defaultSuggestionsVisible: defaultSuggestionsVisible,
          }}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default ExpandedSection
