import { useContext, useEffect, useState } from "react"
import { Editor, Transforms } from "slate"
import { backendWriter } from "../App"
import PostContext from "../../ReactContexts/PostContext"
import { getSlateEditorString } from "../Editor/SlateEditorString"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Detail, { suggBatchFn } from "../Detail/Detail"
import { analytics } from "../App"
import { logEvent } from "firebase/analytics"
import ThoughtList from "../Feed/ThoughtList"
import "./AppBody.css"
import AuthContext from "../../ReactContexts/AuthContext"
import Orientation from "../Orientation/Orientation"
import { Survey } from "../Survey/Survey"
import loader from "../../assets/plexus-final2.gif"

const thoughtWordMin = 8

const textIsValidForThought = (text: string): boolean => {
  return text.split(" ").length >= thoughtWordMin
}

const AppBody = ({
  editor,
  isOriented,
  alerts,
}: {
  editor: Editor
  isOriented: boolean
  alerts: any
}) => {
  const { posts, loadingPosts } = useContext(PostContext)
  const { person } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()
  const urlIncludesOrientation = location.pathname.includes("orientation")

  /**
   * Add a new post and reset the editor
   */
  const appendPost = () => {
    //Add post to firebase
    const textOfNewPost = getSlateEditorString(editor).trim()

    // Disallow submissions of fewer than k (10) words
    if (!textIsValidForThought(textOfNewPost)) {
      window.alert(`Plexus has a ${thoughtWordMin}-word minimum!`)
      return
    }
    if (!textOfNewPost) return

    //if don't have a name
    if (!backendWriter.personName) {
      const name = window.prompt("What's your name?")
      window.alert("Text davey (646-352-2244) that there's an issue with your name!")
      backendWriter.setName(name)
    }
    const { postWithId, addPostPromise } = backendWriter.addPost(
      editor.children,
      textOfNewPost,
      placeId
    )
    if (!postWithId) return

    //then also add suggestions, hacky way to ask if pc has been set already (wait a second...)
    addPostPromise.then(() => {
      setTimeout(() => suggBatchFn(postWithId, placeId), 2500)
      //  backendWriter.findAndRecordSuggestionsForThought(postWithId, placeId)

      // setTimeout(() => backendWriter.findAndRecordSuggestionsForThought(postWithId, placeId), 3000)
    })

    // Reset the editor
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    })

    logEvent(analytics, "append_post", { text: textOfNewPost, uid: person?.uid }) //firebase logging
    navigate(`${"/p/" + (placeId ?? "forum")}/${postWithId.id}`) //focus on this particular thought now
    setTimeout(() => {
      setEditorEmpty(true)
    }, 2000)
  }
  const [editorEmpty, setEditorEmpty] = useState(true)
  useEffect(() => {
    setSearchPanelOpen(editorEmpty)
  }, [editorEmpty])
  //close at the beginning

  const [searchPanelOpen, setSearchPanelOpen] = useState(true)
  const { thoughtId, placeId } = useParams()
  Survey(person, alerts)

  //which toggle/tab/section is selected (of thoughts/suggestions/matches)
  return !isOriented || urlIncludesOrientation ? (
    <Orientation />
  ) : (
    <>
      <div className="big-panel column">
        {thoughtId ? (
          posts[thoughtId] ? (
            <Detail providedPost={posts[thoughtId]} postId={thoughtId} key={thoughtId} />
          ) : (
            <div>loading thought...</div>
          )
        ) : (
          <>
            <div>
              {searchPanelOpen ? (
                !loadingPosts ? (
                  <>
                    <ThoughtList
                      {...{ editorEmpty }}
                      editor={editor}
                      appendPost={appendPost}
                      key={(thoughtId ?? placeId) + "-feed"}
                      {...{
                        enableWe: false,
                        meVsWe: true,
                      }}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    {/* loading... */}
                    <img src={loader} style={{ width: "100px" }} id="loader"></img>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default AppBody
