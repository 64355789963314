import { PostButtonDefault } from "../../../../AppBody.tsx/PostButton"
import EditorContainer from "../../../../Editor/EditorContainer"
import "./PostSection.css"

const PostSection = ({ editor, handleChange, appendPost, showSubmit, customPrompt }) => {
  return (
    <div className={"post-section column panel"}>
      <EditorContainer
        {...{
          editor,
          handleEnter: appendPost,
          handleChange: handleChange,
          publishedAlready: false,
          defaultText: customPrompt,
        }}
      />
      {true ? (
        <div
          className="row buttons-in-editor"
          style={{ visibility: showSubmit ? "visible" : "hidden" }}
        >
          <PostButtonDefault {...{ appendPost }} />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default PostSection
