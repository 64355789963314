import "./ThoughtFooter.css"
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { getFullEdgeMapArr, getReplyThoughtsFromParent } from "../../../../Firebase/ReplyUtilities"
import PostContext, { ConnectionKind, TextPost } from "../../../../ReactContexts/PostContext"
import { TabViewStatusOptions, ThoughtDisplayType } from "../Thought"
import { ChatCircleText, Plugs, PlugsConnected } from "phosphor-react"
import { backendWriter } from "../../../App"
import { SingleConnectionUpdateForAPerson } from "../../../../Types/types"
import AuthContext from "../../../../ReactContexts/AuthContext"

/**
 * This is the thought footer that appears underneath a thought that the active person has either (1) written or (2) connected to.
 * For now, going to disable this from showing up underneath unconnected thoughts.
 * @param param0
 * @returns
 */
const ThoughtFooter = ({
  tabViewStatus,
  post,
  setShowReplyField,
  parentThought,
}: {
  tabViewStatus: TabViewStatusOptions
  post: TextPost
  setShowReplyField: Dispatch<SetStateAction<boolean>>
  parentThought?: TextPost
}) => {
  const { posts } = useContext(PostContext)
  const { person } = useContext(AuthContext)

  //how many replies so far
  const [replies, setReplies] = useState([])

  useEffect(() => {
    async function fetchReplies() {
      const fetchedReplies = await getReplyThoughtsFromParent(post)
      setReplies(fetchedReplies)
    }

    fetchReplies()
  }, [post])

  const relatedAlready = parentThought ? checkIfAlreadyRelated(parentThought, post) : false

  //how many thoughts you've swiped on so far with this thought
  return (
    <div className="focused-thought-bottom-tabs-container first-tab">
      {/* Commenting out footer so connections can be our first step */}
      {/* comments */}
      <div
        className={
          "focused-thought-tab-text"
          // +
          // (tabViewStatus === "replies" ? " selected-option" : "")
        }
        title={"Reply to " + post.authorName + "'s thought"}
        onClick={(e) => {
          e.stopPropagation()
          setShowReplyField((x) => !x)
          // if (!thoughtExpandable) return
          // e.stopPropagation()
          // setTabs(tabViewStatus, setTabViewStatus, "communal")
          // backendWriter.updateLastExpandedByAuthor(post.id, post.authorId)
        }}
      >
        <ChatCircleText size={20} />
        {/* spacer */}
        <div style={{ width: "2pt" }}></div>
        {replies.length}
      </div>
      &nbsp;
      {/* relates */}
      {parentThought ? (
        <div
          className={
            "focused-thought-tab-text" + (tabViewStatus === "replies" ? " selected-option" : "")
          }
          onClick={(e) => {
            e.stopPropagation()
            //relate these thoughts

            //relate these two
            if (!relatedAlready) {
              const connection: SingleConnectionUpdateForAPerson = {
                targetAuthorId: post.authorId,
                targetThoughtId: post.id,
                edgeKind: ConnectionKind.RELATE,
                edgeAuthorId: person.uid,
                sourceId: parentThought.id,
                authorId: parentThought.authorId,
                timestamp: Date.now(),
              }
              backendWriter.addConnection(connection)
            } else {
              //need to write a new delete function that's more particular than the current one
              // backendWriter.deleteLink()
              backendWriter.deleteRelateConnections(parentThought, post.id)
            }
          }}
          title={"Connect " + post.authorName + "'s thought (liking with context)"}
        >
          {!relatedAlready ? <Plugs size={24} /> : <PlugsConnected size={24} weight={"fill"} />}

          <div style={{ width: "1pt" }}></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ThoughtFooter

const checkIfAlreadyRelated = (sourceThought: TextPost, targetThought: TextPost) => {
  const suggestionEdges = getFullEdgeMapArr(
    { ...sourceThought.connections?.outbound } ?? {},
    ConnectionKind.RELATE
  )
  const defined = suggestionEdges.filter((e) => e.targetThoughtId === targetThought.id)
  return defined?.length > 0
}
