import { useState } from "react"
import { thoughtIsReply } from "../../../../../../Firebase/ReplyUtilities"
import RepliesTab from "../../Components/RepliesTab"
import SuggestedThoughtsTab from "../../Components/SuggestedThoughtsTab/SuggestedThoughtsTab"
import "./MegaColumn.css"

const MegaColumn = ({
  thought,
  lineage,
  suggestedThoughts,
  suggestionsLoading,
  numAdditionalSuggestions,
  setNumAdditionalSuggestions,
  defaultSuggestionsVisible,
}) => {
  const isReply = thoughtIsReply(thought)
  const includeTitle: true | undefined = true
  //whether replies are empty
  const [empty, setEmpty] = useState<boolean>(true)

  return (
    <div className="mega-column-container">
      {empty ? (
        <></>
      ) : (
        <div
          style={{
            background: "white",
            width: "100vw",
            height: "100vh",
            zIndex: "2",
            position: "fixed",
            left: 0,
            top: 0,
            marginTop: "-1%",
            opacity: 0.5,
          }}
        ></div>
      )}
      <RepliesTab
        parentThought={thought}
        includeTitle={includeTitle}
        {...{ empty, setEmpty, suggestions: suggestedThoughts }}
      />

      {false && isReply ? (
        <></>
      ) : (
        <SuggestedThoughtsTab
          {...{
            // need to change extra vs. suggestd here if reenable mega column
            suggestedThoughts: suggestedThoughts,
            thought: thought,
            lineage: lineage,
            suggestionsLoading: suggestionsLoading,
            setNumAdditionalSuggestions: setNumAdditionalSuggestions,
            numAdditionalSuggestions: numAdditionalSuggestions,
            defaultVisible: defaultSuggestionsVisible,
          }}
        />
      )}
    </div>
  )
}

export default MegaColumn
