import { child, DatabaseReference, get, push, remove, set, update } from "firebase/database"
import { Descendant, Node } from "slate"
import {
  BidirectionalEdgeMap,
  ConnectionKind,
  ConnectionMap,
  ConnectionUpdatesForAPerson,
  DirectionalEdgeMap,
  PersonThoughtInteractionType,
  PersonThoughtInteractions,
  PostMap,
  SingleConnectionUpdateForAPerson,
  SinglePersonThoughtInteraction,
  TextPost,
  TextPostWithoutId,
  EdgeInfoWithConnectionData,
  AncestorThought,
  EdgeInfo,
  EdgeKind,
} from "../ReactContexts/PostContext"
//@ts-ignore
import { signInWithPopup } from "firebase/auth"
import { auth, provider } from "../Components/App"
import { getOpenAISummaryForConversation, getOpenAiTextEmbedding } from "./OpenAi"
import {
  addFamilyEdges,
  backendUpsertEmbedding,
  deleteThoughtById,
} from "./FirebaseFunctionPointers"
import { getSlateValueFromText } from "../Components/HackyAdmin/components/sendServerData"
import {
  getEdgeArrFromThought,
  getEdgeMapFromThought,
  getFlatEdgeMapFull,
  getFullEdgeMapArr,
  getReplyThoughtsFromParent,
} from "./ReplyUtilities"
import { usernamex } from "../Components/PrivateRoute/PrivateRoute"
import { sendEmailIfFirstReply } from "../SendEmail"
import getSuggestedThoughts, {
  SuggestedThoughtInfo,
  SUGGESTIONS_SIMILARITY_THRESHOLD,
} from "../Components/Feed/GetSuggestedThoughts"
import { getEdgeAuthor } from "../Logic/ConnectionLogic"
import { windowUpdate } from "../Types/types"
import { generateChatId, StupidMessage } from "../Components/PeopleSection/Chat/Chat"
import { abbreviate, replyIsSuggestable } from "../Logic/Utilities"

export const getTextFromChildren = (children: Descendant[]) => {
  return children.reduce((text: string, nextChild: Descendant) => {
    return text + " " + Node.string(nextChild)
  }, "")
}
class FirebaseWriter {
  // NOTE: made all of these optional, not 100% sure this doesn't break type checking downstream
  databaseRef: any
  personId?: string
  personEmail?: string
  personName?: string
  todaysPrompt?: string
  constructor(
    databaseRef: any,
    personId?: string,
    personEmail?: string,
    personName?: string,
    prompt?: string
  ) {
    this.databaseRef = databaseRef
    this.personId = personId
    this.personEmail = personEmail
    this.personName = personName
    this.todaysPrompt = prompt
  }

  initialize(
    databaseRef: any,
    personId?: string,
    personEmail?: string,
    personName?: string,
    prompt?: string
  ) {
    this.databaseRef = databaseRef
    this.personId = personId
    this.personEmail = personEmail
    this.personName = personName
    this.todaysPrompt = prompt
    if (personName) this.recordPersonName(personName)
  }

  setName(name: string) {
    if (name) {
      this.personName = name
      this.recordPersonName(name)
    }
  }
  setEmail(email: string) {
    this.personEmail = email
    this.recordPersonEmail(email)
  }
  setPersonId(id: string) {
    this.personId = id
  }
  ensureSignedIn(condition: boolean, callback: Function) {
    if (condition) return callback()
    else
      signInWithPopup(auth, provider).then((e) => {
        const { email, displayName, uid } = e.user
        if (email) this.personEmail = email
        this.personName = displayName ?? email ?? "anon"

        if (this.personName)
          window.alert("Good to have you here, " + this.personName.split(" ")[0] + ".")
        if (uid) {
          this.personId = uid
          return callback()
        }
      })
    return
  }
  visitPost(postId: string) {
    if (!postId) return
    const postListRef = child(this.databaseRef, "nodes/" + postId + "/visitors")
    update(postListRef, { [this.personId]: true })
  }

  addPostInternal(
    post: TextPostWithoutId,
    placeId: string,
    id?: string
  ): addPostInternalResult | undefined {
    const postListRef = child(this.databaseRef, "nodes")
    const newPostRef = id ? child(postListRef, id) : push(postListRef)
    const newId = newPostRef.key
    if (newId) {
      //add the embedding in there
      const textWithoutBreaks = post.text
        .split("\n")
        .filter((e) => e)
        .join("\n")

      const textForEmbedding = `${post.prompt ? post.prompt.replace("?", ".") + " " : ""}${
        post.text
      }`
        .split("\n")
        .filter((e) => e)
        .join("\n")
      const postWithId: TextPost = {
        ...post,
        id: newId,
        text: textWithoutBreaks,
      }
      const addPostPromise = set(newPostRef, postWithId)
      if (!newPostRef.key) return undefined
      //Add to posts

      //openai
      addPostPromise
        .then((e) => this.ensureEmbeddings(textForEmbedding, post.authorId, newId, placeId))
        .then((e) => {
          //get mischievous title for the thought
          this.addTitleForThought(postWithId.id, postWithId.text)
        })

      return { postWithId, newPostRef, addPostPromise }
    }
  }

  addTitleForThought(thoughtId: string, thoughtText: string) {
    const summary = getOpenAISummaryForConversation(
      `Here's a three-to-five-word description of the subject of "${thoughtText}" that uses only the author's words:`
    )
    summary.then((e) => {
      const cleanText = (text: string) => {
        const firstClean = text.trim().replace("\n", "").replace('"', "").replace('"', "")
        const secondClean =
          firstClean[firstClean.length - 1] === "." ? firstClean.slice(0, -1) : firstClean
        return secondClean
      }
      const summaryText = e?.data?.choices[0]?.text

      const cleaned = cleanText(summaryText)
      this.setTitleForThought(thoughtId, cleaned)
    })
  }

  setTitleForThought(thoughtId: string, title: string) {
    const postListRef = child(this.databaseRef, "nodes/" + thoughtId + "/title")
    if (title) set(postListRef, title)
  }
  addPost(
    children: Descendant[],
    text: string,
    placeId: string,
    isReply?: true,
    lineage?: AncestorThought[],
    fromPlexus?: true
  ): { postWithId?: TextPost; addPostPromise?: Promise<any> } {
    const personId: string = this.personId
    if (!personId)
      this.ensureSignedIn(false, () =>
        this.addPost(children, text, placeId, isReply, lineage, fromPlexus)
      )
    const textWithoutBreaks = text
      .split("\n")
      .filter((e) => e)
      .join("\n")
    const newPost: TextPost = makePost(
      fromPlexus ? "plexus-default" : personId,
      fromPlexus ? "Plexus" : this.personName ?? usernamex,
      fromPlexus ? "app@plexus.earth" : this.personEmail,
      textWithoutBreaks,
      Date.now(),
      undefined,
      children,
      undefined,
      isReply,
      lineage
    ) as TextPost
    const result = this.addPostInternal(newPost, placeId)

    if (result) return { postWithId: result.postWithId, addPostPromise: result.addPostPromise }
    else return
  }

  ensureEmbeddings(text: string, authId: string, thoughtId: string, placeId: string) {
    // If personId does not exist (for example, on an admin panel), assign the passed in authorId
    this.personId = this.personId ? this.personId : authId
    if (!this.personId) return
    const textEmbedding = getOpenAiTextEmbedding(text, this.personId)
    //takes a second, but that's okay I think
    textEmbedding
      //vector value
      .then((val) => {
        //@ts-ignore
        const vector = val.data.data ? val.data.data[0].embedding : undefined
        if (vector) {
          // Calls firebase function to upsert embedding and thought ID into Pinecone
          backendUpsertEmbedding(thoughtId, vector, placeId)
        }
      })
      .catch((e) => console.warn(e))
  }

  /**
   * Function to add a reply under a given parent thought
   * @param text text of the reply to add
   * @param parentThoughtId id of the thought under which to add the reply
   * @param children slate editor value, if provided, the point is to include line breaks (hard to store in text), otherwise generated
   * @returns
   */
  addReplyThought(
    text: string,
    parentThought: TextPost,
    placeId: string,
    children?: Descendant[],
    fromPlexus?: true
  ): { addPostPromise: Promise<any>; newReplyThoughtId: string } {
    const childrenToUse: Descendant[] = children ?? getSlateValueFromText(text)

    //add the new reply thought, with isReply tag = true
    const newLineageNode = [
      {
        id: parentThought.id,
        textPreview: abbreviate(parentThought.text, 30),
        authorId: parentThought.authorId,
      },
    ]
    const lineage: AncestorThought[] = parentThought.isReply
      ? parentThought.lineage
        ? [...parentThought.lineage, ...newLineageNode]
        : undefined
      : newLineageNode
    const { addPostPromise, postWithId } = this.addPost(
      childrenToUse,
      text,
      placeId,
      true,
      lineage,
      fromPlexus
    )

    //add the new reply edge
    addPostPromise.then(() => {
      // Adds grandparent edges
      console.log(postWithId)
      addFamilyEdges(postWithId.id)
      const connectionEdgePromise = this.addConnectionConnection(
        parentThought.id,
        postWithId.id,
        parentThought.authorId,
        postWithId.authorId,
        parentThought,
        postWithId,
        this.personId
      )
      //this ordering is a stupid hack, so that updates works. can do a better job of update filtering later
      connectionEdgePromise.then(() => {
        this.addReplyConnection(
          parentThought.id,
          postWithId.id,
          parentThought.authorId,
          postWithId.authorId
        ).then(() => {
          //see if should send first reply email
          sendEmailIfFirstReply(parentThought, postWithId)
          //add a connection
        })
      })

      return Promise.all([connectionEdgePromise])
    })

    setTimeout(() => {
      if (replyIsSuggestable(postWithId)) {
        debugger
        this.findAndRecordSuggestionsForThought(postWithId, "forum")
      }
    }, 3000)

    return { addPostPromise, newReplyThoughtId: postWithId.id }
  }

  /**
   * Adds an edge between two posts to indicate its a replly. From reply to parent, to stay consistent with direction of the notification
   * @param parentThoughtId id of the root thought, to which the thought is a reply
   * @param replyThoughtId id of the reply thought, which is being replied to the root thought
   * @param parentAuthorId author id of the root thought
   * @param replyAuthorId author id of the reply thought
   */
  addReplyConnection(
    parentThoughtId: string,
    replyThoughtId: string,
    parentAuthorId: string,
    replyAuthorId: string
  ) {
    const connectionData: SingleConnectionUpdateForAPerson = {
      sourceId: replyThoughtId,
      targetThoughtId: parentThoughtId,
      targetAuthorId: parentAuthorId,
      authorId: replyAuthorId,
      edgeKind: ConnectionKind.REPLY,
      timestamp: Date.now(),
    }
    return this.addConnection(connectionData)
  }

  addConnectionConnectionFromThoughts(
    sourceThought: TextPost,
    targetThought: TextPost,
    edgeAuthorId?: string,
    anti?: true
  ) {
    return this.addConnectionConnection(
      sourceThought.id,
      targetThought.id,
      sourceThought.authorId,
      targetThought.authorId,
      sourceThought,
      targetThought,
      edgeAuthorId,
      anti
    )
  }

  /**
   * Adds an edge between two posts to indicate one was connected to the other
   *  Saying a CONNECT connection is not redundant. It's like saying REPLY connection.

   * @param sourceThoughtId id of the root thought, to which a thought is being connected
   * @param targetThoughtId id of the sugggested, which is being connected to the root thought
   * @param sourceAuthorId author id of the root thought
   * @param targetAuthorId author id of the suggested thought
   */
  addConnectionConnection(
    sourceThoughtId: string,
    targetThoughtId: string,
    sourceAuthorId: string,
    targetAuthorId: string,
    sourceThought?: TextPost,
    targetThought?: TextPost,
    edgeAuthorId?: string,
    anti?: true
  ) {
    const connectionData: SingleConnectionUpdateForAPerson = {
      sourceId: sourceThoughtId,
      targetThoughtId,
      targetAuthorId,
      authorId: sourceAuthorId,
      edgeKind: ConnectionKind.CONNECTION,
      timestamp: Date.now(),
      edgeAuthorId: edgeAuthorId ?? sourceAuthorId,
    }
    //important that it's assigned this way, otherwise writes an undefined value to fb (if anti not provided)
    if (anti) connectionData.anti = true
    return this.addConnection(connectionData)
  }

  /**
   * Generic function for adding a new edge
   *  Worth noting! Connections added here can be of any type, including "CONNECT" and "REPLY"
   * @param connectionObject
   * @returns
   */
  addConnection(connectionObject: SingleConnectionUpdateForAPerson) {
    const sourceThoughtId = connectionObject.sourceId
    const targetThoughtId = connectionObject.targetThoughtId
    if (!sourceThoughtId || !targetThoughtId) return

    const linkKeyName = "connections"

    const postListRef = child(this.databaseRef, "nodes")

    const firstConnectionParentRef = child(
      postListRef,
      `${sourceThoughtId}/${linkKeyName}/outbound/${targetThoughtId}`
    )

    const secondConnectionParentRef = child(
      postListRef,
      `${targetThoughtId}/${linkKeyName}/inbound/${sourceThoughtId}`
    )
    //get the reply edge id
    const firstConnectionRef = push(firstConnectionParentRef)
    const secondConnectionRef = child(secondConnectionParentRef, firstConnectionRef.key)

    //actually add
    const link1Promise = set(firstConnectionRef, connectionObject)
      .then(() =>
        console.log(
          "successfully added connection from " + sourceThoughtId + " to " + targetThoughtId,
          connectionObject
        )
      )
      .catch((e) => console.warn(e))

    //other way

    const link2Promise = set(secondConnectionRef, connectionObject)
      .then(() =>
        console.log(
          "successfully added connection from " + targetThoughtId + " to " + sourceThoughtId,
          connectionObject
        )
      )
      .catch((e) => console.warn(e))

    //then also, every time a connection is made, record that connection in an index for the person who was connected to, and probably also an outgoing index

    this.recordConnectionForBothPartiesInvolved({
      ...connectionObject,
    })

    return Promise.all([link1Promise, link2Promise]).catch((e) => console.warn(e))
  }

  /**
   * Record a connection made between two people's thoughts, in both people's firebase buckets
   * This is weird, because the source thought of a reply connection is the parent thought, but the source author of a connection is the child
   * @param sourceThoughtId
   * @param targetThoughtId
   * @param sourceAuthorId
   * @param targetAuthorId
   * @param connectionKind
   */
  recordConnectionForBothPartiesInvolved(
    connectionUpdate: SingleConnectionUpdateForAPerson
  ): Promise<any> {
    //get poeple location in firebase
    const peopleLocation = child(this.databaseRef, "people/")

    //1. record for sourceThoughtAuthor
    const sourceThoughtAuthorRef = child(
      peopleLocation,
      connectionUpdate.authorId + "/connections/outbound"
    )
    const sourceAuthorBucketUpdate = push(sourceThoughtAuthorRef, connectionUpdate)
    const connectionUpdateKey = sourceAuthorBucketUpdate.key

    //2. then record for TargetThoughtAuthor
    //use the same key as above
    const targetThoughtAuthorRef = child(
      peopleLocation,
      connectionUpdate.targetAuthorId + "/connections/inbound"
    )
    const targetAuthorBucketUpdate = set(
      child(targetThoughtAuthorRef, connectionUpdateKey),
      connectionUpdate
    )

    //3. then, if sourceThoughtAuthor is different than the edge author id, record it in thirdbound for the edge author
    //QUESTION: should we keep this condition? or just record regardless always?
    //  for now: I think only if different is more efficient for now.
    if (getEdgeAuthor(connectionUpdate) !== connectionUpdate.authorId) {
      const edgeAuthorRef = child(
        peopleLocation,
        getEdgeAuthor(connectionUpdate) + "/connections/thirdbound"
      )
      set(child(edgeAuthorRef, connectionUpdateKey), connectionUpdate)
    }

    //don't include conditional edge author promise, for now
    return Promise.all([sourceAuthorBucketUpdate, targetAuthorBucketUpdate])
  }

  /**
   * Deletes all directed connectionsbetween two thoughts, no matter the type
   *  only deletes outbound edges from source to target, keeps stuff from target to source if there
   * and doesn't delete from author buckets, only from thought buckets
   * @param sourceThoughtId
   * @param targetThoughtId
   */
  deleteConnection(sourceThoughtId: string, targetThoughtId: string) {
    const postListRef = child(this.databaseRef, "nodes")

    //Get references to right spots in the db
    const firstConnectionRef = child(
      postListRef,
      `${sourceThoughtId}/connections/outbound/${targetThoughtId}`
    )

    //other way
    const secondConnectionRef = child(
      postListRef,
      `${targetThoughtId}/connections/inbound/${sourceThoughtId}`
    )

    //Remove edge info from both locations
    const link1Promise = remove(firstConnectionRef)
      .then(() =>
        console.log(
          "successfully removed firebase connection (in first direction) from " +
            sourceThoughtId +
            " to " +
            targetThoughtId
        )
      )
      .catch((e) => console.warn(e))
    const link2Promise = remove(secondConnectionRef)
      .then(() =>
        console.log(
          "removed firebase connection (in second direction) from " +
            sourceThoughtId +
            " to " +
            targetThoughtId
        )
      )
      .catch((e) => console.warn(e))

    return Promise.all([link1Promise, link2Promise]).catch((e) => console.warn(e))
  }

  //when you unclick the like button, eg.
  deleteRelateConnections(sourceThought: TextPost, targetThoughtId: string) {
    return this.deleteConnectionOfType(sourceThought, targetThoughtId, ConnectionKind.RELATE)
  }

  /**
   * Deletes all directed connectionsbetween two thoughts, no matter the type
   *  only deletes outbound edges from source to target, keeps stuff from target to source if there
   * and doesn't delete from author buckets, only from thought buckets
   * @param sourceThoughtId
   * @param targetThoughtId
   */
  deleteConnectionOfType(
    sourceThought: TextPost,
    targetThoughtId: string,
    connectionKind: ConnectionKind
  ) {
    const postListRef = child(this.databaseRef, "nodes")

    //get all the edge ids of the particular type
    //this logic depends on edges having the same id for inbound and for outbound listings
    const edgeIds: string[] = Object.keys(
      getEdgeMapFromThought(sourceThought, connectionKind, "outbound") ?? {}
    )
    debugger

    let promises = []
    edgeIds.forEach((edgeId) => {
      //Get references to right spots in the db
      const firstConnectionRef = child(
        postListRef,
        `${sourceThought.id}/connections/outbound/${targetThoughtId}/${edgeId}`
      )

      //other way
      const secondConnectionRef = child(
        postListRef,
        `${targetThoughtId}/connections/inbound/${sourceThought.id}/${edgeId}`
      )

      //Remove edge info from both locations
      const link1Promise = remove(firstConnectionRef)
        .then(() =>
          console.log(
            "successfully removed firebase connection (in first direction) from " +
              sourceThought.id +
              " to " +
              targetThoughtId
          )
        )
        .catch((e) => console.warn(e))
      const link2Promise = remove(secondConnectionRef)
        .then(() =>
          console.log(
            "removed firebase connection (in second direction) from " +
              sourceThought.id +
              " to " +
              targetThoughtId
          )
        )
        .catch((e) => console.warn(e))

      promises.push(link1Promise, link2Promise)
    })

    return Promise.all(promises).catch((e) => console.warn(e))
  }

  //ALL OlD EDGE LOGIC.
  //WE IMPLEMENTED EDGES POORLY IN THIS ITERATIONS.
  // NEW EDGE KEY, as of 2/6/23, to be used for replies in threading, IS CALLED `connections`
  // This is all deprecated, except for the addRequest link logic and the delete logic (we'll still want to delete wacky edges)
  //addEdge of type 'request': still used for marking that someone has started a chat with someone else through a thought pair
  //delete logic still good to keep for now, so we can clear stupid edges from the equation

  //this is outdated, only for replies and previous links
  deleteLink(id1: string, id2: string, anti: boolean = false) {
    const linkKeyName = anti ? "antiLinks" : "links"

    const postListRef = child(this.databaseRef, "nodes")
    const newLinkRef = child(postListRef, id1 + "/" + linkKeyName + "/" + id2)
    set(newLinkRef, null)
      .then(() => console.log("success link1"))
      .catch((e) => console.warn(e))

    //other way
    const secondLinkRef = child(postListRef, id2 + "/" + linkKeyName + "/" + id1)
    set(secondLinkRef, null)
      .then(() => console.log("success link2"))
      .catch((e) => console.warn(e))
  }
  /**
   * Delete a post and all its replies
   * @param post
   */
  async deletePostAndReplyThoughts(post: TextPost, posts: PostMap, placeId: string) {
    //save the connections first, before any deletion stuff happens
    //get the new replies
    //THIS NEEDS TESTING
    const newReplies = await getReplyThoughtsFromParent(post)
    //delete this one and all its connections
    this.deletePost(post, placeId)
    //delete all reply thoughts--delete these ones recursively!
    newReplies.forEach((replyPost) => this.deletePostAndReplyThoughts(replyPost, posts, placeId))

    // delete all other kinds of edges, non recursively (connections) in the delete post function
  }

  deletePost(post: TextPost, placeId: string) {
    //delete from post list
    const postListRef = child(this.databaseRef, "nodes")
    const newPostRef = child(postListRef, post.id)
    set(newPostRef, null)

    //delete all Edges

    //delete obsolete links (obsolete, used for replies)
    const links = post.links ? Object.keys(post.links) : []
    links.forEach((linkId) => {
      this.deleteLink(linkId, post.id)
    })

    //delete almost-obsolete kind of connections (edgeList startConversation connections)
    const edgeList = post?.edgeList ?? {}
    if ("edgeList" in post) {
      for (let key of Object.keys(edgeList)) {
        this.deleteInboundAndOutboundEdgesOfThought(post.id, key)
      }
    }

    //delete the in-use links for new replies
    this.deleteAllEdgesForDefunctThought(post)
    //delete embeddings for pinecone
    deleteThoughtById([post.id], placeId)
  }

  /**
   * Deletes all Connection edges between this post and all others
   * @param post
   */
  deleteAllEdgesForDefunctThought(post: TextPost) {
    const connections: BidirectionalEdgeMap = post.connections

    if (connections) {
      Object.values(connections).forEach((oneDirectionEdges: DirectionalEdgeMap) => {
        //delete all the edges for this direction
        Object.entries(oneDirectionEdges).forEach(
          ([otherThoughtId, thoughtPairEdges]: [string, ConnectionMap]) => {
            //for each of the thought pair edges, delete em
            //delete all edges between these two thoughts
            //delete in both directions, will be redundant but whatever
            this.deleteConnection(post.id, otherThoughtId)
            this.deleteConnection(otherThoughtId, post.id)
          }
        )
      })
    }
  }

  // Used for deleting inbound and outbound edges by passing in an id
  // This is a clean up function that is used for removing latent edges
  // After somebody deletes their thought.
  deleteInboundAndOutboundEdgesOfThought(deletedThoughtId: string, targetThoughtId: string) {
    // Getting the path of edgelist for the thought that we are NOT deleting so that we can delete the node that we ARE deleting
    const targetThoughtRef = child(
      this.databaseRef,
      `nodes/${targetThoughtId}/edgeList/${deletedThoughtId}`
    )
    // Setting to null deletes the node
    set(targetThoughtRef, null)
  }

  //updates last time stored for when author expanded this thought.
  updateLastExpandedByAuthor(thoughtId: string, authorId: string) {
    const lastExpanded = child(this.databaseRef, "nodes/" + thoughtId + "/lastExpanded/")
    const authorData = {
      [authorId]: Date.now(),
    }
    update(lastExpanded, authorData)
  }

  //write to a people section, saying the person logged in
  markPersonAsOnboarded() {
    // if (!this.databaseRef) return
    const lastExpanded = child(
      this.databaseRef,
      "people/" + this.personId + "/orientation/enteredDoor"
    )
    set(lastExpanded, Date.now())

    //also set their email + password
    if (this.personEmail && this.personName) {
      this.recordPersonName(this.personName)
      this.recordPersonEmail(this.personEmail)
    }
  }

  recordOnboardingStart() {
    if (!this.databaseRef || !this.personId) return
    // if (!this.databaseRef) return
    const lastExpanded = child(
      this.databaseRef,
      "people/" + this.personId + "/orientation/clickedWelcomePage"
    )
    set(lastExpanded, Date.now())
  }

  addThoughtSubmission(submission: string) {
    if (!this.databaseRef || !this.personId) return
    // if (!this.databaseRef) return
    const lastExpanded = child(
      this.databaseRef,
      "people/" + this.personId + "/orientation/submissions"
    )
    return push(lastExpanded, { text: submission, timestamp: Date.now() })
  }
  updateThoughtSubmission(score: number, key) {
    if (!this.databaseRef || (!this.personId && !score)) return
    // if (!this.databaseRef) return
    const lastExpanded = child(
      this.databaseRef,
      "people/" + this.personId + "/orientation/submissions/" + key + "/score"
    )
    return set(lastExpanded, score)
  }
  /**
   *
   */
  recordPersonName(name: string) {
    if (!(this.databaseRef && this.personId)) return
    const ref = child(this.databaseRef, "people/" + this.personId + "/personName/")
    set(ref, name)
  }

  recordPersonPhone(phone: string, authorObject) {
    if (!(this.databaseRef && this.personId)) return
    const ref = child(this.databaseRef, `phone/${phone}`)
    set(ref, authorObject)
    const bucketRef = child(this.databaseRef, `people/${this.personId}/phone/`)
    set(bucketRef, phone)
  }

  recordPersonEmail(email: string) {
    const ref = child(this.databaseRef, "people/" + this.personId + "/personEmail/")
    set(ref, email)
  }

  /**
   * Record that this person has opened a given thought
   *  Initial purpose: keeping track of which thoughts someone has already 'seen' or not, for use in bolding
   * @param thoughtId
   */
  recordPersonThoughtInteraction(thoughtId: string, interactionType: PersonThoughtInteractionType) {
    //get the interaction object
    const interaction: SinglePersonThoughtInteraction = {
      timestamp: Date.now(),
      type: interactionType,
      thoughtId,
      personId: this.personId,
    }
    //record the interaction in two locations
    //in person firebase bucket
    const promise1 = this.recordPersonThoughtInteractionForPerson(interaction)
    //in thought firebase bucket

    const promise2 = this.recordPersonThoughtInteractionForThought(interaction)

    return Promise.all([promise1, promise2])
  }

  //TODO condense these two into a single function
  //part one of recording a person-thought interaction
  recordPersonThoughtInteractionForPerson(interaction: SinglePersonThoughtInteraction) {
    const ref = child(
      this.databaseRef,
      "people/" + interaction.personId + "/personThoughtInteractions/"
    )
    return push(ref, interaction)
  }

  //part one of recording a person-thought interaction
  recordPersonThoughtInteractionForThought(interaction: SinglePersonThoughtInteraction) {
    const ref = child(
      this.databaseRef,
      "nodes/" + interaction.thoughtId + "/personThoughtInteractions/"
    )
    return push(ref, interaction)
  }

  /**
   * Find and record suggestions per thought
   */
  findAllSuggsForThought(thought: TextPost, placeId: string): Promise<SuggestedThoughtInfo[]> {
    //2. find the suggestions
    const rawSuggs = this.findSuggestionsForThought(thought, placeId)

    let allSuggsToAdd = []
    let suggsToAdd = []
    //3. record them in plexus and in person interactions section
    return rawSuggs.then((suggestions) => {
      //make sure at least five
      const minSuggs = 10
      const topFive = suggestions.slice(0, minSuggs)
      const afterTopFive = suggestions.slice(minSuggs)
      const topSuggs = afterTopFive
        .filter((e) => e.score > SUGGESTIONS_SIMILARITY_THRESHOLD)
        .slice(0, 100)
      suggsToAdd = [...topFive, ...topSuggs]
      //store all suggestions in an array.
      allSuggsToAdd = [...allSuggsToAdd, ...suggsToAdd]
      return suggsToAdd
    })
  }

  /**
   * Find and record suggestions per thought
   */
  findAndRecordSuggestionsForThought(
    thought: TextPost,
    placeId: string
  ): undefined | Promise<SuggestedThoughtInfo[]> {
    //1. check if any suggestions already. if any, abort, because we only want to record connection edges once for each new thought
    const suggestionsAlreadyFound = FirebaseWriter.checkIfThoughtAlreadyHasSugg(thought)
    if (suggestionsAlreadyFound) return
    //2. find the suggestions
    const rawSuggs = this.findSuggestionsForThought(thought, placeId)

    let allSuggsToAdd = []
    let suggsToAdd = []
    //3. record them in plexus and in person interactions section
    rawSuggs.then((suggestions) => {
      //make sure at least five
      const minSuggs = 10
      const topFive = suggestions.slice(0, minSuggs)
      const afterTopFive = suggestions.slice(minSuggs)
      const topSuggs = afterTopFive
        .filter((e) => e.score > SUGGESTIONS_SIMILARITY_THRESHOLD)
        .slice(0, 100)
      suggsToAdd = [...topFive, ...topSuggs]
      //store all suggestions in an array.
      allSuggsToAdd = [...allSuggsToAdd, ...suggsToAdd]
      this.recordSuggestionsForThought(thought, suggsToAdd)
      return suggestions
    })
  }

  /**
   * Check if a given thought already has recorded connections
   * Only check outbound
   * @param thought
   * @returns
   */
  static checkIfThoughtAlreadyHasSugg(thought: TextPost) {
    if (!thought.connections) return false
    const outbound = thought.connections.outbound
    const inboundFlat = []

    const outboundFlat = outbound ? Object.values(getFlatEdgeMapFull(outbound)) : []
    const allFlat = [...inboundFlat, ...outboundFlat]

    //loop through all flat to see if any edges of type sugg
    const sugg = allFlat.filter((edge) => edge.edgeKind === ConnectionKind.SUGGESTION)
    const alreadyHas = sugg.length > 0
    return alreadyHas
  }

  findSuggestionsForThought(thought: TextPost, placeId: string): Promise<SuggestedThoughtInfo[]> {
    //find the thoughts
    const result = getSuggestedThoughts([thought], placeId)

    return result
  }

  /**
   * Adds a bunch of suggestion edges to the graph + to each involved person's bucket (add it in both directions? I think so.)
   *  connection represents whether the suggestion was made or not. so draw it in both directions.
   * @param thought
   * @param suggestions
   */
  recordSuggestionsForThought(
    thought: TextPost,
    suggestions: SuggestedThoughtInfo[],
    justIncoming?: true
  ) {
    //draw a connection in both directions, for each suggestion
    let promises = []
    suggestions.forEach((suggestion: SuggestedThoughtInfo, i) => {
      const connection: EdgeInfoWithConnectionData = {
        edgeAuthorId: "plexus-default",
        timestamp: Date.now(),
        sourceId: thought.id,
        authorId: thought.authorId,
        targetAuthorId: suggestion.suggestedThought.authorId,
        targetThoughtId: suggestion.suggestedThought.id,
        edgeKind: ConnectionKind.SUGGESTION,
        weight: suggestion.score ?? 0,
      }
      //only add the first direction
      const prom1 = justIncoming ? undefined : this.addConnection(connection)
      promises.push(prom1)

      //don't add in other direction at all
      //only add few connection incoming if this is the top suggestion
      // const prom2 = i < 3 ? this.addConnection(secondConnection) : undefined
      // promises.push(prom2)
    })
    return Promise.all(promises)
  }

  makeNodesUpdateObjectFromEdgeArr = (edges: EdgeInfoWithConnectionData[]) => {
    const updatePathsFromNodes: UpdatesObjFromNodesBucket = {}
    // const postListRef = child(this.databaseRef, "nodes")
    const postListRef = child(this.databaseRef, "nodes")

    //for each edge
    edges.forEach((edge) => {
      const edgeIsValidated = FirebaseWriter.validateEdge(edge)
      const sourceThoughtId = edge.sourceId
      const targetThoughtId = edge.targetThoughtId
      if (!sourceThoughtId || !targetThoughtId || !edgeIsValidated) return
      const linkKeyName = "connections"

      //get the first path
      const startOfFirstPath = `${sourceThoughtId}/${linkKeyName}/outbound/${targetThoughtId}`
      const startOfSecondPath = `${targetThoughtId}/${linkKeyName}/inbound/${sourceThoughtId}`

      const firstConnectionParentRef = child(
        postListRef,
        `${sourceThoughtId}/${linkKeyName}/outbound/${targetThoughtId}`
      )

      const secondConnectionParentRef = child(
        postListRef,
        `${targetThoughtId}/${linkKeyName}/inbound/${sourceThoughtId}`
      )
      //get the reply edge id
      const firstConnectionRef = push(firstConnectionParentRef)
      const secondConnectionRef = child(secondConnectionParentRef, firstConnectionRef.key)
      //debugger see what the path is here

      const fullFirstPath = startOfFirstPath + "/" + firstConnectionRef.key
      const fullSecondPath = startOfSecondPath + "/" + firstConnectionRef.key
      updatePathsFromNodes[fullFirstPath] = edge
      updatePathsFromNodes[fullSecondPath] = edge
    })
    return updatePathsFromNodes
  }

  static validateEdge(edge: EdgeInfoWithConnectionData): boolean {
    //no undefined properties
    return Object.values(edge).filter((e) => typeof e === "undefined").length == 0
  }

  writeSuggUpdates = (
    thought: TextPost,
    updates: UpdatesObjFromNodesBucket,
    peopleUpdates?: UpdatesObjFromNodesBucket
  ) => {
    const suggestionsAlreadyFound = FirebaseWriter.checkIfThoughtAlreadyHasSugg(thought)
    if (suggestionsAlreadyFound) return
    //node promise
    const postListRef = child(this.databaseRef, "nodes")
    const nodePromise = update(postListRef, updates)
    const promises = [nodePromise]

    if (peopleUpdates) {
      const peopleRef = child(this.databaseRef, "people")
      promises.push(update(peopleRef, peopleUpdates))
    }

    return Promise.all(promises)
  }

  makePeopleUpdateObjectFromEdgeArr = (edges: EdgeInfoWithConnectionData[]) => {
    // const postListRef = child(this.databaseRef, "people")
    //get poeple location in firebase
    const peopleLocation = child(this.databaseRef, "people/")

    const updatePathsFromPeople: UpdatesObjFromNodesBucket = {}
    // const postListRef = child(this.databaseRef, "nodes")

    //for each edge
    edges.forEach((edge) => {
      //get the first path
      const startOfFirstPath = getEdgeAuthor(edge) + "/connections/outbound"

      const startOfSecondPath = edge.targetAuthorId + "/connections/inbound"

      const firstConnectionParentRef = child(peopleLocation, startOfFirstPath)

      //get the reply edge id
      const firstConnectionRef = push(firstConnectionParentRef)
      // const secondConnectionRef = child(secondConnectionParentRef, firstConnectionRef.key)
      //debugger see what the path is here

      const fullSecondPath = startOfSecondPath + "/" + firstConnectionRef.key

      updatePathsFromPeople[fullSecondPath] = edge

      //thirdbound
      if (getEdgeAuthor(edge) !== edge.authorId) {
        const thirdPath = getEdgeAuthor(edge) + "/connections/thirdbound/" + firstConnectionRef.key
        updatePathsFromPeople[thirdPath] = edge
      }
      //only do update from source thought author as outbound if source thought author is the same as source edge author
      else {
        const fullFirstPath = startOfFirstPath + "/" + firstConnectionRef.key
        updatePathsFromPeople[fullFirstPath] = edge
      }
    })
    return updatePathsFromPeople
  }

  //gets array of edges from suggestions
  static convertSuggArrToEdgeArr = (
    thought: TextPost,
    suggestions: SuggestedThoughtInfo[]
  ): EdgeInfoWithConnectionData[] => {
    return suggestions?.map((e) => FirebaseWriter.convertSuggToEdgeData(thought, e)) ?? []
  }
  static convertSuggToEdgeData(thought: TextPost, suggestion: SuggestedThoughtInfo) {
    const connection: EdgeInfoWithConnectionData = {
      edgeAuthorId: "plexus-default",
      timestamp: Date.now(),
      sourceId: thought.id,
      authorId: thought.authorId,
      targetAuthorId: suggestion.suggestedThought.authorId,
      targetThoughtId: suggestion.suggestedThought.id,
      edgeKind: ConnectionKind.SUGGESTION,
      weight: suggestion.score ?? 0,
    }
    return connection
  }

  recordSuggestionsPeak() {
    const path = child(
      this.databaseRef,
      "people/" + this.personId + "/timestamps/lastPeakedSuggestionsThreads"
    )
    set(path, Date.now())
  }
  recordAllUpdatesPeak() {
    const path = child(
      this.databaseRef,
      "people/" + this.personId + "/timestamps/lastPeakedAllUpdates"
    )
    set(path, Date.now())
  }
  // Records items used in survey and alert system
  recordWindowResponse(response: windowUpdate) {
    const path = child(this.databaseRef, "people/" + this.personId + "/surveys/" + response.title)
    set(path, response)
  }

  //NEW CHAT LOGIC

  addMessage(message: StupidMessage, otherAuthorId: string) {
    const chatId = generateChatId(otherAuthorId, message?.authorId)
    const path = child(this.databaseRef, "chats/" + chatId + "/messages")
    return push(path, message)
  }
  recordChatCheck(otherAuthorId) {
    const chatId = generateChatId(otherAuthorId, this.personId)
    const path = child(this.databaseRef, "chats/" + chatId + "/lastChecked/" + this.personId)
    return set(path, Date.now())
  }
  /**
   * Queries the Firebase database for the objects with the specified IDs and returns their data.
   *
   * @param {string|string[]} ids - The ID(s) of the objects to query. Can be a single ID or an array of IDs.
   * @returns {Promise<PostMap[]>} - A Promise that resolves to an array of objects, where each object represents the data for an object in the database with the specified ID(s).
   * @throws {Error} - If the Firebase query encounters an error, the Promise is rejected with an Error object.
   */
  queryByIds = (ids: string | string[]): Promise<TextPost[]> => {
    ids = typeof ids === `string` ? [ids] : ids
    const promises = ids.map((id) => {
      const ref = child(this.databaseRef, `nodes/${id}`)
      return get(ref)
    })

    return Promise.all(promises)
      .then((snapshots) => {
        const objectsData = snapshots
          .filter((snapshot) => snapshot.exists())
          .map((snapshot) => snapshot.val())
        return objectsData
      })
      .catch((error) => {
        console.error(error)
        return []
      })
  }
  rememberSettings(settingsObj: DisplaySettings) {
    const path = child(this.databaseRef, "people/" + this.personId + "/displaySettings/")
    set(path, settingsObj)
  }
}

export interface DisplaySettings {
  showThoughtTitles: boolean
}

export default FirebaseWriter

export const makePost = (
  authorId: string,
  authorName: string,
  authorEmail: string,
  text: string,
  timestamp: number,
  id?: string,
  providedChildren?: Descendant[],
  prompt?: string,
  isReply?: true,
  lineage?: AncestorThought[]
): TextPostWithoutId | TextPost => {
  const children: Descendant[] =
    providedChildren ??
    text.split("\n").map((e) => ({ type: "paragraph", children: [{ type: "text", text: e }] }))
  const post: TextPostWithoutId | TextPost = {
    authorId: authorId,
    authorName,
    slateValue: children,
    text,
    timestamp,
    id,
    authorEmail,
  }
  if (isReply) post.isReply = isReply
  if (prompt) post.prompt = prompt
  if (lineage) post.lineage = lineage
  return post
}

type addPostInternalResult = {
  postWithId: TextPost
  newPostRef: DatabaseReference
  addPostPromise: Promise<any>
}

//as of 2/2, these are rough types for the firebase buckets

export type FirebaseDatabaseTypes = {
  people: PeopleFirebaseBucket

  nodes: {
    [thoughtId: string]: TextPost
  }

  //dictionary and embeddings are obsolete
}
export interface PeopleFirebaseBucket {
  [personId: string]: PersonFirebaseBucket
}

export interface ThoughtSubmission {
  text: string
  timestamp: number
  score?: number
}
export interface PersonFirebaseBucket {
  //outdated now
  orientation?: {
    clickedWelcomePage: number
    enteredDoor: number // timestamp of when they entered plex//outdated now
    submissions: { [id: string]: ThoughtSubmission }
  }
  personName: string //username
  personEmail: string
  personPhone?: string
  //log of connections, organized by direction of interaction with the person's thoughts (inbound, outbound, vicarious)
  connections?: {
    //for connections to thoughts that are their own (connections they receive), made by them or by others
    inbound?: ConnectionUpdatesForAPerson
    //for connections from thoughts that are their own, made by them or by others
    outbound?: ConnectionUpdatesForAPerson
    //for vicarious connections: connections the person makes between thoughts that aren't their own
    thirdbound?: ConnectionUpdatesForAPerson
  }
  timestamps?: {
    lastPeakedSuggestionsThreads?: number
    lastPeakedAllUpdates?: number
  }
  personThoughtInteractions?: PersonThoughtInteractions
  displaySettings?: DisplaySettings
}

interface UpdatesObjFromNodesBucket {
  [path: string]: EdgeInfoWithConnectionData
}
