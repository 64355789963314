import { abbreviate } from "../../Logic/Utilities"

const examples: { text: string }[] = [
  {
    text: `During the day, my mind constantly oscillates between achieving things in the world (practical) and making progress in idea space (theoretical/abstract). I may need to confront the interplay and figure out how to live a balanced life while doing both. Getting deep in my head, and then getting the heck out of it…`,
  },
  {
    text: `I'm not sure what will await me on the other side of the New York trip, and maybe it isn't even in my best interest to try and aim myself towards some specific outcome, because I have no idea what will happen on the trip. It's sort of an odd balance you have to strike between 'orienting yourself towards that which you want to see' vs 'allowing things to unfold in front of you and learn in as unbiased a manner as you can from them`,
  },
  {
    text: `My biggest problem with the internet is that it's so fractured. I've got a piece of me here, a piece of me there, and I struggle to maintain all the pieces. I'd love to have an internet where I could just be me and not have to leave small bits of myself everywhere while I try to maintain them. I feel like I'm building part of that, but it's not something I can do on my own. It's hard to maintain a sense of self, because everywhere expects us to label ourselves, because labels are data, data is good for companies, they can use that. What if instead of labels, we relied on trust? How can you build trust without labels? What does that look like?`,
  },
  {
    text: `My classmates and colleagues had always been my social circle. By the time I got to grad school, I'd experienced a lot of changes and decided I want to be one of those go-getters. I was successful at succeeding, but I sacrificed building a new social network along the way. It's funny how traditional "success" is usually so unbalanced with all other components of life. Now that I'm working, I've set some rules for myself to follow to make sure I'm maintaining a good balance, but damn, sometimes I think I have really intense catching up to do..`,
  },
]
const ExampleThoughts = ({ i }: { i: number }) => {
  return <div className="example-thought">{abbreviate(examples[i]?.text, 230)}</div>
}

export default ExampleThoughts
