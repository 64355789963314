import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database"
import firebaseConfig from "../../../Firebase/FirebaseConfig"
import "./AdminThoughtTableDisplay.css"
import { useState } from "react"
import countEdges from "../functions/countEdges"
import moment from "moment"

//@ts-ignore
const app = initializeApp(firebaseConfig)
const db = getDatabase()

// Accepts object with three keys: authorName, authorEmail, and text
export default function AdminThoughtTable({ thoughts }) {
  const [visibleThoughts, setVisibleThoughts] = useState(thoughts)

  // Assigns edgeCount object for inbound/outbound/total matches
  for (let i = 0; i < thoughts.length; i++) {
    thoughts[i]["edgeCounts"] = countEdges(thoughts[i])
  }

  // When author name is input, filters list by substring match of author names
  const filterByAuthor = (event) => {
    let authorSubstring = event.target.value.toUpperCase()
    let thoughtsWithAuthorSubstringMatch = thoughts.filter((thought) => {
      return thought.authorEmail?.toUpperCase().includes(authorSubstring)
    })
    setVisibleThoughts(thoughtsWithAuthorSubstringMatch)
  }

  // Brings view back to default by setting state to entire thought list
  const resetView = () => {
    setVisibleThoughts(thoughts)
  }

  return (
    <div>
      <div className="adminThoughtTableDisplayTopContainer">
        <h2 className="adminThoughtHeader">Thought Viewer</h2>
        <span>Filter by email</span>
        <br></br>
        <input name="authorName" onChange={filterByAuthor}></input>
        <span
          className="adminThoughtTableDisplayResetButton"
          onClick={() => {
            resetView()
          }}
        >
          reset
        </span>
      </div>
      <table className="adminThoughtTable">
        <thead>
          <tr>
            <th className="emailThCell">AuthorId</th>
            <th className="timeThCell">Time</th>
            <th className="thoughtThCell">Thought</th>
          </tr>
        </thead>
        <tbody>
          {visibleThoughts.map((thought, i) => {
            return (
              <tr className="adminThoughtTableRow" key={i}>
                <td className="adminThoughtTableCell">{thought.authorId}</td>
                <td className="adminThoughtTableCell adminThoughtTableCellTime">
                  {" "}
                  {moment(thought?.timestamp).format("MM/DD/YYYY")}
                </td>
                <td className="adminThoughtTableCell adminThoughtTableCellText">{thought.text}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
