const domainDetails: {
  [domainId: string]: {
    name?: string
    aPlaceTo?: string
    toGetStarted?: string
    weFeedOn?: true
    weFeedDefault?: true
    purpose?: string
    oneLiner?: string
    announcement?: string[]
  }
} = {
  forum: {
    purpose: `/p is a new kind of social network — a thought network — where people connect through mutual thoughts, not mutual friends`,
  },
  growingtogether: {
    name: "Mind Health",
    aPlaceTo:
      "find community through your mental and emotional health lessons and reflections. We want to honor how far you’ve come!",
    toGetStarted: "share your thoughts and reflections to see what other people are saying.",
  },
  visioncommittee: {
    name: "Vision Committee",
    aPlaceTo: "make substantive connections with peers in the committee.",
    toGetStarted:
      "share a problem or resource, and you'll see the most related content from the rest of the Vision Committee.",
    purpose: `p/visioncommittee is a shared brain for parents and educators focused
   on innovation in homeschooling.`,
  },
  phil401: {
    name: "Williams Philosophy 401",
    weFeedOn: true,
    weFeedDefault: true,
    purpose:
      "The shared brain for Shaddock's Freedom class is designed to help students connect to each other through shared thoughts",
  },
  SIA: {
    name: "Send It Around",
    weFeedOn: true,
    weFeedDefault: true,
    purpose: "Plan labor day",
  },
  smartpeopledontsleep: {
    name: "Smart People Who Sleep",
    weFeedOn: true,
    weFeedDefault: true,
    purpose: "Connect over shared sleep experiences",
  },
  afterlife: {
    name: "Figuring out what's out there after college...",
    // weFeedOn: true,
    // weFeedDefault: true,
    purpose:
      "Welcome! p/afterlife is a place to connect through the mysteries and struggles of life after college.",
    oneLiner: "for life after graduation",
  },
  tech: {
    announcement: [
      "Welcome to p/tech - a community for a small-group of tech focused friends to share what they're thinking about and start conversations with each other.",
      "To get started, feel free to introduce yourself + share something exciting you're working on.",
    ],
    weFeedDefault: true,
  },
  friends: {
    purpose: `p/friends a place for our friends to connect with each other through
    daily reflections. You'll be some of the first people to give Plexus a whirl. We hope
    that this pocket of the internet can hold some meaning for each of you.`,
  },
  tc: {
    name: "The TechCrunch Brain",
    purpose:
      "The TechCrunch shared brain is for connecting with people who are thinking about the same things as you: investors interested in what you're building, clients who need what you can provide, or thought-partners who are obsessed with the same problems. We hope it helps you find the most relevant people for you ASAP. By: Plexus, a public benefit corp making connection-making technology.",
  },
}
export default domainDetails
