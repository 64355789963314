'use-strict'
import moment from "moment"
import { useEffect, useState } from "react"
import { getOpenAISummaryForConversation } from "../../../../Firebase/OpenAi"
import { getOpenAiEmbeddings } from "./textAnalyzer"
import { backendMetaphorReturnLinks, backendScrapeWebsite } from "../../../../Firebase/FirebaseFunctionPointers"

interface AuthorContent {
  timestamp: number,
  text: string,
  authorName: string
  selected: boolean
  id: number
}

interface PineconeMatch {
  id: string,
  score: number
}

const DataProcessorBI = ({ selectedTexts, setSelectedTexts, thoughts }) => {
  const [queryContent, setQueryContent] = useState<string>("")
  const [inputContent, setInputContent] = useState<string>("")
  const [suggestedThoughts, setSuggestedThoughts] = useState([])

  var allThoughts = {};
  for (var i = 0; i < thoughts.length; i++) {
    allThoughts[thoughts[i].id] = thoughts[i]
  }

  // When suggested thoughts is returned from pinecone, references allThoughts to find their related metadata
  // then updates the state with the correct metadata to be displayed in the frontend
  useEffect(() => {
    if (!suggestedThoughts.length) {
      return
    }
    // Grabs matches object from pinecone, only data relevant to use
    let matches: PineconeMatch[] = suggestedThoughts[0].suggestions.matches
    let suggestions = []

    // Fills suggestions array with the correctly formatted metadata
    for (let i = 0; i < matches.length; i++) {
      // Cuts returned suggestions down to 5, could be any number though
      if (suggestions.length > 5) {
        break
      }
      if (!(matches[i].id in allThoughts)) {
        continue
      }
      let authorName = "authorName" in allThoughts[matches[i].id] ? allThoughts[matches[i].id].authorName + ` (Plexus Score): ${matches[i].score.toFixed(4)}` : "no_author_found"
      suggestions.push({
        authorName: authorName,
        id: matches[i].id,
        selected: true,
        text: allThoughts[matches[i].id].text,
        timestamp: allThoughts[matches[i].id].timestamp
      })
    }


    // Updating the state with the new suggestions in front of the old selections 
    setSelectedTexts([...suggestions, ...selectedTexts])

  }, [suggestedThoughts])

  const handleMessageFormChange = (message) => {
    setQueryContent(message)
  }

  const handleSelectFormChange = (e) => {
    setInputContent(e.currentTarget.value)
  }

  const handleInputFormChange = (input) => {
    setInputContent(input)
  }

  const handleSelection = (content: AuthorContent) => {
    setQueryContent(queryContent.trim() + content.text.trim().replaceAll("\n", "") + ". ")
  }

  // Queries openai and foramts the response to an author content object, then updates the visible texts to include the formatted response
  const callSummarization = (text: string) => {
    getOpenAISummaryForConversation(text).then((result) => {
      let openAiText: AuthorContent = {
        timestamp: Date.now(),
        text: result.data.choices[0].text?.split('"').join(""),
        authorName: "OpenAI",
        selected: true,
        id: selectedTexts.length + 1
      }
      setSelectedTexts([openAiText, ...selectedTexts])
    })
  }

  const callScraper = async (url: string | string[]) => {
    // if (typeof(url) === "object") url.splice(10)
    await backendScrapeWebsite(url)
      .then((result) => {
        // result = removeWhiteSpace(result)
        console.log(result)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <div className="dataProcessingContainer">
      <div className="leftProcessingViewer">
        <h2> Selected Content </h2>
        <div className="selectedContentsColumn">
          {selectedTexts.map((selectedText, i) => {
            return (
              <div key={"selected-process-" + i} className="contentContainer" onClick={() => {
                handleSelection(selectedText)
              }}>
                <div className="contentContainerTopRow">
                  <span className="contentNameText"> {selectedText.authorName} </span>
                  <span className="contentTimeText"> {moment(selectedText.timestamp).fromNow()} </span>
                </div>
                <div>
                  {selectedText.text}
                </div>
                <div className="contentCopyText"
                  onClick={(e) => {
                    e.stopPropagation()
                    navigator.clipboard.writeText(selectedText.text)
                  }}> Copy </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="dataProcessingArea">
        <h2> Processing </h2>
        <div className="prefixOuterContainer">
          <div className="openAiTextPrefixInputContainer">
            <input
              className="openAiTextPrefixInput"
              placeholder="Open Ai Prefix Query"
              onChange={(e) => handleInputFormChange(e.currentTarget.value)}
              value={inputContent}
            >
            </input>
          </div>
          <div className="openAiTextPrefixSelectorContainer">
            <select
              name="prefillOpenAiOptions"
              onChange={handleSelectFormChange}>
              <option value="">None</option>
              <option value="Praise me based on what I have been through: ">Praise me based on what I have been through:</option>
              <option value="Explain how you have been through similar things to me:">Explain how you have been through similar things to me:</option>
              <option value="Convince me to join your community based on the following:">Convince me to join your community based on the following:</option>
              <option value="Empathize with what I have been through:">Empathize with what I have been through:</option>
              <option value="A mischevious and pithy topic describing:">A mischevious and pithy topic describing:</option>
            </select>
          </div>
        </div>
        <div className="analyisTextAreaContainer">
          <textarea
            className="analysisTextArea"
            placeholder="Input Content Here"
            value={queryContent}
            onChange={(e) => handleMessageFormChange(e.currentTarget.value)}
          ></textarea>
        </div>
        <div className="processingButtonContainer">
          <button onClick={() => {
            callSummarization(inputContent + queryContent)
          }}> OpenAI </button>
          <button onClick={(() => {
            getOpenAiEmbeddings([queryContent], setSuggestedThoughts)
          })}> Plexus Suggestions </button>
          <button onClick={(() => {
            console.log("hit")
            backendMetaphorReturnLinks(`a blog post about "No one watches the news anymore because they never provide any solutions to the problem reported and only exist to infuriate you for clicks and likes.":`).then((result) => {
              callScraper(result)
            })
          })}> test </button>
        </div>
      </div>
    </div>
  )
}

export default DataProcessorBI