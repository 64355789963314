import { backendWriter } from "./Components/App"
import { SuggestedThoughtInfo } from "./Components/Feed/GetSuggestedThoughts"
import { getSuggestionsFromPersonBucket } from "./Components/Feed/ThoughtsSection/SuggestedThreads/SuggestedThreads"
import { EdgeAndThoughtPair } from "./Components/Feed/UpdatesSection/AllUpdates/AllUpdates"
import { PersonFirebaseBucket } from "./Firebase/FirebaseWriter"
import {
  ConnectionKind,
  PostMap,
  SingleConnectionUpdateForAPerson,
  TextPost,
} from "./ReactContexts/PostContext"

const edgeTimestampIsSeen = (
  edgeTime: number,
  lastSeenTimestamp: number,
  msBuffer: number = 0
): boolean => {
  return edgeTime < lastSeenTimestamp - msBuffer
}

/**
 * Determines whether thought has been interacted with in more significant way than whether it's been seen
 * Simpler for now to keep track this way?
 * @param thought
 * @returns
 */
export const thoughtIsVisited = (thought: TextPost, personId: string): boolean => {
  return personId in (thought?.visitors ?? {})
}

export const getUnseenSuggestions = async (
  personId: string,
  personBucket: PersonFirebaseBucket
): Promise<SuggestedThoughtInfo[]> => {
  const allSugg = await getSuggestionsFromPersonBucket(personId, personBucket)
  const lastSeenTimestamp = personBucket?.timestamps?.lastPeakedSuggestionsThreads ?? 0
  const unseen = getUnseenSuggestionsFromAllSuggestions(allSugg, lastSeenTimestamp)

  return unseen
}
const getUnseenSuggestionsFromAllSuggestions = (
  suggestions: SuggestedThoughtInfo[],
  lastSeenTimestamp: number
): SuggestedThoughtInfo[] => {
  return suggestions.filter((e) => !edgeTimestampIsSeen(e.timestamp, lastSeenTimestamp))
}

const getInboundReplies = (
  personBucket: PersonFirebaseBucket,
  personId: string,
  posts: PostMap
): SingleConnectionUpdateForAPerson[] => {
  return getInboundEdgesOfType(personBucket, personId, posts, ConnectionKind.REPLY)
}
const getInboundRelates = (
  personBucket: PersonFirebaseBucket,
  personId: string,
  posts: PostMap
): SingleConnectionUpdateForAPerson[] => {
  return getInboundEdgesOfType(personBucket, personId, posts, ConnectionKind.RELATE)
}

const getInboundGrandparents = (
  personBucket: PersonFirebaseBucket,
  personId: string,
  posts: PostMap
): SingleConnectionUpdateForAPerson[] => {
  return getInboundEdgesOfType(personBucket, personId, posts, ConnectionKind.GRANDPARENT)
}

const getInboundEdgesOfType = (
  personBucket: PersonFirebaseBucket,
  personId: string,
  posts: PostMap,
  connectionKind: ConnectionKind
): SingleConnectionUpdateForAPerson[] => {
  const inbound = Object.values(personBucket?.connections?.inbound ?? {})
  const edgesArray: SingleConnectionUpdateForAPerson[] = inbound.filter(
    (connection: SingleConnectionUpdateForAPerson) => connection?.edgeKind === connectionKind
  )
  const edgesFromOthers: SingleConnectionUpdateForAPerson[] = edgesArray.filter(
    (reply: SingleConnectionUpdateForAPerson) => reply.authorId !== personId
  )
  const defined: SingleConnectionUpdateForAPerson[] = edgesFromOthers.filter(
    //not checking for source cuz may not be there yet
    (connection: SingleConnectionUpdateForAPerson) =>
      connection.targetThoughtId in posts && connection.sourceId in posts
  )
  const ordered = defined.reverse()
  return ordered
}

export const getAllUpdates = (
  posts: PostMap,
  personBucket: PersonFirebaseBucket,
  personId: string,
  unreadAtTop: boolean
) => {
  //Get replies
  // Get all replies for the person from the personBucket
  const replies = getInboundReplies(personBucket, personId, posts)
  // Format replies into EdgeAndThoughtPair objects
  const repliesFormatted = formatUpdates(replies, posts)

  //Get relates
  const relates = getInboundRelates(personBucket, personId, posts)
  const relatesFormatted = formatUpdates(relates, posts)
  const grandparents = getInboundGrandparents(personBucket, personId, posts)
  const grandParentsFormatted = formatUpdates(grandparents, posts)

  const allUpdateEdgesFormatted = [
    ...repliesFormatted,
    ...relatesFormatted,
    ...grandParentsFormatted,
  ]

  // Filter out any invalid updates
  const allUpdates = filterValidUpdates(allUpdateEdgesFormatted)
    .sort((a, b) => b.edge.timestamp - a.edge.timestamp)
    .slice(0, 200)
  // Sort the updates based on unreads first
  const sortedUpdates = sortUpdatesByUnvisited(allUpdates, unreadAtTop)
  // Remove duplicate updates
  const uniqueUpdates = filterDuplicateUpdates(sortedUpdates)
  // Return the unique updates
  return uniqueUpdates
}

// format replies into EdgeAndThoughtPairs
const formatUpdates = (
  replies: SingleConnectionUpdateForAPerson[],
  posts: PostMap
): EdgeAndThoughtPair[] => {
  return replies.map((reply) => {
    const sourceThought = posts[reply.sourceId]
    const targetThought = posts[reply.targetThoughtId]
    return { sourceThought, targetThought, edge: reply }
  })
}

// TODO: This function will need to be rewritten as it relies on all thoughts...

// Filter out any invalid updates
const filterValidUpdates = (updates: EdgeAndThoughtPair[]): EdgeAndThoughtPair[] => {
  return updates.filter((e) => e.edge.timestamp && e.targetThought.text && e.sourceThought.text)
}

const sortUpdatesByUnvisited = (
  updates: EdgeAndThoughtPair[],
  unreadAtTop: boolean
): EdgeAndThoughtPair[] => {
  if (unreadAtTop) {
    return updates.sort((a, b) => b.edge.timestamp - a.edge.timestamp)
  }
  return updates
}

// Remove duplicate updates
const filterDuplicateUpdates = (updates: EdgeAndThoughtPair[]): EdgeAndThoughtPair[] => {
  return updates.reduce((arrSoFar: EdgeAndThoughtPair[], nextUpdate) => {
    if (arrSoFar.map((val) => val.sourceThought.id).includes(nextUpdate.sourceThought.id)) {
      return arrSoFar
    }
    return [...arrSoFar, nextUpdate]
  }, [])
}
export const getUnseenUpdates = (
  personId: string,
  personBucket: PersonFirebaseBucket,
  posts: PostMap
): EdgeAndThoughtPair[] => {
  const updates = getAllUpdates(posts, personBucket, personId, false) //doesn't matter if unseen at top
  return updates.filter((edgeAndThoughtPair: EdgeAndThoughtPair) => {
    const { edge } = edgeAndThoughtPair
    const isRead = edgeTimestampIsSeen(
      edge.timestamp,
      //need to change this to lastPeakedUpdates
      personBucket?.timestamps?.lastPeakedAllUpdates ?? 0
    )
    return !isRead
  })
}
