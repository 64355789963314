import { signInWithPopup, User } from "firebase/auth"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { auth, backendWriter, provider } from "../App"
import domainDetails from "../../DomainDetails"
import "./PrivateRoute.css"
import FirebaseDataContainer from "../FirebaseDataContainer"
import { useEffect } from "react"

const usernameLocationLocal = "plexus-anon-name" // "place-username"
export let usernamex = localStorage.getItem(usernameLocationLocal) ?? ""

export const runOnboardingMessages = () => {
  while (!usernamex) {
    usernamex = window.prompt("What's your first name?") ?? ""
  }
  localStorage.setItem(usernameLocationLocal, usernamex)
  backendWriter.setName(usernamex)
  return usernamex
}

/**
 * A page that redirects to landing page if not logged in, but enters plexus app if person is logged in
 * @param param0
 * @returns
 */
const PrivateRoute = ({ person }: { person?: User }) => {
  const { placeId, thoughtId } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    localStorage.setItem("plexus-thoughtId", thoughtId)
  }, [thoughtId])

  const name =
    placeId in domainDetails && "name" in domainDetails[placeId]
      ? domainDetails[placeId].name
      : `p/${placeId ?? ""}`

  if (placeId === "forum" && !person && !thoughtId) return <Navigate to={"/"} />
  //when is oriented is defined, run firebase function then delete it

  return person ? (
    <FirebaseDataContainer />
  ) : (
    <div className="login-page">
      <span style={{ display: "inline-flex", lineHeight: "2em" }}>{name}</span>

      <span
        style={{
          fontSize: ".7em",
          textAlign: "center",
          fontWeight: "normal",
          maxWidth: "500px",
          margin: "1em",
          lineHeight: "1.5em",
        }}
      ></span>
      <div
        className="login-button"
        onClick={() => {
          navigate((placeId ?? "forum") + "/authenticating")
          //used to redirect
          signInWithPopup(auth, provider)

          // emailSignInProcess()
        }}
      >
        get started
      </div>
      <a
        style={{
          color: "gray",
          fontSize: ".6em",
          fontWeight: "normal",
          fontStyle: "italic",
          textDecoration: "none",
        }}
        href=" https://docs.google.com/document/d/1JvcOJpZJTIXtUfVmN3PVEtYG4POcKjNjVePKJC8WpW8/edit?usp=sharing"
      >
        privacy
      </a>
      <br></br>
      <br></br>
    </div>
  )
}

export default PrivateRoute
