import { useContext, useEffect, useState } from "react"
import AuthContext from "../../../../ReactContexts/AuthContext"
import PostContext, {
  SingleConnectionUpdateForAPerson,
  TextPost,
} from "../../../../ReactContexts/PostContext"
import { getAllUpdates } from "../../../../UpdatesLogic"
import { backendWriter } from "../../../App"
import ThoughtPair from "../ThoughtPair/ThoughtPair"
import "./AllUpdates.css"

export interface EdgeAndThoughtPair {
  sourceThought: TextPost
  targetThought: TextPost
  edge: SingleConnectionUpdateForAPerson
}

const AllUpdates = () => {
  const { personBucket, posts } = useContext(PostContext)
  const { person } = useContext(AuthContext)

  const allUpdates = getAllUpdates(posts, personBucket, person.uid, false)
  const [numToShow, setNumToShow] = useState(50)

  //record peak
  useEffect(() => {
    //record the last time person has viewed suggestions
    backendWriter.recordAllUpdatesPeak()
  }, [person])

  return (
    <div className="connected-updates-container">
      {allUpdates
        .slice(0, numToShow)
        .map(({ sourceThought, targetThought, edge }: EdgeAndThoughtPair) => {
          //reverse these for now to counteract weird shit
          if (!sourceThought || !targetThought) return
          //flip order to present connection choice

          return (
            <ThoughtPair
              {...{
                timestamp: edge.timestamp,
                rootThought: targetThought, //target thought in incoming connection should be yours
                suggestedThought: sourceThought, // source thought in incoming connection should be theirs
                edge: edge,
              }}
            />
          )
        })}
      {numToShow < allUpdates.length ? (
        <span
          className="centered-gray-text"
          onClick={() => {
            setNumToShow((x) => x + 20)
          }}
        >
          show more...
        </span>
      ) : (
        <span></span>
      )}
      {allUpdates.length > 0 ? (
        <></>
      ) : (
        <div style={{ textAlign: "center", color: "gray" }}>
          This is where you'll see connections and replies from the community.
          <br></br> <br></br>
          To get started, go to Thoughts and express something raw.
        </div>
      )}
    </div>
  )
}

export default AllUpdates
