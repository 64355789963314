import { useEffect, useState } from "react"
import { TextPost } from "../../../ReactContexts/PostContext"
import "./AdminBeautifulInviter.css"
import DetailedViewBI from "./BeautifulInviter/DetailedViewBI"

const AdminBeautifulInviter = ({ thoughts }) => {
  var allThoughts = {}
  for (var i = 0; i < thoughts.length; i++) {
    allThoughts[thoughts[i].id] = thoughts[i]
  }

  interface displayedThought {
    newThought: string
    connectedThoughts: TextPost[]
  }

  const [selectedThoughts, setSelectedThoughts] = useState<string[]>([])
  const [suggestedThoughts, setSuggestedThoughts] = useState<any>([])
  const [displayedThoughts, setDisplayedThoughts] = useState<displayedThought[]>([])

  useEffect(() => {
    let displayedThoughtList: displayedThought[] = selectedThoughts.map((selected) => {
      return {
        newThought: selected,
        connectedThoughts: [],
      }
    })
    setDisplayedThoughts(displayedThoughtList)
    setSuggestedThoughts([])
  }, [selectedThoughts])

  return (
    <>
      <h3> Beautiful Inviter </h3>
      <DetailedViewBI thoughts={thoughts} />
    </>
  )
}

export default AdminBeautifulInviter
