import axios from "axios"

declare module "axios" {
  export interface AxiosRequestConfig {
    crossdomain: boolean
  }
}

// Returns an array of arrays of the top ids by similarity score when passing in an array of thought ids and n suggestions requested
export const backendGetThoughtSuggestionsArray = (
  ids: string[],
  n: number,
  namespace: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        // `http://localhost:6080/`,
        `https://us-central1-community-3ffbb.cloudfunctions.net/getThoughtSuggestionsArray-getThoughtSuggestionsArray/`,
        {
          crossdomain: true,
          params: {
            ids: ids,
            n: n,
            namespace: namespace,
          },
        }
      )
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// Adds a vector and related id to the pinecone database
export const backendUpsertEmbedding = (id: string, vector: number[], namespace: string) => {
  console.log("calling backend upsert")
  return new Promise((resolve, reject) => {
    axios
      .post(
        `https://us-central1-community-3ffbb.cloudfunctions.net/upsertEmbedding-upsertEmbedding/upsert`,
        // `http://localhost:6060/upsert`,
        {
          crossdomain: true,
          body: { id: id, vector: vector, namespace: namespace },
        }
      )
      .then((response) => {
        resolve(response.data)
        console.log(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteThoughtById = (ids: string[], namespace: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `https://us-central1-community-3ffbb.cloudfunctions.net/deleteEmbeddingByIds-deleteEmbeddingByIds/`,
        {
          crossdomain: true,
          params: {
            ids: ids,
            namespace: namespace,
          },
        }
      )
      .then((response) => {
        resolve(response.data)
        console.log(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const backendGetThoughtSuggestionsByVector = (
  vector: number[],
  n: number,
  namespace: string
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `https://us-central1-community-3ffbb.cloudfunctions.net/getThoughtSuggestionsByVector-getThoughtSuggestionsByVector?vector=${vector}&n=${n}&namespace=${namespace}`,
        // `http://localhost:6120/?vector=${vector}&n=${n}`,
        {
          crossdomain: true,
        }
      )
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const backendScrapeWebsite = (site: string | string[]) => {
  if (typeof site === "object") site.length = 100
  console.log("starting website scrape")
  return new Promise<string>((resolve, reject) => {
    axios
      .get(`https://us-central1-community-3ffbb.cloudfunctions.net/scrapeSite-scrapeSite/`, {
        // .get(`http://localhost:7023/`, {
        crossdomain: true,
        params: { site: site },
      })
      .then((response) => {
        console.log("Completed scrape")
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const backendScrapeWebsiteAddSuggestions = () => {
  console.log("Attempting to add suggestions")
  return new Promise<string>((resolve, reject) => {
    axios
      .get(
        `https://us-central1-community-3ffbb.cloudfunctions.net/scrapeSite-scrapeSite/addSuggestions`,
        // `http://localhost:7023/addSuggestions`,
        {
          crossdomain: true,
        }
      )
      .then((response) => {
        console.log(response.data)
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// Calls backend function to assign openai texts and transfer firebase data to next step of beautiful inviter
export const backendAddOpenAiText = () => {
  console.log("Attempting to add OpenAi Text")
  return new Promise<string>((resolve, reject) => {
    axios
      .get(
        `https://us-central1-community-3ffbb.cloudfunctions.net/scrapeSite-scrapeSite/addOpenAiTexts`,
        // `http://localhost:7023/addOpenAiTexts`,
        {
          crossdomain: true,
        }
      )
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const backendMetaphorReturnLinks = (text: string) => {
  console.log("calling metaphor")
  return new Promise<any>((resolve, reject) => {
    axios
      .get(
        `https://us-central1-community-3ffbb.cloudfunctions.net/metaphorReturnLinks-metaphorReturnLinks/`,
        // `http://localhost:5020/`,
        {
          crossdomain: true,
          params: { text: text },
        }
      )
      .then((response) => {
        console.log(`Metaphor links found: ${response.data.length}`)
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const backendGetFirebaseDataFromPath = (path: string) => {
  console.log("Attempting to retrieve data from firebase")
  return new Promise<any>((resolve, reject) => {
    axios
      .get(
        `https://us-central1-community-3ffbb.cloudfunctions.net/getThoughtById-getThoughtById`,
        // `http://localhost:5020/`,
        {
          crossdomain: true,
          params: { path: path },
        }
      )
      .then((response) => {
        console.log(`Data received.`)
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteInviteData = (path: string, id: string) => {
  if (!path || !id) {
    console.error("Missing path or id in deleteInviteData. Check function call.")
    return
  }
  return new Promise<string>((resolve, reject) => {
    axios
      .get(
        `https://us-central1-community-3ffbb.cloudfunctions.net/getThoughtById-getThoughtById/delete`,
        // `http://localhost:5020/`,
        {
          crossdomain: true,
          params: { path: path, id: id },
        }
      )
      .then((response) => {
        console.log(`Data received.`)
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const upsertInviteData = (path: string, data: any) => {
  if (!path || !data) {
    console.error("Missing path or id in upsertInviteData. Check function call.")
    return
  }
  return new Promise<string>((resolve, reject) => {
    axios
      .get(
        `https://us-central1-community-3ffbb.cloudfunctions.net/getThoughtById-getThoughtById/upsert`,
        // `http://localhost:5020/`,
        {
          crossdomain: true,
          params: { path: path, data: data },
        }
      )
      .then((response) => {
        console.log(`Data received.`)
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addInviteData = (path: string, data: any) => {
  if (!path || !data) {
    console.error("Missing path or id in upsertInviteData. Check function call.")
    return
  }
  return new Promise<string>((resolve, reject) => {
    axios
      .post(
        `https://us-central1-community-3ffbb.cloudfunctions.net/getThoughtById-getThoughtById/add`,
        // `http://localhost:5030/add`,
        {
          crossdomain: true,
          body: { path: path, data: data },
        }
      )
      .then((response) => {
        console.log(`Data received.`)
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getMeanWeightedEmbedding = (ids: string[], namespace: string) => {
  return new Promise<string>((resolve, reject) => {
    axios
      .get(
        `https://us-central1-community-3ffbb.cloudfunctions.net/getMeanWeightedEmbedding-getMeanWeightedEmbedding`,
        // `http://localhost:6140/`,
        {
          crossdomain: true,
          params: { ids: ids, namespace: namespace },
        }
      )
      .then((response) => {
        console.log(`Embedding data average received.`)
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// https://us-central1-community-3ffbb.cloudfunctions.net/write
export const addFamilyEdges = (id: string) => {
  return new Promise<string>((resolve, reject) => {
    axios
      .get(
        `https://us-central1-community-3ffbb.cloudfunctions.net/write/addFamilyEdges`,
        // `http://localhost:6140/`,
        {
          crossdomain: true,
          params: { id: id },
        }
      )
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
