import { TextPost } from "../../../ReactContexts/PostContext"

// Shortcut function to lookup (inefficiently) author emails by ID
export const retrieveAuthorName = (thoughts: TextPost[], authorId: string) => {
  for (let i = thoughts.length - 1; i > 0; i--) {
    if (thoughts[i].authorId === authorId) {
      return thoughts[i].authorName
    }
  }
}

// Shortcut function to lookup (inefficiently) author emails by ID
export const retrieveAuthorEmail = (thoughts: TextPost[], authorId: string) => {
  for (let i = 0; i < thoughts.length; i++) {
    if (thoughts[i].authorId === authorId) {
      return thoughts[i].authorEmail
    }
  }
}
