// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC-waTdHxHmLQFNjlpvrMf8Qu94Pe4HMyg",
  authDomain: "community-3ffbb.firebaseapp.com",
  projectId: "community-3ffbb",
  storageBucket: "community-3ffbb.appspot.com",
  messagingSenderId: "1049002315506",
  appId: "1:1049002315506:web:442f2a08ee8fbfae6fca6b",
  measurementId: "G-CWD5NNJYJV",
}
export default firebaseConfig
