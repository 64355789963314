import { useEffect, useState } from "react"
import { getReddit } from "../../../../ManualTransfers/RedditImport"
import { AuthorContent } from "../../../../Types/types"
import CollectedItem from "./CollectedItem"
import { loader } from "./loading"
import Metaphor from "./Metaphor"

const DataCollectorBI = ({ selectedTexts, setSelectedTexts }) => {
  const [authorText, setAuthorText] = useState<string>("")
  const [subredditText, setSubredditText] = useState<string>("")
  const [collectorView, setCollectorView] = useState<string>("metaphor")
  const [queryText, setQueryText] = useState<string>("")
  const [redditContents, setRedditContents] = useState<AuthorContent[]>(selectedTexts)
  // const [metaphorContents, setMetaphorContents]
  const [showAdvancedOptions, setShowAdvancedOptions] = useState<boolean>(true)
  const [filterLinks, setFilterLinks] = useState<boolean>(false)
  const [minWords, setMinWords] = useState<number>(0)
  const [maxWords, setMaxWords] = useState<number>(500)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingText, setLoadingText] = useState<string>("Loading ")

  const applyAdvancedOptions = (contents: AuthorContent[]) => {
    if (filterLinks) contents = removeLinks(contents)
    if (minWords) contents = removeIfBelowMinWords(contents)
    if (maxWords) contents = removeIfBelowMaxWords(contents)
    return contents
  }

  const handleAuthorChange = (e) => {
    setAuthorText(e.target.value)
  }

  const handleSubredditChange = (e) => {
    setSubredditText(e.target.value)
  }

  const handleQueryChange = (e) => {
    setQueryText(e.target.value)
  }

  const resetAllStates = () => {
    setAuthorText("")
    setSubredditText("")
    setQueryText("")
    setRedditContents([])
    setFilterLinks(false)
    setMinWords(0)
    setMaxWords(500)
    setSelectedTexts([])
    setLoading(false)
    setLoadingText(" Loading")
  }

  // Filter function for removing any text with "http" in it, could be expanded for markdown style links later
  const removeLinks = (contents: AuthorContent[]): AuthorContent[] => {
    return contents.filter((content) => {
      return !content.text.includes("http")
    })
  }

  // Filter functions for removing all objects that have text under the minWords length
  const removeIfBelowMinWords = (contents: AuthorContent[]): AuthorContent[] => {
    return contents.filter((content) => {
      return content.text.split(" ").length >= minWords
    })
  }

  // Filter functions for removing all objects that have text over the maxWords length
  const removeIfBelowMaxWords = (contents: AuthorContent[]): AuthorContent[] => {
    return contents.filter((content) => {
      return content.text.split(" ").length <= maxWords
    })
  }

  // Pass in an array of object and an id. It will return the element without the object containg that id key:value
  const removeObjectWithId = (arr, id) => {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id)
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1)
    }
    return arr
  }

  // Loader incrementing.
  const callLoader = (text: string) => {
    setTimeout(() => {
      if (loading) {
        setLoadingText(loader(text))
      }
    }, 500)
  }

  // If loading text changes, starts the loading increment loop.
  useEffect(() => {
    if (loading) {
      callLoader(loadingText)
    }
  }, [loadingText])

  // If redditcontents is reset, turn loader off
  useEffect(() => {
    if (redditContents.length && loading) {
      setLoading(false)
      setLoadingText("Loading ")
    }
  }, [redditContents])

  // When an element is selected, adds it to the textarea content and also changes selected status in reddit contents
  const handleSelection = (content: AuthorContent, state: AuthorContent, setState: Function) => {
    content.selected = !content.selected

    // Replaces state with the selected status by removing the object from the array and replacing it with correct key,value pair
    let a = removeObjectWithId(state, content.id)
    setState([content, ...a])
    // Keeps any object that has a true selected attribute
    let b = [content, ...a].filter((contents) => {
      return contents.selected
    })
    setSelectedTexts(b)
  }

  return (
    <div>
      <div className="topBarCollectContainer">
        <div
          className="topBarButton"
          onClick={() => {
            setCollectorView("reddit")
          }}
        >
          {" "}
          Reddit{" "}
        </div>
        <div
          className="topBarButton"
          onClick={() => {
            setCollectorView("metaphor")
          }}
        >
          {" "}
          Metaphor (Coming Soon){" "}
        </div>
      </div>
      {collectorView === "reddit" ? (
        <div>
          <div className="getterContainer">
            <input
              className="getterInputField"
              placeholder="Author"
              value={authorText}
              onChange={handleAuthorChange}
            ></input>
            <input
              className="getterInputField"
              placeholder="Subreddit"
              value={subredditText}
              onChange={handleSubredditChange}
            ></input>
            <input
              className="getterInputField"
              placeholder="Keywords"
              value={queryText}
              onChange={handleQueryChange}
            ></input>
            <div className="buttonContainer">
              <span
                className="getterButton"
                onClick={() => {
                  setRedditContents([])
                  setLoading(true)
                  setLoadingText("Loading  ")
                  getReddit(authorText, subredditText, queryText).then((result: any) => {
                    let resultWithSelected = result.map((r: any, i: any) => {
                      return { ...r, ...{ selected: false }, ...{ id: i } }
                    })
                    setRedditContents(resultWithSelected)
                  })
                }}
              >
                {" "}
                Load{" "}
              </span>
              <span
                className="getterButton"
                onClick={() => {
                  resetAllStates()
                }}
              >
                {" "}
                Reset{" "}
              </span>
            </div>
            {
              <div className="optionsContainer">
                <div
                  className="advancedOptionsToggle"
                  onClick={() => {
                    setShowAdvancedOptions(!showAdvancedOptions)
                  }}
                >
                  {" "}
                  {showAdvancedOptions ? "Show Advanced Options ▼" : "Hide Advanced Options ▲"}{" "}
                </div>
                {showAdvancedOptions ? (
                  <>
                    <div className="advancedOptionRow">
                      <input type="checkbox"></input>
                      <span> Smart Filter (Coming Soon) </span>
                    </div>
                    <div className="advancedOptionRow">
                      <input
                        type="checkbox"
                        checked={filterLinks}
                        onChange={() => setFilterLinks(!filterLinks)}
                      ></input>
                      <span> Remove thoughts with links </span>
                    </div>
                    <div className="advancedOptionRow">
                      <input
                        className="numberInput"
                        type="number"
                        value={minWords}
                        onChange={(e) => setMinWords(parseInt(e.target.value))}
                      ></input>
                      <span> Min words </span>
                      <input
                        className="numberInput"
                        type="number"
                        value={maxWords}
                        onChange={(e) => setMaxWords(parseInt(e.target.value))}
                      ></input>
                      <span> Max words </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            }
          </div>
          <div className="fetchedContainer">
            {redditContents.length ? (
              <div>
                Found {applyAdvancedOptions(redditContents).length} valid thoughts.
                {applyAdvancedOptions(redditContents).map((content, i) => {
                  return (
                    <div key={"content-" + i}>
                      <CollectedItem
                        content={content}
                        selectionHandler={handleSelection}
                        state={redditContents}
                        setState={setRedditContents}
                      />
                    </div>
                  )
                })}
              </div>
            ) : (
              <div>
                {!loading ? `Input parameters and click "Load" to get thoughts.` : loadingText}
              </div>
            )}
          </div>
        </div>
      ) : (
        <Metaphor handleSelection={handleSelection} />
      )}
    </div>
  )
}

export default DataCollectorBI
