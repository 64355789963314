import { useMemo, useState } from "react"
import {
  backendGetFirebaseDataFromPath,
  backendScrapeWebsite,
} from "../../../../Firebase/FirebaseFunctionPointers"

interface MetaphorLink {
  id: string
}

const ScrapeBI = () => {
  const [links, setLinks] = useState<MetaphorLink>()
  useMemo(() => {
    backendGetFirebaseDataFromPath("invites/metaphor").then((result) => {
      setLinks(result)
    })
  }, [])

  const callScraper = async (url: string | string[]) => {
    // if (typeof(url) === "object") url.splice(10)
    await backendScrapeWebsite(url).catch((e) => {
      console.error(e)
    })
  }

  return (
    <div className="dataProcessingContainer">
      <div className="dataProcessingAreaInviter">
        <h2> Scraping {links ? Object.values(links).length : ""} </h2>
        <div className="processingButtonContainer">
          <button
            onClick={() => {
              callScraper(Object.values(links))
            }}
          >
            {" "}
            Scrape{" "}
          </button>
        </div>
        <ol>
          {links
            ? Object.values(links).map((link, i) => {
                return (
                  <li key={"scraped-link-" + i}>
                    <a className="metaphorHyperlink" href={link}>
                      {link}
                    </a>
                  </li>
                )
              })
            : ""}
        </ol>
      </div>
    </div>
  )
}

export default ScrapeBI
