import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { createEditor, Descendant } from "slate"
import { withReact } from "slate-react"
import AuthContext from "../../../ReactContexts/AuthContext"
import { backendWriter } from "../../App"

import PostContext, {
  EdgeInfo,
  PersonThoughtInteractionType,
  TextPost,
} from "../../../ReactContexts/PostContext"
import EditorContainer, { isMobile } from "../../Editor/EditorContainer"
import "./Thought.css"
import ThoughtFooter from "./ThoughtFooter/ThoughtFooter"
import moment from "moment"
import { thoughtIsVisited } from "../../../UpdatesLogic"
import { getShorthandTimeFromTimestamp } from "../../../Logic/TimestampLogic"
import { checkIfConnection } from "../GetSuggestedThoughts"
import { ParagraphElement } from "../../Editor/EditorTSConfig"
import { abbreviate } from "../../../Logic/Utilities"
import ReplyInputField from "../../Detail/ReplyInputField/ReplyInputField"
import { emailAuthor } from "../../PeopleSection/Chat/ChatPreview/ChatPreview"
import {
  getReplyThoughtIdsFromParent,
  getReplyThoughtsFromParent,
} from "../../../Firebase/ReplyUtilities"
export type EdgeMap = { [postId: string]: EdgeInfo }

export const ExpansionEnabledEverywere = true
export type ThoughtDisplayType =
  | "my-thought"
  | "suggested-thought"
  | "connected-thought"
  | "principal-thought"
  | "breadcrumb"
  | "update-thought"
  | "update-source"

//communal / personal are the enlightened options (newer ones)
export type TabViewStatusOptions =
  | "replies"
  | "suggestions"
  | "connections"
  | "communal"
  | "personal"
  | "related-threads"

/**
 * Enormous component that deals with everything that goes in a Thought
 * @param post contains all important info about the thought being rendered here
 * @param originStory janky var, outdated now just used now to store whether this thought is principal or not (whether its the focus of its own page)
 * @param lineage stores the lineage of the given thought, if it has parent thoughts. lineage is an array of thought ids.
 * @param breadcrumb stores whether this thought is a breadcrumb or not. only a breadcrumb if it's the ancestor of another
 * @param providedExpanded whether this thought should be "expanded" by default. whether it's replies should be showing beneath it
 * @param isReply whether this thought is rendered as a reply to a parent thought
 * @param promptPost only defined if this thought is the second in a "suggested thought pair" or in an inbox "relates/reply" notification.
 * @param promptRelation a description of the relationship btw the prompt post and the post post, defined only if the promptPost is defined
 * @param displayType describes how this thought is beign displayed, a more descriptive version of originStory (could probably replace origin story)
 * @returns
 * displayType
 */
const Thought = ({
  post,
  lineage,
  isReply,
  displayType,
  providedTabViewStatus,
  parentThought,
  providedReplyEmpty,
  providedSetReplyEmpty,
  providedShowReplyField,
  providedAutoFocus,
}: {
  post: TextPost
  lineage?: string[]
  isReply?: boolean
  displayType: ThoughtDisplayType
  providedTabViewStatus?: TabViewStatusOptions
  parentThought?: TextPost
  providedReplyEmpty?: boolean
  providedSetReplyEmpty?: Dispatch<SetStateAction<boolean>>
  providedShowReplyField?: boolean
  providedAutoFocus?: boolean
}) => {
  //the updated author ID
  const { person } = useContext(AuthContext)
  const { posts, myNodes, displaySettings, setDisplaySettings } = useContext(PostContext)
  const { placeId } = useParams()
  const domainId = placeId ?? "forum"
  //breadcrumb is a parent thought up the reply hierarchy, which shows up when you're focused on a current reply
  const isBreadcrumb = displayType === "breadcrumb"
  //update source is the faded gray thought you see to give context on where/why another thought was touched.
  const isUpdateSource = displayType === "update-source"

  // const isBreadcrumb = displayType === "breadcrumb"
  const replies = getReplyThoughtIdsFromParent(post)
  const IveReplied = replies?.filter((e) => e in myNodes)?.length > 0
  const [showReplyField, setShowReplyField] = useState(
    (!isMobile() && displayType === "principal-thought") ||
      (post?.authorId !== person.uid &&
        !IveReplied &&
        (displayType === "suggested-thought" || displayType === "update-thought"))
  )
  const [replyEmpty, setReplyEmpty] = useState(providedReplyEmpty)

  const alreadyConnected =
    parentThought && checkIfConnection(parentThought, post, undefined, person.uid)
  //already connected, or this is the root thought and its the same author
  const clickable =
    alreadyConnected || ((!lineage || lineage?.length === 0) && post?.authorId === person?.uid)

  const navigate = useNavigate()

  const [editor] = useState(() => withReact(createEditor()))
  const value: ParagraphElement[] = (post?.slateValue?.filter(
    (e: any) => e?.children[0]?.text.length > 1
  ) ?? []) as ParagraphElement[]

  //based on whether it's a breadcrumb
  const adjustedValue = isUpdateSource ? getBreadcrumbChildren(value) : value

  const handleChange = (val: Descendant[]) => {
    return val
  }
  // Controls if suggestions tab, connections tab, or neither is shown within parent thought tab
  const [tabViewStatus, setTabViewStatus] = useState<TabViewStatusOptions | undefined>(
    providedTabViewStatus
  )
  const lineageLength = lineage?.length
  const moreThanOneLevelDeep = lineageLength > 0

  //detect whether the either suggestions haven't been seen yet or an incoming connection has a timestamp greater than lastExpanded
  //for now, just look at whether lastExpanded exists at all for this author
  const applyUnvisitedStyling = (thought: TextPost, onlookerId: string): boolean => {
    //get whether the thought is visited and not mine
    return !thoughtIsVisited(thought, onlookerId) && thought?.authorId !== onlookerId
  }

  //when tab view status is replies, mark the thought as expanded
  useEffect(() => {
    if (tabViewStatus === "replies") {
      backendWriter.recordPersonThoughtInteraction(
        post?.id,
        PersonThoughtInteractionType.REPLIES_EXPANDED
      )
    } else if (tabViewStatus === "suggestions") {
      backendWriter.recordPersonThoughtInteraction(
        post?.id,
        PersonThoughtInteractionType.SUGGESTIONS_EXPANDED
      )
    }
  }, [tabViewStatus])

  if (!post) return <div></div>

  const titleTextToShow = post?.title ?? "Untitled"
  //don't show title right now--as part of davey simplification experiment
  const showThisThoughtTitle =
    !("isReply" in (post ?? {})) && (post?.title || post?.authorId === person?.uid)

  // This converts long form momentjs into shortform. e.g.,  "2 years ago" becomes "2y"
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "%ds",
      m: "%dm",
      mm: "%dm",
      h: "%dh",
      hh: "%dh",
      d: "%dd",
      dd: "%dd",
      M: "%dmo",
      MM: "%dmo",
      y: "%dy",
      yy: "%dy",
    },
  })
  if (!post) return <div></div>

  //whether this thought is either connected to one of my thoughts, or it is one of my thoughts
  const unvisited = applyUnvisitedStyling(post, person.uid)

  //whether can email
  const emailable = false && post?.authorId !== person.uid

  //made all these levels of containers at some point for css bs. probably could simplify the levels eventually

  return value ? (
    <>
      <div
        className={
          "feed-item-container " +
          (showReplyField ? " showing-reply-field" : "") +
          (post?.authorId === person.uid ? " mine" : "") +
          (typeof isReply === "boolean" ? (isReply ? " isReply" : " isPinned") : "") +
          //thoughts all stay gray for now unless expanded
          (tabViewStatus
            ? // || !nConnections["outbound"] || post.authorId !== person.uid
              " showingRelated"
            : "") +
          (clickable ? " clickable" : "") +
          (unvisited ? " unvisited" : " visited") +
          (displayType ? " " + displayType : "")
        }
        onClick={() => {
          if (displayType !== "principal-thought") {
            //For now just navigate to thought regardless of whether is connected. Eventually keep track of path via connections.
            // navigate("/p/" + (placeId ?? "forum") + "/" + post.id)
            if (parentThought) {
              //add connection, wait .5 second so person can see it, then navigate
              backendWriter.addConnectionConnectionFromThoughts(parentThought, post, person.uid)
              let a = backendWriter.queryByIds([post.id, "-NDdoxaPemTFOFt3dheI"]).then((result) => {
                console.log(result)
                return result
              })
              setTimeout(() => {
                navigate("/p/" + (placeId ?? "forum") + "/" + post.id)
              }, 600)
            } else navigate("/p/" + (placeId ?? "forum") + "/" + post.id)
          }
        }}
      >
        <div
          key={post.id}
          className={"feed-item bubbley " + (lineageLength > 0 ? "" : " top-level")} //bubbley might depend on detail view itself.
        >
          <div className="feed-item-header">
            {/* either there is a post or its your own post */}
            <div className="row" style={{ alignItems: "center" }}>
              {/* {unvisited ? "*" : ""} */}
              {displaySettings?.showThoughtTitles ? (
                showThisThoughtTitle ? (
                  <>
                    <div
                      className="in-thought-title"
                      onClick={(e) => {
                        e.stopPropagation()
                        if (post?.authorId !== person.uid) return
                        const newTitle = window.prompt("New thread title:", titleTextToShow)
                        if (newTitle) backendWriter.setTitleForThought(post.id, newTitle)
                      }}
                    >
                      {titleTextToShow}
                    </div>
                    &nbsp;&nbsp;
                  </>
                ) : (
                  <>
                    <div>[reply]</div>
                    &nbsp;
                  </>
                )
              ) : (
                <></>
              )}
              <div className="row inthought-text" style={{ alignItems: "center" }}>
                <span className={"author-tag"}>
                  <span
                    className={"author-tag-real " + (emailable ? "emailable" : "")}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (emailable) emailAuthor(post.authorName, post.authorEmail, post.text)
                    }}
                  >
                    {true || displayType === "principal-thought" ? post.authorName ?? "" : ""}
                  </span>
                  {" · " + getShorthandTimeFromTimestamp(post.timestamp)}
                </span>
              </div>
              <div className={"feed-item-buttons row"}>
                {post?.authorId === person?.uid ||
                post?.authorEmail === person?.email ||
                person?.email.includes("plexus") ? (
                  !isBreadcrumb && !isUpdateSource ? (
                    <>
                      <div
                        onClick={(e) => {
                          e.stopPropagation()
                          if (window.confirm("Are you sure you'd like to delete this thought?")) {
                            backendWriter.deletePostAndReplyThoughts(post, posts, placeId)
                          }
                          //if detail view
                          if (displayType === "principal-thought")
                            navigate("/p/" + (domainId ?? ""))
                        }}
                        className={"feed-item-button"}
                      >
                        &nbsp;&nbsp;{true ? "⋮" : ""}
                      </div>
                      &nbsp; &nbsp;
                    </>
                  ) : (
                    //padding to make it bigger to click on, to take up the same room (ish) that "delete" would
                    <span className="delete-button-padder"></span>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className={"thought-text " + displayType}>
            {/* render a uneditable slate editor, representing this thought */}
            {/* the reason we render a slate editor here, and not just the thought's text, is in order to properly render "returns" between paragraphs */}
            <EditorContainer
              {...{
                editor,
                handleChange,
                handleEnter: () => {},

                givenValue: adjustedValue,
                publishedAlready: true,
                idToExclude: post?.id,
              }}
            />
          </div>
          {/* commenting out for now */}
          {!isBreadcrumb && !isUpdateSource ? (
            <ThoughtFooter
              tabViewStatus={tabViewStatus}
              post={post}
              setShowReplyField={setShowReplyField}
              parentThought={parentThought}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {showReplyField ? (
        <ReplyInputField
          {...{
            parentThought: post,
            providedEmpty: replyEmpty,
            providedSetEmpty: setReplyEmpty,
            callback: () => {
              //if not principal thought, close on reply
              if (displayType !== "principal-thought") setShowReplyField(false)
              //visit the thought
              backendWriter.visitPost(post.id)
              // setDefaultFooter("replies")
            },
            providedAutoFocus: providedAutoFocus,
          }}
        />
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  )
}

export default Thought

const getBreadcrumbChildren = (children: ParagraphElement[]): ParagraphElement[] => {
  if (!children || children.length === 0) return []
  //get the first paragraph element
  const firstChild = children[0]
  const firstText = firstChild.children[0].text
  const abbreviatedText = abbreviate(firstText, 60)
  const newPara: ParagraphElement = { type: "paragraph", children: [{ text: abbreviatedText }] }
  return [newPara]
}
