import { getDatabase, onValue, ref } from "firebase/database"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import AdminThoughtTable from "./components/AdminThoughtTableDisplay"
import AdminMatchesMadeViewer from "./components/AdminMatchesMadeViewer"
import "./ThoughtImporter.css"
import AdminBeautifulInviter from "./components/AdminBeautifulInviter"

// Loading down data from firebase
const db = getDatabase()

// Initializes main admin page
const ThoughtImporter = () => {
  const { placeId } = useParams()
  const [allThoughts, setAllThoughts] = useState([])
  const [viewer, setViewer] = useState("BeautifulInviter")

  // Loads data after placeId is identified
  useEffect(() => {
    const domainId = placeId ?? "forum"
    const postsRef = ref(db, "p/" + domainId + "/nodes") //nodes are posts
    onValue(postsRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        // Convert to array
        setAllThoughts(Object.values(value).reverse())
      }
    })
  }, [placeId])

  return (
    <div className="privateAdminContainer">
      <div className="privateAdminContainerNavbar" style={{ display: "inline-flex" }}>
        <span onClick={() => setViewer("MatchesMadeViewer")}>Statistics</span>
        <span onClick={() => setViewer("BeautifulInviter")}> Beautiful Inviter </span>
        {allThoughts.length ? (
          <span onClick={() => setViewer("AllThoughtViewer")}>All Thought Viewer</span>
        ) : (
          <span> Gathering thoughts... </span>
        )}
      </div>
      {viewer === "AllThoughtViewer" ? <AdminThoughtTable thoughts={allThoughts} /> : ""}
      {viewer === "MatchesMadeViewer" ? <AdminMatchesMadeViewer thoughts={allThoughts} /> : ""}
      {viewer === "BeautifulInviter" ? <AdminBeautifulInviter thoughts={allThoughts} /> : ""}
    </div>
  )
}

export default ThoughtImporter
