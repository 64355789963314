import { EdgeInfo } from "../ReactContexts/PostContext"

/**
 * Function to get the Id of the edge's actual author
 *  Before this, we were assuming that the edge's author is the author of the source thought, which is no longer always true now that rabbit holing / vicarious connection-making is possible.
 * TODO write this function with new logic
 * @param e
 */
export function getEdgeAuthor(e: EdgeInfo): string {
  return "edgeAuthorId" in e ? e.edgeAuthorId : e.authorId
}
