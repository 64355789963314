import { backendGetThoughtSuggestionsArray } from "../../Firebase/FirebaseFunctionPointers"
import { thoughtIsReply } from "../../Firebase/ReplyUtilities"
import { getEdgeAuthor } from "../../Logic/ConnectionLogic"
import { ConnectionKind, ConnectionMap, EdgeInfo, TextPost } from "../../ReactContexts/PostContext"
import { PineconeMatch } from "../../Types/types"
import { backendWriter } from "../App"
import { getPrompts } from "../HackyAdmin/HackyAdmin"

export const SUGGESTIONS_SIMILARITY_THRESHOLD = 0.85
export type SuggestedThoughtInfo = {
  rootThought: TextPost
  suggestedThought: TextPost
  score: number
  timestamp: number
}
/**
 * takes as input a list of the person's thoughts, as well as all the thoughts, as well as the embeddings
 * @param myOwnThoughts should just be og thoughts, not replies

 * @returns list of related enough thoughts, in order
 */
export default async function getSuggestedThoughts(
  myOwnThoughts: TextPost[],
  placeId: string
): Promise<SuggestedThoughtInfo[]> {
  const mostRecentFifty = myOwnThoughts.slice(0, 100)
  // Creates an array of my own thought ids from person's most recent thoughts
  const myOwnThoughtIds = mostRecentFifty.map((a) => a.id)
  // Calls backend to trigger pinecone embedding suggestions
  let thoughtsRelatedToOwn
  try {
    // Saves suggested thought Ids to pre-initialized variable (the results returned from pinecone)
    thoughtsRelatedToOwn = await backendGetThoughtSuggestionsArray(myOwnThoughtIds, 100, placeId)
  } catch (e) {
    console.error(e)
  }
  let suggestedThoughtsFinal = []
  // Loops through all of the results returned by the API (pinecone/firebase)
  // TODO: Refactor so that this is not a loop, it only takes in one thought at a time
  for (let i = 0; i < thoughtsRelatedToOwn.length; i++) {
    // Using the original ID sent to the API, finds the original thought in the post list
    const [ourThought] = await backendWriter.queryByIds(thoughtsRelatedToOwn[i].originalID)
    const rawSuggestionsForThisThought: PineconeMatch[] =
      thoughtsRelatedToOwn[i].suggestions.matches
    // Making an array of suggestion ids
    const idsArray = rawSuggestionsForThisThought.map((suggestion) => suggestion.id)
    const potentialSuggestions = (await backendWriter.queryByIds(idsArray)).filter((sugg) => {
      // Ensures author emails aren't the same, and that is not old or new type of reply
      // and that no anti-edge exists between the two thoughts
      return (
        ourThought?.authorEmail !== sugg?.authorEmail &&
        !checkIfReply(sugg) &&
        !thoughtIsReply(sugg) &&
        !checkIfAntiDirectedConnection(ourThought, sugg.id)
      )
    })
    // Add the score property to each result object
    const suggestionsForThisThought = potentialSuggestions.map((sugg) => {
      // Find the corresponding suggestion object in rawSuggestionsForThought
      const suggestion = rawSuggestionsForThisThought.find((s) => s.id === sugg.id)
      // If the suggestion object is found, add the score property to the result object
      if (suggestion) {
        // Return a new object with only the data and score properties
        return { rootThought: ourThought, suggestedThought: sugg, score: suggestion.score }
      }
      return { suggestedThought: sugg }
    })

    suggestedThoughtsFinal = [...suggestedThoughtsFinal, ...suggestionsForThisThought]
  }
  // Objects are automatically ordered from highest to lowest by score.

  // TODO: Add in a method that filters out authors who have not posted in the last n days
  return suggestedThoughtsFinal
}

//Checks if this thought is a reply
const checkIfReply = (thought: TextPost) => {
  const prompts = getPrompts(thought)
  return !(!prompts || !prompts.length || prompts.length === 0)
}

//NEW LOGIC!
//Checks if there's a directed connection from the source thought to the target thought (approval or denial)
const checkIfDirectedConnection = (
  sourceThought: TextPost,
  targetThoughtId: string,
  includeAnti?: true,
  personId?: string
) => {
  const connectionConnections = findDirectedConnections(
    sourceThought,
    targetThoughtId,
    includeAnti,
    personId
  )

  return connectionConnections.length > 0
}

const findDirectedConnections = (
  sourceThought: TextPost,
  targetThoughtId: string,
  includeAnti?: true,
  personId?: string
) => {
  const relevantMap = getOutboundMapIfExists(sourceThought, targetThoughtId)
  if (!relevantMap) return []

  //in the relevant map, check if there's a connection edge that doesn't have anti
  const connectionConnections = findConnectionConnectionsInEdgeMap(
    relevantMap,
    includeAnti,
    personId
  )

  return connectionConnections
}

const getOutboundMapIfExists = (sourceThought: TextPost, targetThoughtId: string) => {
  const outbound = sourceThought?.connections?.outbound
  if (!outbound) return
  const relevantMap = targetThoughtId in outbound ? outbound[targetThoughtId] : undefined
  if (!relevantMap) return
  else return relevantMap
}

export const checkIfConnection = (
  sourceThought: TextPost,
  targetThought: TextPost,
  includeAnti?: true,
  personId?: string
) => {
  const outbound = checkIfDirectedConnection(sourceThought, targetThought.id, includeAnti, personId)
  const inbound = checkIfDirectedConnection(targetThought, sourceThought.id, includeAnti, personId)
  return outbound || inbound
}

const findConnectionConnectionsInEdgeMap = (
  edgeMap?: ConnectionMap,
  includeAnti?: true,
  personId?: string
): EdgeInfo[] => {
  const edges = Object.values(edgeMap ?? {}).filter(
    (edge) =>
      edge.edgeKind === ConnectionKind.CONNECTION &&
      (!("anti" in edge) || includeAnti) &&
      (!personId || getEdgeAuthor(edge) === personId)
  )
  return edges
}

const checkIfAntiDirectedConnection = (sourceThought: TextPost, targetThoughtId: string) => {
  const outbound = sourceThought?.connections?.outbound

  if (!outbound) return false
  const relevantMap = targetThoughtId in outbound ? outbound[targetThoughtId] : undefined
  if (!relevantMap) return false

  //in the relevant map, check if there's a connection edge that doesn't have anti
  const hasAntiConnection = edgeMapHasAntiConnectionConnection(relevantMap)

  return hasAntiConnection
}
const edgeMapHasAntiConnectionConnection = (edgeMap?: ConnectionMap): boolean => {
  const antiEdges = Object.values(edgeMap ?? {}).filter(
    (edge) => edge.edgeKind === ConnectionKind.CONNECTION && edge.anti
  )
  return antiEdges.length > 0
}
