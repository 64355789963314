import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { thoughtIsReply } from "../../../../Firebase/ReplyUtilities"
import { getShorthandTimeFromTimestamp } from "../../../../Logic/TimestampLogic"
import AuthContext from "../../../../ReactContexts/AuthContext"
import PostContext, {
  ConnectionKind,
  SingleConnectionUpdateForAPerson,
  TextPost,
} from "../../../../ReactContexts/PostContext"
import { thoughtIsVisited } from "../../../../UpdatesLogic"
import Thought from "../../FeedItem/Thought"
import "./ThoughtPair.css"
import { abbreviate } from "../../../../Logic/Utilities"
import { personHasReplied } from "../../../../Logic/ReplyLogic"
import { isMobile } from "../../../Editor/EditorContainer"
import { getDatabase, onValue, ref } from "firebase/database"
import { getEdgeAuthor } from "../../../../Logic/ConnectionLogic"

const ThoughtPair = ({
  disabled,
  expandedIds,
  setDisabledThoughtIds,
  disabledThoughtIds,
  setExpandedIds,
  thisOneExpanded,
  rootThought,
  suggestedThought,
  timestamp,
  edge,
}: {
  rootThought: TextPost
  suggestedThought: TextPost
  disabled?: boolean
  expandedIds?: any
  setDisabledThoughtIds?: any
  disabledThoughtIds?: any
  setExpandedIds?: any
  thisOneExpanded?: boolean
  timestamp?: number
  edge?: SingleConnectionUpdateForAPerson
}) => {
  const { person } = useContext(AuthContext)
  const { placeId } = useParams()
  const navigate = useNavigate()

  //reverse direction to see if I've interacted already
  const visited = thoughtIsVisited(suggestedThought, person.uid)
  //Determine if root thought is connected to suggested thought

  const relativeTimeToShow = timestamp ? `${getShorthandTimeFromTimestamp(timestamp)}` : ""
  // TODO: optimize to work with async behavior
  const [replied, setReplied] = useState(false)

  // Add a new useEffect for fetching replied value asynchronously
  useEffect(() => {
    async function fetchRepliedStatus() {
      const hasReplied = await personHasReplied(person.uid, suggestedThought)
      setReplied(hasReplied)
    }

    fetchRepliedStatus()
  }, [person.uid, suggestedThought])

  const [updateAuthorName, setUpdateAuthorName] = useState("")
  useEffect(() => {
    const db = getDatabase()
    const domainId = placeId ?? "forum"
    const theRef = ref(db, "p/" + domainId + "/people/" + getEdgeAuthor(edge) + "/personName")
    onValue(theRef, (data) => {
      if (data.exists()) {
        setUpdateAuthorName(data.val())
      }
    })
  }, [edge])

  const theName = updateAuthorName ?? suggestedThought.authorName

  return (
    <div
      key={suggestedThought.id + suggestedThought?.authorId}
      className={"suggested-item thought-pair-container" + (disabled ? " suggestion-disabled" : "")}
      // style={{ opacity: interactionAlreadyFromMe ? ".8" : "1" }}
      onClick={() => {
        navigate("/p/" + (placeId ?? "forum") + "/" + suggestedThought.id)
      }}
    >
      <div
        style={
          {
            // fontWeight: visited ? "normal" : "bold",
            // cursor: "pointer",
          }
        }
        className="caption"
      >
        <span style={{ fontSize: "70%", opacity: 0.7 }}>{visited ? "" : <>●&nbsp;&nbsp;</>}</span>
        {
          <>
            {edge.edgeKind === ConnectionKind.CONNECTION
              ? theName + " found"
              : edge.edgeKind === ConnectionKind.REPLY
              ? theName + " replied to your thought"
              : edge.edgeKind === ConnectionKind.RELATE
              ? theName + " connected your thought"
              : edge.edgeKind === ConnectionKind.SUGGESTION
              ? theName + "'s thought may relate to "
              : edge.edgeKind === ConnectionKind.GRANDPARENT
              ? theName + " replied"
              : "(what?)"}{" "}
          </>
        }{" "}
        <span style={{ opacity: 0.5 }}>{relativeTimeToShow}</span>
      </div>
      <div>
        {/* root thought */}
        {/* {!replied ? ( */}
        <div style={{ opacity: 0.7 }}>
          <Thought
            {...{
              post: rootThought,
              lineage: [],
              displayType: "update-source",
              parentThought: undefined,
            }}
          />
        </div>
        {edge.edgeKind === ConnectionKind.GRANDPARENT ? (
          <div style={{ color: "darkgray" }}>&emsp;&emsp;...</div>
        ) : (
          <></>
        )}

        <Thought
          {...{
            post: suggestedThought,
            lineage: [rootThought.id],
            displayType: "update-thought",
            parentThought: rootThought,
            providedShowReplyField: !isMobile() && !replied,
            providedAutoFocus: false,
          }}
        />
      </div>
      <br></br>
    </div>
  )
}

export default ThoughtPair

export const getTitleOrFirstLittleBit = (thought: TextPost) => {
  return abbreviate(thought.text, 240)
  // thought.title && !("isReply" in thought) ? thought.title :
}
export const getTitleForUpdate = (thought: TextPost) => {
  return thought.title && !("isReply" in thought) ? thought.title : abbreviate(thought.text, 20)
}
