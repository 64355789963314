import moment from "moment"

export const getShorthandTimeFromTimestamp = (secondsSinceEpoch: number) => {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ",
      s: "%ds",
      m: "%dm",
      mm: "%dm",
      h: "%dh",
      hh: "%dh",
      d: "%dd",
      dd: "%dd",
      M: "%dmo",
      MM: "%dmo",
      y: "%dy",
      yy: "%dy",
    },
  })
  return moment(secondsSinceEpoch).fromNow().trim()
}
