import { useContext, useEffect, useState } from "react"
import { createEditor, Editor, Node, Transforms } from "slate"
import { withHistory } from "slate-history"
import { withReact } from "slate-react"
import "./FirstThoughtRequest.css"
import "../Orientation/Orientation.css"
import PostSection from "../Feed/ThoughtsSection/MyThreads/PostSection/PostSection"
import { backendWriter } from "../App"
import getSuggestedThoughts, {
  SUGGESTIONS_SIMILARITY_THRESHOLD,
} from "../Feed/GetSuggestedThoughts"
import { useParams } from "react-router-dom"
import { getDatabase, onValue, ref } from "firebase/database"
import AuthContext from "../../ReactContexts/AuthContext"
import { ThoughtSubmission } from "../../Firebase/FirebaseWriter"

export const resetEditor = (editor: Editor) => {
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  })
}
const FirstThoughtRequest = ({ actuallyAttemptingToGainEntry }) => {
  const [editor, setEditor] = useState(() => withHistory(withReact(createEditor())))
  const { person } = useContext(AuthContext)
  const { placeId } = useParams()

  const handleChange = () => {
    // check if editor is empty
    const editorText = Node.string(editor)
    const editorIsEmpty = editorText.length === 0
    setEmpty(editorIsEmpty)
  }

  const [submissions, setSubmissions] = useState<{ [id: string]: ThoughtSubmission }>({})
  useEffect(() => {
    const db = getDatabase()
    if (!person || !("uid" in person)) return

    const personRef = ref(
      db,
      "p/" + (placeId ?? "forum") + "/people/" + person.uid + "/orientation/submissions"
    ) //nodes are posts

    //get person data on the front end
    onValue(personRef, (data) => {
      if (data.exists()) {
        setSubmissions(data.val())
      }
    })

    //grab firebase display name etc.
  }, [person])

  const [empty, setEmpty] = useState(true)
  const handleEnter = () => {
    //check if there are related enough thoughts
    if (!Node.string(editor)) return
    if (Node.string(editor).split(" ").length < 8) {
      window.alert("Try sharing a bit more (couple sentences)... ")
      return
    }
    const result = backendWriter.addPost(
      editor.children,
      Node.string(editor),
      "forum",
      undefined,
      []
    )
    const submissionCall = backendWriter.addThoughtSubmission(Node.string(editor))

    setTimeout(() => {
      let rawSuggested = getSuggestedThoughts([result.postWithId], "forum")
      rawSuggested.then((rawSuggested) => {
        const suggestions = rawSuggested.filter(
          (e) => e.score >= SUGGESTIONS_SIMILARITY_THRESHOLD - 0.04
        )
        const mostSimilar = rawSuggested.reduce((soFar, nextOne) => {
          return nextOne.score > soFar ? nextOne.score : soFar
        }, 0)
        backendWriter.updateThoughtSubmission(mostSimilar, submissionCall.key)

        //if there are any
        if (suggestions.length > 0) {
          //first thing
          window.alert(
            "Congratulations. Other people are discussing similar things in Plexus, including " +
              suggestions[0].suggestedThought.authorName +
              ': "' +
              suggestions[0].suggestedThought.text +
              '"'
          )

          result.addPostPromise.then(() => {
            backendWriter.findAndRecordSuggestionsForThought(result.postWithId, placeId)
          })
          backendWriter.recordOnboardingStart()
        } else {
          window.alert(
            "We don't think you'll have an great Plexus experience yet. (You're likely blazing new ground.) Try sharing something else that's on your mind (particularly about the future / ai / relationships), or we'll reach out again when there are people here for you."
          )
          backendWriter.deletePost(result.postWithId, "forum")
          // resetEditor(editor)
        }
      })
      //ask if there are related enough thoughts
    }, 2000)
  }

  return (
    <div className="first-thought-request-container">
      <br></br>

      <div className="first-thought-editor-container">
        <PostSection
          {...{
            editor,
            handleChange,
            showSubmit: !empty,
            appendPost: handleEnter,
            customPrompt: `Share something on your mind, so we can let you know whether you're a fit for the early Plexus community.`,
          }}
        />
      </div>

      <div className="decision-message">{""} </div>

      {/* {Object.keys(submissions ?? {})?.length > 0 ? (
        <div className="decision-message">
          <h3>submissions</h3>
          {Object.values(submissions ?? {})
            .filter((e) => e.text)
            ?.reverse()
            .map((sub) => (
              <>
                <div className="feed-item-container submission">{sub.text}</div>
                {sub.score ? (
                  <div style={{ fontSize: ".9em", marginTop: "-1em", color: "gray" }}>
                    {"Closest thought in Plexus is " + convFracToPercent(sub.score) + "% related. "}
                    {"Threshold is " + convFracToPercent(SUGGESTIONS_SIMILARITY_THRESHOLD) + "%."}
                  </div>
                ) : (
                  <></>
                )}
                <br></br>
              </>
            ))}{" "}
        </div>
      ) : (
        <></>
      )} */}
    </div>
  )
}

export default FirstThoughtRequest

const makeFartherFromWhole = (num) => 1 - (1 - num) * 2
const convFracToPercent = (num: number) => {
  return Math.round(100 * makeFartherFromWhole(num) * 10) / 10
}
