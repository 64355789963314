import { OpenAIApi, Configuration } from "openai"

function parseForOpenAi(text: string | string[]) {
  let textArr = (typeof text === "string" ? [text] : text).map((e) => e.replaceAll("\n", " "))
  // Max text length allowed by openAI is 2048 chars
  textArr = textArr
    .map((t) => t.slice(0, 2000).replace(/[^a-z1-9 .,!?]|\s\s+/gi, ""))
    // NOTE: openAI throws a fit if text is too short
    .filter((t) => t.length >= 2)
  return textArr
}

export function getOpenAiTextEmbedding(
  text: string | string[],
  personId?: string,
  davinci?: true
): Promise<any> {
  //openai
  const configuration = new Configuration({
    apiKey: "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",
  })
  const openai = new OpenAIApi(configuration)
  const response = openai.createEmbedding({
    model: davinci ? "text-similarity-davinci-001" : "text-similarity-ada-001",
    input: parseForOpenAi(text),
    user: personId,
  })
  return response
}
export function getOpenAiQueryEmbedding(text: string | string[], personId?: string): Promise<any> {
  //openai
  const configuration = new Configuration({
    apiKey: "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",
  })
  const openai = new OpenAIApi(configuration)
  const response = openai.createEmbedding({
    model: "text-search-ada-query-001",
    input: parseForOpenAi(text),
    user: personId,
  })
  return response
}
export function getOpenAiDocEmbedding(text: string | string[], personId?: string): Promise<any> {
  //openai
  const configuration = new Configuration({
    apiKey: "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",
  })
  const openai = new OpenAIApi(configuration)

  const response = openai.createEmbedding({
    model: "text-search-ada-doc-001",
    input: parseForOpenAi(text),
    user: personId,
  })
  return response
}

export const getOpenAISummaryForConversation = (text: string): Promise<any> => {
  const configuration = new Configuration({
    apiKey: "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",
  })
  const openai = new OpenAIApi(configuration)

  const parameters = {
    model: "text-davinci-003",
    prompt: text,
    max_tokens: 2000,
  }
  const response = openai.createCompletion(parameters)
  console.log(response)

  //clean it up
  return response
}

export const getAutomatedReply = (
  thoughtA: string,
  nameA: string,
  thoughtB: string,
  nameB: string
): Promise<any> => {
  const configuration = new Configuration({
    apiKey: "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",
  })
  const openai = new OpenAIApi(configuration)

  const prompt = replyPrompt(thoughtA, thoughtB, nameA, nameB)
  const parameters = {
    model: "text-davinci-003",
    prompt: prompt,
    // "Plexus is a mischevious, humble, highly articulate student wandering around the Internet.\n\nFirst, she reads a thought that Person B wrote.\nPerson B: \"In 100 years, how similar or different will the institution of marriage be? Will it still be state recognized? The core of the nuclear family?\"\n\nPlexus takes a moment to absorb the insight.  A minute later, she comes across Person A's blog, who she comes to regard as a genius.\n\nPerson A: \"I'm thinking that all institutions, including schools, marriages, families, will get disbanded in favor of less coarse, more precise ways for people to coalesce and disperse across time.\"\n\nUpon reflection, Plexus realizes there's an extraordinary connection with Person B's writing. \n\nPlexus takes Person A aside to let them know about the connection. Plexus first paraphrases Person B's writing, then describes the deep (and somewhat funny) overlap between Person A and Person B's thinking, then asks a precise question to get the conversation rolling.\n\nPlexus:  \"To build on your thought, Person A:",
    temperature: 0.63,
    max_tokens: 1000,
    // top_p: 1,
    // frequency_penalty: 0,
    // presence_penalty: 0,
  }
  const response = openai.createCompletion(parameters)
  console.log(response)

  //clean it up
  return response
}

const replyPrompt = (thoughtA: string, thoughtB: string, nameA: string, nameB: string) =>
  `Plexus is a mischevious, humble, highly articulate student wandering around the Internet.

First, she reads a thought that ${nameB} wrote.
${nameB}: "${thoughtB}"

Plexus takes a moment to absorb the thought.

A while later she comes across ${nameA}'s writing, whom she regards a genius.

${nameA}: "${thoughtA}"

Upon reflection, Plexus realizes there's a point of connection between what ${nameA} and ${nameB} are saying. 

Plexus takes ${nameA} aside to let them know about the connection. Plexus plans what she's going to say: first, paraphrase ${nameB}'s writing, then, point out the (maybe funny) way that ${nameA} and ${nameB}'s thoughts overlap, and then ask a precise question about the overlap in order to start the conversation:

Plexus:  "To build on your thought, ${nameA}:`
