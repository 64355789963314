import { PostButtonDefault } from "../../../AppBody.tsx/PostButton"
import EditorContainer from "../../../Editor/EditorContainer"
import Thought from "../../FeedItem/Thought"

const MyThreads = ({ finalPosts, editor, appendPost, handleChange, showSubmit }) => {
  return (
    <>
      {/* <div className="tab-title">My Thoughts</div> */}
      {showSubmit ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "120vw",
            height: "520vh",
            background: "white",
            zIndex: "2",
          }}
        ></div>
      ) : (
        <></>
      )}
      <div className={"post-section column panel"}>
        <EditorContainer
          {...{
            editor,
            handleEnter: appendPost,
            handleChange: handleChange,
            publishedAlready: false,
          }}
        />

        {true ? (
          <div
            className="row buttons-in-editor"
            style={{ visibility: showSubmit ? "visible" : "hidden" }}
          >
            <PostButtonDefault {...{ appendPost }} />
          </div>
        ) : (
          <></>
        )}
      </div>
      {finalPosts.length > 0 ? (
        finalPosts
          //only slice when viewing suggestion section, in which case we want to show one at a time.
          .map((e) => <Thought key={e.id + e?.authorId} post={e} displayType={"my-thought"} />)
      ) : (
        <div style={{ textAlign: "center", color: "gray" }}>
          This is where you'll see your thoughts.
          <br></br> <br></br>
          To start, write something you're wrestling with.
          <br></br> <br></br>
          Lots of thoughts so far about psychology, relationships, & AI.
        </div>
      )}
    </>
  )
}

export default MyThreads
