import { TextPost } from "../../../ReactContexts/PostContext"

// A list of excluded author Ids (specifically Davey, Micah and test accounts)
// these won't be counted in the matches
const excludedAuthorIds = ["wSFLu3kTxYbJqvvqrRsnLpKDgIt1", "ydJOnfKXWrexVUcAbQMX1XZgd5d2", "RZZI7Z3xniZvHEUAgEF3zn7Vlmm1", "Qr4DH7AobfUdAsWLswbANu7eoqZ2"]
// const excludedAuthorIds = []

interface edgeCountTracker {
    total: string[],
    totalWithData: {
        id: string,
        timestamp: string
    }[],
    inboundAccepted: string[],
    outboundAccepted: string[],
    inboundRejected: string[],
    outboundRejected: string[]
}

const countEdges = (thought: TextPost, excludeSpecialAuthorIds: string[] = excludedAuthorIds) => {
    let edgeCounts: edgeCountTracker = {
        total: [],
        totalWithData: [],
        inboundAccepted: [],
        outboundAccepted: [],
        inboundRejected: [],
        outboundRejected: []
    }
    // If there's no edge list, there are zero connections
    if (!(`edgeList` in thought)) {
        return edgeCounts
    }
    
    // Iterate through each thought's edges to count edge types
    for (const edge in thought.edgeList) {
        // Incrementing for inbound edges and anti-edges
        if (`inbound` in thought.edgeList[edge] && checkValidAuthor(thought.edgeList[edge], `inbound`, excludeSpecialAuthorIds)) {
            if (`anti` in thought.edgeList[edge].inbound) {
                edgeCounts.inboundRejected.push(edge)
            } else {
                edgeCounts.inboundAccepted.push(edge)
            }
        }

        // Incrementing for outbound edges and anti-edges
        if (`outbound` in thought.edgeList[edge] && checkValidAuthor(thought.edgeList[edge], `outbound`, excludeSpecialAuthorIds)) {
            if (`anti` in thought.edgeList[edge].outbound) {
                edgeCounts.outboundRejected.push(edge)
            } else {
                edgeCounts.outboundAccepted.push(edge)
            }
        }
    }
    // TODO: Replace instances of simple array of ids with array of objects (totalWithData) in all places
    edgeCounts.total = findTotalMatches(edgeCounts)
    
    // Assigns timestamp for when a match was made
    for (let k = 0; k < edgeCounts.total.length; k++) {
        let outboundTimestamp = thought.edgeList[edgeCounts.total[k]].outbound.timestamp
        let inboundTimestamp = thought.edgeList[edgeCounts.total[k]].inbound.timestamp
        let obj = {
            id: edgeCounts.total[k],
            timestamp: assignMatchMadeTimestamp(outboundTimestamp, inboundTimestamp)
        }
        edgeCounts.totalWithData.push(obj)
    }

    return edgeCounts
}

// Excludes match checking for authors on the black list
const checkValidAuthor = (edge, direction, excludeList: string[]) => {
    if (excludeList.includes(edge[direction]["authorId"])) {
        return false
    }
    return true
}

// Returns latest timestamp between two matched thoughts (when the match was made final)
const assignMatchMadeTimestamp = (timestamp1, timestamp2) => {
    return timestamp1 >= timestamp2 ? timestamp1 : timestamp2
}

// Finds intersection between inbound accepted edges and outbound accepted edges
const findTotalMatches = (edgeCounts: edgeCountTracker) => {
    const matchedThoughts = edgeCounts.inboundAccepted.filter(node => edgeCounts.outboundAccepted.includes(node));
    // console.log(matchedThoughts)
    return matchedThoughts
}


export default countEdges