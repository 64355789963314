import MyThreads from "./MyThreads/MyThreads"
import "./ThoughtsSection.css"

const ThoughtsSection = ({ editor, appendPost, handleChange, showSubmit, finalPosts }) => {
  return (
    <>
      <div className="app-section-header">Experiences</div>
      <MyThreads {...{ finalPosts, showSubmit, handleChange, appendPost, editor }} />
    </>
  )
}

export default ThoughtsSection
