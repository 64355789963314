import axios from "axios"

declare module "axios" {
  export interface AxiosRequestConfig {
    crossdomain: boolean
  }
}

const endpoint =
  "https://us-central1-community-3ffbb.cloudfunctions.net/getRedditPosts-getRedditPosts"

// Single Reddit author query, talks to firebase and organizes data
export const getReddit = (username: string = "", subreddit: string = "", query: string = "") => {
  return callRedditApi(username, subreddit, query).then((e: any[]) => {
    //add reddit content
    const posts = e.map((e) => ({
      timestamp: e.timestamp,
      text: e.text,
      authorName: e.authorName,
    }))
    return posts
  })
}

// Queries firebase with reddit parameters to talk to Pushshift
const callRedditApi = (
  author: string = "",
  subreddit: string = "",
  query: string = ""
): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${endpoint}/reddit/?author=${author}&submissions=${true}&comments=${true}&subreddit=${subreddit}&q=${query}`,
        {
          crossdomain: true,
        }
      )
      .then((response) => {
        let redditData = reduceToValidObject(response.data)
        resolve(redditData)
      })
  })
}

// Minimizes object size into smaller group of keys
const reduceToValidObject = (unfilteredThoughts: any[]) => {
  let reducedObject: any[] = []
  for (let thought of unfilteredThoughts) {
    thought["isAnnouncement"] = false
    thought["slateValue"] = getSlateValueFromText(thought["text"])
    reducedObject.push(thought)
  }
  return reducedObject
}

// //get slate value from text
const getSlateValueFromText = (text: string) => {
  const breaked = text.split("\n")
  return breaked.map((para: string) => ({ type: "paragraph", children: [{ text: para }] }))
}
