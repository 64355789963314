import { getDatabase, onValue, ref } from "firebase/database"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getFlatEdgeMapFull } from "../../Firebase/ReplyUtilities"
import AuthContext from "../../ReactContexts/AuthContext"
import { ConnectionKind, TextPost } from "../../ReactContexts/PostContext"
import Chat, { StupidMessage } from "./Chat/Chat"
import ChatPreview from "./Chat/ChatPreview/ChatPreview"
import "./PeopleSection.css"
import { backendWriter } from "../App"
export interface ChatShell {
  personId: string
  personName: string
}
export interface StupidMessages {
  [messageId: string]: StupidMessage
}
export interface StupidChat {
  messages: StupidMessages
  lastChecked: { [authorId: string]: number }
}
export type StupidChats = { [id: string]: StupidChat }
const PeopleSection = ({ myOwnPosts }) => {
  const { placeId } = useParams()
  const { person } = useContext(AuthContext)
  const [friends, setFriends] = useState<PersonData[]>([])

  useEffect(() => {
    async function fetchFriends() {
      const fetchedFriends = await getFriends(myOwnPosts)
      setFriends(fetchedFriends)
    }

    fetchFriends()
  }, [myOwnPosts])
  const [chat, setChat] = useState<ChatShell>()
  const [allChats, setAllChats] = useState<{ [id: string]: StupidChat }>()
  useEffect(() => {
    const db = getDatabase()

    const chatRef = ref(db, "p/" + (placeId ?? "forum") + "/chats/") //nodes are posts

    //get person data on the front end
    onValue(chatRef, (data) => {
      if (data.exists()) {
        const val = data.val()
        const justMine = filterMap(val, (id: string) => id.includes(person.uid))
        setAllChats(justMine)
      }
    })
  })
  const unread = Object.values(allChats ?? {}).filter(
    (chat) => chat.messages && chatIsUnread(chat, person.uid)
  )
  const numUnread = unread.length
  if (numUnread > 0) debugger
  if (chat) return <Chat {...{ chat, setChat }} />

  return (
    <div className="people-section-container">
      {/* {numUnread} */}
      <br></br>
      <div style={{ textAlign: "center" }}>{friends.length} people you've interacted with</div>
      {friends.map((friend) => (
        <ChatPreview {...{ e: friend, setChat, allChats }} />
      ))}
    </div>
  )
}

export default PeopleSection

interface PersonData {
  id: string
  name: string
  email: string
  connectionsToMe?: any[]
  connectionsFromMe?: any[]
  interactionCount?: number
}

//Get all people you've connected with
const getFriends = async (myOwnPosts: TextPost[]): Promise<PersonData[]> => {
  let friends: PersonData[] = []
  const friendIds = new Set()

  for (const post of myOwnPosts) {
    // Get all the incoming and outgoing connections
    const inbound = Object.values(getFlatEdgeMapFull(post.connections?.inbound ?? {}))
    const thoughtIds = inbound
      .filter((e) => e.edgeKind === ConnectionKind.REPLY)
      .map((edge) => edge.sourceId)

    const outbound = Object.values(getFlatEdgeMapFull(post.connections?.outbound ?? {}))
    const outThoughtIds = outbound.map((edge) => edge.targetThoughtId)

    const allThoughtIds = [...thoughtIds, ...outThoughtIds]

    // Fetch thoughts in parallel
    const thoughts = await Promise.all(allThoughtIds.map((id) => backendWriter.queryByIds(id)))

    // Iterate over thoughts and update friends list
    for (const [thought] of thoughts) {
      if (thought && thought.authorId !== post.authorId && !friendIds.has(thought.authorId)) {
        friendIds.add(thought.authorId)
        friends.push({
          id: thought.authorId,
          name: thought.authorName,
          email: thought.authorEmail,
          interactionCount: 1,
        })
      }
    }
  }
  const uniqueFriends = collapseAuthorData(friends, (a, b) => a.email === b.email)
  const sorted = uniqueFriends.sort((b, a) => a.interactionCount - b.interactionCount)

  return sorted
}

const collapseAuthorData = (arr: PersonData[], eqFn: (a, b) => boolean) => {
  let newArr = []
  arr.forEach((item) => {
    const sameItems: PersonData[] = newArr.filter((e) => eqFn(e, item))

    if (sameItems.length > 0) {
      //then add to the first time
      sameItems[0].interactionCount = (sameItems[0].interactionCount ?? 1) + 1
    } else newArr = [...newArr, item]
  })
  return newArr
}

export function chatIsUnread(chat: StupidChat, authorId: string): boolean {
  const latestMessage = getLatestMessage(chat)
  if (!latestMessage) return false
  const latestMessageTime = latestMessage.timestamp
  const lastCheckedTime =
    chat?.lastChecked && authorId in chat.lastChecked ? (chat.lastChecked[authorId] as number) : 0
  return lastCheckedTime < latestMessageTime
}

export function getLatestMessage(chat: StupidChat): StupidMessage {
  const sortedMessages = Object.values(chat?.messages ?? {}).sort(
    (a, b) => b.timestamp - a.timestamp
  )
  const latestMessage = sortedMessages.length > 0 ? sortedMessages[0] : undefined
  return latestMessage
}

const filterMap = (
  obj: { [id: string]: any },
  filterFn: (key: string) => boolean
): { [id: string]: any } => {
  const entries = Object.entries(obj)
  const filtered = entries.filter((e) => filterFn(e[0]))
  const mapAgain = filtered.reduce((soFar, nextOne) => {
    return { ...soFar, [nextOne[0]]: nextOne[1] }
  })
  return mapAgain
}
