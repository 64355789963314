import { SuggestedThoughtInfo } from "../../../../GetSuggestedThoughts"
import Thought from "../../../Thought"
import { numSuggestionsToShowAtFirst } from "../../ExpandedSection/ExpandedSection"
import "./SuggestedThoughtsTab.css"
import loader from "../../../../../../assets/plexus-final2.gif"

const suggMax = 10

/**
 * This component includes suggestions from the rest of the network, including connections from other people and from Plexus.
 *  This policy comes from the personal view conception of rabbit-holing (distinct from author-confirmed rabbit holing), where YOU have the ability to make connections with any thought you come across
 * @param param0
 * @returns
 */
const SuggestedThoughtsTab = ({
  suggestedThoughts,
  thought,
  lineage,
  suggestionsLoading,
  setNumAdditionalSuggestions,
  numAdditionalSuggestions,
  defaultVisible,
}: {
  suggestedThoughts: SuggestedThoughtInfo[]
  thought
  lineage
  suggestionsLoading
  setNumAdditionalSuggestions
  numAdditionalSuggestions
  defaultVisible: boolean
}) => {
  return (
    <div className="suggested-thoughts-tab-container">
      {/* If there are related thoughts, show them. Otherwise show empty state placeholder. */}
      {/* {filteredSuggested.length > 0 ? ( */}
      <>
        {defaultVisible ? (
          <>
            {[...suggestedThoughts]
              .slice(0, numAdditionalSuggestions)
              .map(({ suggestedThought }) => (
                //last two properties are deprecated I think
                <Thought
                  {...{
                    parentThought: thought,
                    post: suggestedThought,
                    lineage,
                    isSuggestion: true,
                    displayType: "suggested-thought",
                  }}
                />
              ))}

            <div style={{ color: "gray", margin: ".5em", textAlign: "center" }}>
              {suggestionsLoading || suggestedThoughts.length == 0 ? (
                <img src={loader} style={{ width: "60px" }} id="loader"></img>
              ) : (
                <div>
                  {numAdditionalSuggestions < suggMax &&
                  numAdditionalSuggestions < suggestedThoughts.length ? (
                    <div
                      style={{ color: "gray", textAlign: "center", cursor: "pointer" }}
                      onClick={() =>
                        setNumAdditionalSuggestions((x) => x + numSuggestionsToShowAtFirst)
                      }
                    >
                      see more...
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
      {/* ) : ( */}

      {/* )} */}
    </div>
  )
}

export default SuggestedThoughtsTab
