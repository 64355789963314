import React, { Dispatch, SetStateAction } from "react"
import { Descendant } from "slate"
import { ThoughtBreadcrumbsI } from "../Components/App"
import { FilterMode } from "../Components/Feed/ThoughtList"
import { DisplaySettings, PersonFirebaseBucket } from "../Firebase/FirebaseWriter"
export interface embeddingsMap {
  [thoughtId: string]: number[]
}

/**
 * Context for all high level info retrieved from firebase
 */
let PostContext = React.createContext<{
  posts: PostMap
  setPosts: Dispatch<SetStateAction<PostMap>>
  myNodes: PostMap
  setMyNodes: Dispatch<SetStateAction<PostMap>>
  dictionary: WordMap
  setDictionary: Dispatch<SetStateAction<WordMap>>
  personalFilter: boolean
  setPersonalFilter: Dispatch<SetStateAction<boolean>>
  relevanceFilter: boolean //will serve as a boolean; changing it to true will trigger a search for all the items most relevant to the given text
  setRelevanceFilter: Dispatch<SetStateAction<boolean>>
  loadingPosts: boolean
  setLoadingPosts: Dispatch<SetStateAction<boolean>>
  todaysPrompt?: string
  titleThoughtId?: string
  setTitleThoughtId?: Dispatch<SetStateAction<string>>
  filterMode?: FilterMode
  setFilterMode?: Dispatch<SetStateAction<FilterMode>>
  routeToConversation?: Function
  personBucket?: PersonFirebaseBucket
  setPersonBucket?: Dispatch<SetStateAction<PersonFirebaseBucket>>
  allBreadcrumbs?: ThoughtBreadcrumbsI
  setAllBreadcrumbs?: Dispatch<SetStateAction<ThoughtBreadcrumbsI>>
  displaySettings?: DisplaySettings
  setDisplaySettings?: Dispatch<SetStateAction<DisplaySettings>>
}>({
  posts: {},
  setPosts: () => {},
  myNodes: {},
  setMyNodes: () => {},
  dictionary: {},
  setDictionary: () => {},
  personalFilter: true,
  setPersonalFilter: () => {},
  relevanceFilter: false,
  setRelevanceFilter: () => {},
  loadingPosts: false,
  setLoadingPosts: () => false,
  todaysPrompt: undefined,
  allBreadcrumbs: [],
  setAllBreadcrumbs: () => false,
  displaySettings: { showThoughtTitles: false },
  setDisplaySettings: () => false,
})
export default PostContext

export interface WordMap {
  [strippedWord: string]: { [postId: string]: false }
}
export interface PostMap {
  [postId: string]: TextPost
}
export interface TextPostWithoutId {
  timestamp: number
  text: string
  id?: string
  //do a conversion process? annoying problem this one.
  authorId: string //currently, this is just what the person says they'd like to be called.
  //there's the possibility for problematic overlap. should either replace this attribute, or make a new attribute based on fb id
  //corrected person info, not yet implemented
  authorName?: string //what the person says they'd like to be called, otherwise, their display name
  authorEmail: string
  //
  slateValue?: Descendant[] // Only need slateValue and above

  //keep track of who's expanded this thought, and when.
  lastExpanded?: { [authorId: string]: number }

  //new edge list, previously implemented both other edge lists poorly
  connections?: BidirectionalEdgeMap
  personThoughtInteractions?: PersonThoughtInteractions

  //title for this thought
  title?: string
  isReply?: true //if true, it's a reply thought

  //lineage, if its a reply
  lineage?: AncestorThought[]

  //now there's just an anti property in EdgeInfo, so this is deprecated
  // antiEdgeList?: { [thoughtId: string]: EdgeInfo[] }

  //about to be deprecated (relate)
  //lists to represent mutual thought requests/relations between thoughts in the mutual thought scheme
  //for request edges
  edgeList?: { [thoughtId: string]: { outbound: EdgeInfo; inbound: EdgeInfo } }
  links?: { [postId: string]: EdgeInfo }
  antiLinks?: { [postId: string]: EdgeInfo }
  prompt?: string
  isAnnouncement?: boolean
  visitors?: { [id: string]: true } //people who've visited this thought
  discordImportId?: string
}
export interface AncestorThought {
  textPreview?: string // the first 30 chars if its a reply, the title if its a thread
  id: string //the id of the ancestor
  authorId: string
  authorName?: string
  authorEmail?: string
}

export interface TextPost extends TextPostWithoutId {
  id: string
}
export interface TextPostWithEmbeddings extends TextPost {
  openaiEmbedding?: number[] //1024 spots
  openaiQueryEmbedding?: number[] //1024 spots, used as the query
  openaiTextSimilarityEmbedding?: number[]
}

export interface EdgeInfoWithoutAuthor {
  sourceId: string
  // edgeLabel?: string //will be the query string, by default
  edgeKind: EdgeKind | ConnectionKind
  timestamp: number
  focusThoughtId?: string
  anti?: true
  weight?: number //a number between -1 and 1 that will represent the strength of a connection
  targetThoughtId?: string
  targetAuthorId?: string
  edgeAuthorId?: "plexus-default" | string // if not provided, authorId is assumed to be the author id
  //intended to be used with sliders on the CONNECT edge type.
}
export interface EdgeInfo extends EdgeInfoWithoutAuthor {
  authorId: string
}

export interface EdgeInfoWithConnectionData extends EdgeInfoWithoutAuthor {
  sourceId: string
  authorId: string
  targetThoughtId: string
  targetAuthorId: string
}

export interface EdgeInfoPlus extends EdgeInfo {
  ogThought: TextPost
}

export interface EdgeInfoWithId extends EdgeInfo {
  id: string //the edge's id (in firebase)
}

//The inbound / outbound distinction is stored redundantly: once at the key level (inbound/outbound), and once within each edge (thought source id = )
export interface BidirectionalEdgeMap {
  inbound: DirectionalEdgeMap
  outbound: DirectionalEdgeMap
}
//A couple important things to note about this edge list
//This interface allows multiple edges to be stored between the same two people
//this creates the possibility for:
//edges of multiple kinds, added by the same author
//edges added by different authors
//neither of these possibilities is strictly neceessary yet, but after last two edgeList / linkList ts interfaces became confusing, wanted to make sure this kind could last
export interface DirectionalEdgeMap {
  [otherThoughtId: string]: ConnectionMap
}

export interface ConnectionMap {
  [edgeId: string]: EdgeInfo
}
//only used for old edges... use ConnectionKind for each new edge types
//each item here is an int, need to keep ordered in this list
export enum EdgeKind {
  "LINK" = 0, //when you click relate
  "REPLY" = 1, //when you reply
  "ANNOUNCEMENT" = 2, //deprecated
  "REQUEST" = 3, //the new conversation link type. //int = 3
}

//new enum
export enum ConnectionKind {
  "REPLY" = "REPLY", //discussion replies, the direction of a reply is from child to parent! new change, to ensure it's same as direction of the notification
  "CONNECTION" = "CONNECTION", //the edge that forms between two thoughts when person approves a suggested thought
  //the intention here is for us to experiment with a few possible effects of CONNECT: saving the thought (personally), pinging the author of the suggested thought, contributing to the structure of the thought network
  "SUGGESTION" = "SUGGESTION", //a stored connection that Plexus makes with a given thought
  "RELATE" = "RELATE", // a new kind of like button, with context
  "GRANDPARENT" = "GRANDPARENT",
}

//New bucket in firebase for connection updates

export interface ConnectionUpdatesForAPerson {
  [connectionUpdateId: string]: SingleConnectionUpdateForAPerson
}

export interface SingleConnectionUpdateForAPerson extends EdgeInfo {
  //author id and source id are initiator metadata
  //edgeKind is connection kind
  targetAuthorId: string
  targetThoughtId: string
  migrations?: {
    feb9?: true //for connection direction
  }
}

//New spot within thought firebase bucket for it's been seen by a person

//New spot within person firebase bucket for when they've seen a thought
//Just an array/object of interactions, which can be filtered to find the right kind of interaction
export interface PersonThoughtInteractions {
  //thought has been opened
  //same structure fundamentally for thought and for people buckets, but two different names for keys here just for sake of clarity

  [interactionId: string]: SinglePersonThoughtInteraction
}

export interface SinglePersonThoughtInteraction {
  timestamp: number
  type: PersonThoughtInteractionType
  personId: string
  thoughtId: string
}

export enum PersonThoughtInteractionType {
  REPLIES_EXPANDED = "REPLIES_EXPANDED", //person expanded this thought either to see its replies or to reply to it
  SUGGESTIONS_EXPANDED = "SUGGESTIONS_EXPANDED", //person expanded this thought either to see its replies or to reply to it
}
