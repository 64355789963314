import { Bell, Gear, Intersect, Lightbulb, UsersThree } from "phosphor-react"
import { useContext } from "react"
import PostContext from "../../../ReactContexts/PostContext"
import { isMobile } from "../../Editor/EditorContainer"
import { FilterMode } from "../ThoughtList"
import "./ThoughtsFilterToggle.css"

const ThoughtsFilterToggle = ({ setFilterMode, filterMode, unseenSuggestions, updatesUnseen }) => {
  const { setTitleThoughtId } = useContext(PostContext)
  return (
    <div className="toggles" onClick={() => setTitleThoughtId(undefined)}>
      <div className="header-row" style={{ fontStyle: "normal", textAlign: "center" }}>
        <div
          onClick={() => {
            setFilterMode(FilterMode.SENT)
          }}
          className={filterMode === FilterMode.SENT ? " selected" : ""}
        >
          {true || isMobile() ? <Lightbulb size={22} /> : "thoughts"}
          {/*  /> */}
        </div>
        <div
          onClick={() => {
            setFilterMode(FilterMode.SUGGESTIONS)
          }}
          className={"option" + (filterMode === FilterMode.SUGGESTIONS ? " selected" : "")}
          //never bolded for now
          style={{ fontWeight: unseenSuggestions?.length > 0 ? "bold" : "normal" }}
        >
          {true || isMobile() ? (
            <Intersect size={22} />
          ) : (
            `suggestions ${unseenSuggestions?.length > 0 ? unseenSuggestions.length : ""}`
          )}
        </div>
        {/* for recorded suggestions (not computed suggs) */}
        <div
          onClick={() => {
            setFilterMode(FilterMode.UPDATES)
          }}
          className={filterMode === FilterMode.UPDATES ? " selected" : ""}
          style={{ fontWeight: updatesUnseen.length > 0 ? "bold" : "normal" }}
        >
          {true || isMobile() ? <Bell size={22} /> : "updates"}
          {/* comment out for now */}
          <span>{updatesUnseen.length > 0 ? " " + updatesUnseen.length : ""}</span>
        </div>
        {/* <div
          onClick={() => {
            setFilterMode(FilterMode.CHAT)
          }}
          className={filterMode === FilterMode.CHAT ? " selected" : ""}
        >
          {isMobile() ? <UsersThree size={24} /> : "people"}
        </div> */}
        <div
          onClick={() => {
            setFilterMode(FilterMode.SETTINGS)
          }}
          className={filterMode === FilterMode.SETTINGS ? " selected" : ""}
        >
          {/* settings &nbsp; */}
          {true || isMobile() ? <Gear size={22} /> : <Gear size={18} />}
          {/* comment out for now */}
        </div>
      </div>
    </div>
  )
}

export default ThoughtsFilterToggle
