import { ArrowLeft, House } from "phosphor-react"
import { useContext, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { getReplyThoughtsFromParent, thoughtIsReply } from "../../Firebase/ReplyUtilities"
import { getPathToCurrentThoughtFromEdges } from "../../Logic/BreadcrumbsLogic"
import PostContext, { TextPost } from "../../ReactContexts/PostContext"
import { backendWriter } from "../App"
import ExpandedSection from "../Feed/FeedItem/ExpandedRelatedThoughts/ExpandedSection/ExpandedSection"
import Thought, { TabViewStatusOptions } from "../Feed/FeedItem/Thought"
import "./Detail.css"
import { abbreviate } from "../../Logic/Utilities"
import FirebaseWriter from "../../Firebase/FirebaseWriter"
import { update } from "firebase/database"

function topFunction() {
  const ele = document.getElementsByClassName("big-panel")[0]
  if (ele) {
    ele.scrollTop = 0 // For Safari
  }
}

const Detail = ({ providedPost, postId }: { providedPost: TextPost; postId: string }) => {
  const { posts } = useContext(PostContext)
  const { placeId } = useParams()
  const [path, setPath] = useState([])
  const [replies, setReplies] = useState([])
  const navigate = useNavigate()
  //Get parent thought id, if it exists
  const [providedReplyEmpty, providedSetReplyEmpty] = useState(true)
  const [defaultFooter, setDefaultFooter] = useState<TabViewStatusOptions>(
    replies.length > 0 || thoughtIsReply(providedPost) ? "replies" : "related-threads"
  )

  useEffect(() => {
    //programmatically set scroll to top
    topFunction()
  }, [])

  useEffect(() => {
    // synchronous effects
    backendWriter.visitPost(providedPost?.id)
    window.document.title = "Plexus - " + abbreviate(providedPost.text, 120)
    // backendWriter.findAndRecordSuggestionsForThought(providedPost, placeId)

    //test the new firebase function
    setTimeout(() => suggBatchFn(providedPost, placeId), 100)

    // async effects
    async function fetchData() {
      const fetchedReplies = await getReplyThoughtsFromParent(providedPost)
      setReplies(fetchedReplies)
      setDefaultFooter(
        fetchedReplies.length > 0 || thoughtIsReply(providedPost) ? "replies" : "related-threads"
      )

      const pathResult = await getPathToCurrentThoughtFromEdges(providedPost)
      setPath(pathResult)
    }
    fetchData()
  }, [providedPost.id, placeId])

  // useEffect(() => {
  //   setDefaultFooter(
  //     replies.length > 0 || thoughtIsReply(providedPost) ? "replies" : "related-threads"
  //   )
  // }, [replies.length])

  return (
    <div className="column">
      <div
        className="row"
        style={{
          alignItems: "center",
          margin: ".5em 0 0 .5em",
          fontSize: "1.6em",
        }}
      >
        <div
          title="see your past thoughts / add a new one"
          style={{
            textAlign: "center",
            padding: ".5em",
            textDecoration: "none",
            fontSize: ".6em",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size={20} />
        </div>
        <Link
          title="see your past thoughts / add a new one"
          style={{
            textAlign: "center",
            padding: ".5em",
            textDecoration: "none",
            fontSize: ".6em",
          }}
          to={"/p/" + (placeId ?? "")}
        >
          <House size={20} />
        </Link>
      </div>

      <div className="detail-view column">
        {providedPost && providedPost.slateValue ? (
          <div className="column">
            <div>
              {path.map(({ sourceThought }) => (
                <Thought {...{ post: sourceThought, lineage: [], displayType: "breadcrumb" }} />
              ))}
            </div>
            {/* <Breadcrumbs /> */}
            <Thought
              post={providedPost}
              displayType="principal-thought"
              providedTabViewStatus={defaultFooter}
              providedReplyEmpty={providedReplyEmpty}
              providedSetReplyEmpty={providedSetReplyEmpty}
              providedShowReplyField={true}
            />
            <ExpandedSection
              {...{
                thought: providedPost,
                lineage: [],
                setTabViewStatus: setDefaultFooter,
                tabViewStatus: defaultFooter,
                parentType: "principal-thought",
              }}
            />
          </div>
        ) : (
          <span>{posts && posts[postId] ? "loading..." : "doesn't exist"}</span>
        )}
      </div>
    </div>
  )
}

export default Detail

///testing the new sugg batch function

export const suggBatchFn = (thought: TextPost, placeId: string) => {
  const suggPromise = backendWriter.findAllSuggsForThought(thought, placeId)
  suggPromise.then((suggData) => {
    const edges = FirebaseWriter.convertSuggArrToEdgeArr(thought, suggData)

    //in nodes section
    const updates = backendWriter.makeNodesUpdateObjectFromEdgeArr(edges)
    const peopleUpdates = backendWriter.makePeopleUpdateObjectFromEdgeArr(edges)

    const write = backendWriter.writeSuggUpdates(thought, updates, peopleUpdates)
    write?.catch((e) => {
      console.log("error", e)
    })
  })
}
