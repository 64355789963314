import { useMemo, useState } from "react"
import {
  backendAddOpenAiText,
  backendGetFirebaseDataFromPath,
} from "../../../../Firebase/FirebaseFunctionPointers"
import { MetaphorScraped } from "../../../../Types/types"

const OpenAiBI = () => {
  const [items, setItems] = useState<MetaphorScraped>()
  useMemo(() => {
    backendGetFirebaseDataFromPath("invites/suggestions").then((result) => {
      setItems(result)
    })
  }, [])

  const getOpenai = async () => {
    // if (typeof(url) === "object") url.splice(10)
    await backendAddOpenAiText().catch((e) => {
      console.error(e)
    })
  }

  return (
    <div className="dataProcessingContainer">
      <div className="dataProcessingAreaInviter">
        <h2> OpenAi Text Generator </h2>
        <div className="processingButtonContainer">
          <button
            onClick={() => {
              getOpenai()
            }}
          >
            {" "}
            Add OpenAi Text{" "}
          </button>
        </div>
        <ol>
          {items
            ? Object.values(items).map((item, i) => {
                return (
                  <div className="scrapedDataWrapper" key={`sugg-${i}`}>
                    <ul>
                      <li>
                        {item?.base_url}, <a href={item?.url}> Link </a>
                      </li>
                      <li>{item.email_address[0]} </li>
                      <li>
                        <b> Thought: </b>
                        {item?.plexus_query}
                      </li>
                      <li>
                        <b> Plexus: </b>
                        {item?.plexus_suggestions?.text}
                      </li>
                    </ul>
                  </div>
                )
              })
            : ""}
        </ol>
      </div>
    </div>
  )
}

export default OpenAiBI
