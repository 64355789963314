import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ExampleThoughts from "./ExampleThoughts"
import "./Orientation.css"
import "../App.css"
import { backendWriter, localStorageInviteCodeKey } from "../App"
import PostContext from "../../ReactContexts/PostContext"
import { ArrowCircleRight } from "phosphor-react"
import FirstThoughtRequest from "./FirstThoughtRequest"
import { alreadyHereLogic, inviteCodes, stringContainsAnyOf } from "../LandingPage/LandingPage"

const slides = (
  firstName: string
): {
  text: string
  style?: "italic"
  delay?: number
  examples?: true
  exNumber?: number
}[] => [
  { text: "Welcome" + (firstName ? ", " + firstName : "") + "." },
  {
    text: "Plexus is a new kind of network.",
  },
  {
    text: "It's a place to record your strangest, funniest, or most serious experiences...",
  },
  {
    text: "...and connect with people through them.",
  },
  { text: "Some experiences from the community:", examples: true },
  {
    text: "What experiences are on your mind?",
    delay: 2000,
  },
  { text: "When you're ready, here's the door.", delay: 1000 },
]

const Orientation = () => {
  const { placeId } = useParams()
  const { personBucket } = useContext(PostContext)
  const [i, setI] = useState(0)
  const [loading, setLoading] = useState(false)
  const displayName =
    personBucket && "personName" in personBucket ? personBucket.personName : undefined

  const theSlides = slides(displayName)
  const hasInvite = stringContainsAnyOf(
    localStorage.getItem(localStorageInviteCodeKey),
    inviteCodes
  )
  const alreadyHere = alreadyHereLogic(personBucket)
  const nextButtonEnabled = i + 1 < theSlides.length
  const navigate = useNavigate()
  const doubleClickAction = (e) => {
    if (!nextButtonEnabled || window.confirm("skip orientation?")) {
      navigate("/p/" + (placeId ?? ""))
      localStorage.setItem("oriented-already-1", "true")
      backendWriter?.markPersonAsOnboarded()
    }
  }
  //for Raya
  useEffect(() => {
    if (placeId && placeId !== "forum") setI(theSlides.length - 1)
  }, [placeId])
  //go through hoops if not invite code
  if (!alreadyHere && !hasInvite && (!placeId || placeId === "forum"))
    return <FirstThoughtRequest actuallyAttemptingToGainEntry={true} />
  else
    return (
      <div
        className={"orientation-container" + (loading ? " loading" : "")}
        onClick={(e) => {
          setLoading(true)
          //if first click
          if (i === 0) {
            backendWriter.recordOnboardingStart()
          }

          setTimeout(() => {
            setLoading(false)
            if (nextButtonEnabled && !loading) {
              setI((prev) => prev + 1)
            } else {
              doubleClickAction(e)
            }
          }, theSlides[i]?.delay ?? 600) //aligned with the css
        }}
        onDoubleClick={doubleClickAction}
      >
        <div className="slide-text">{theSlides[i]?.text}</div>

        <div style={{}}>
          {theSlides[i]?.examples ? (
            [0, 1, 2].map((example, i) => <ExampleThoughts i={i} />)
          ) : (
            <></>
          )}
        </div>
        <br></br>

        <div className="nav-buttons">
          <div className={"next-button"}>
            {i + 1 < theSlides.length ? (
              <ArrowCircleRight size={32} />
            ) : (
              <span style={{ fontSize: "2em", paddingTop: "50px" }}>🚪</span>
            )}
          </div>
        </div>
      </div>
    )
}

export default Orientation
