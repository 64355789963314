import { useContext, useEffect, useState } from "react"
import { PersonFirebaseBucket } from "../../../../Firebase/FirebaseWriter"
import { thoughtIsReply } from "../../../../Firebase/ReplyUtilities"
import { personHasReplied } from "../../../../Logic/ReplyLogic"
import AuthContext from "../../../../ReactContexts/AuthContext"
import PostContext, {
  ConnectionKind,
  PostMap,
  SingleConnectionUpdateForAPerson,
} from "../../../../ReactContexts/PostContext"
import { backendWriter } from "../../../App"
import { isMobile } from "../../../Editor/EditorContainer"
import Thought from "../../FeedItem/Thought"
import { SuggestedThoughtInfo } from "../../GetSuggestedThoughts"
import "./SuggestedThreads.css"
import { TextPost } from "../../../../Types/types"
import { getRecordedSuggestionsPerThought } from "../../../../Logic/SuggestionsPerThoughtLogic"

const SuggestedThreads = () => {
  const { person } = useContext(AuthContext)
  const { personBucket, posts } = useContext(PostContext)
  const [suggestions, setSuggestions] = useState<SuggestedThoughtInfo[]>([])
  const [repliedStatuses, setRepliedStatuses] = useState<Map<string, boolean>>(new Map())
  const [fetchedPosts, setFetchedPosts] = useState<Map<string, TextPost>>(new Map())
  const [numToShow, setNumToShow] = useState(50)

  async function fetchPostsByIds(ids: string[]): Promise<Map<string, TextPost>> {
    const postsArray: TextPost[] = await backendWriter.queryByIds(ids)
    const postsMap: Map<string, TextPost> = new Map<string, TextPost>()
    postsArray.forEach((post) => {
      postsMap.set(post.id, post)
    })
    return postsMap
  }

  useEffect(() => {
    async function fetchRepliedStatuses() {
      const newRepliedStatuses = new Map<string, boolean>()
      // Loop through all the suggested thoughts.
      for (const { suggestedThought } of suggestions) {
        // check if current person has replied to the suggested thought
        if (!suggestedThought) {
          continue
        }
        const replied = await personHasReplied(person.uid, suggestedThought)
        // Set the replied status for the current suggested thought in the map
        newRepliedStatuses.set(suggestedThought.id, replied)
      }
      // If there are suggestions, assign the replied statuses.
      setRepliedStatuses(newRepliedStatuses)
    }

    if (suggestions.length > 0) {
      fetchRepliedStatuses()
    }
  }, [suggestions, person])

  //record the last time person has viewed suggestions
  useEffect(() => {
    backendWriter.recordSuggestionsPeak()
  }, [person])

  useEffect(() => {
    async function fetchAndSetSuggestions() {
      const connections = await getSuggestionsFromPersonBucket(person.uid, personBucket)
      // const connections = await getRecordedSuggestionsPerThought(posts)

      const allPostIds = connections.reduce((ids, { rootThought, suggestedThought }) => {
        const rootThoughtId = rootThought ? rootThought.id : []
        const suggestedThoughtId = suggestedThought ? suggestedThought.id : []
        return [...ids, rootThoughtId, suggestedThoughtId]
      }, [])
      const uniqueIds = Array.from(new Set(allPostIds)).filter((id) => id) // This line filters out any undefined or empty values
      const postsMap = await fetchPostsByIds(uniqueIds)
      setFetchedPosts(postsMap)

      setSuggestions(connections)
    }

    fetchAndSetSuggestions()
  }, [personBucket, person])

  return (
    <div className="suggested-threads-container">
      <div className="app-section-header">Suggestions</div>

      {/* This filter removes duplicate root thought suggestions based on id */}
      {suggestions.length <= 1 ? (
        <div className="centered-gray-text">
          This is where you'll see suggested thoughts: new thoughts from the community that relate
          to your thoughts.
        </div>
      ) : (
        <></>
      )}
      {suggestions.slice(0, numToShow).map(({ rootThought, suggestedThought }) => {
        //determine whether there is a reply
        const replied = repliedStatuses.get(suggestedThought?.id)
        // const related =
        return (
          <>
            {rootThought ? (
              <Thought
                key={"root" + rootThought?.id + suggestedThought?.id}
                post={rootThought}
                displayType={"update-source"}
              />
            ) : (
              <></>
            )}
            <Thought
              key={rootThought?.id + suggestedThought?.id}
              post={suggestedThought}
              displayType={"suggested-thought"}
              parentThought={rootThought}
              providedShowReplyField={!isMobile() && !replied}
              providedAutoFocus={false}
            />
            {/* determine whether the thought is read */}
            <div className="whether-has-replied">
              {(() => {
                //determine whether there is a reply between
                return replied ? "replied!" : ""
              })()}
            </div>
            <br></br>
          </>
        )
      })}
      {numToShow < suggestions.length ? (
        <span
          className="centered-gray-text"
          onClick={() => {
            setNumToShow((x) => x + 20)
          }}
        >
          show more...
        </span>
      ) : (
        <span></span>
      )}
    </div>
  )
}

export default SuggestedThreads

const filterSuggestionsByFetchedPosts = (suggestions, fetchedPosts) => {
  return suggestions.filter((e) => {
    const sourceExists = fetchedPosts.has(e.sourceId)
    const targetExists = fetchedPosts.has(e.targetThoughtId)
    const targetTextExists = targetExists && fetchedPosts.get(e.targetThoughtId).text
    const sourceTextExists = sourceExists && fetchedPosts.get(e.sourceId).text

    return sourceExists && targetExists && targetTextExists && sourceTextExists
  })
}

const filterSuggestionsByRootThought = (suggestions, fetchedPosts, dontFilter?: true) => {
  return suggestions.filter((e) => {
    const isReply = thoughtIsReply(fetchedPosts.get(e.sourceId))
    return !isReply
    // !isReply
  })
}

const formatSuggestions = (suggestions, fetchedPosts, isInbound?: true) => {
  return suggestions.map((edge) => {
    const rt = fetchedPosts.get(edge.sourceId)
    const trg = fetchedPosts.get(edge.targetThoughtId)
    const rootThought = !isInbound ? rt : trg
    const suggestedThought = !isInbound ? trg : rt
    return {
      rootThought,
      suggestedThought,
      score: 0,
      timestamp: edge.timestamp,
    }
  })
}

export async function getSuggestionsFromPersonBucket(
  personId: string,
  personBucket: PersonFirebaseBucket
): Promise<SuggestedThoughtInfo[]> {
  const connections = personBucket?.connections
  const inbound = connections?.inbound
  const outbound = connections?.outbound

  const outboundSuggestions = Object.values(outbound ?? {}).filter(
    (edge) => edge.edgeKind === ConnectionKind.SUGGESTION
  )
  const inboundSuggestions = Object.values(inbound ?? {})
    .filter((e) => e.targetThoughtId !== personId)
    .filter((edge) => edge.edgeKind === ConnectionKind.SUGGESTION)

  const postIds = Array.from(
    new Set(
      outboundSuggestions
        .concat(inboundSuggestions)
        .map((edge) => [edge.sourceId, edge.targetThoughtId])
        .flat()
    )
  )

  const fetchedPostsArray = await backendWriter.queryByIds(postIds)
  const fetchedPosts = new Map(fetchedPostsArray.map((post) => [post.id, post]))

  const filteredOutboundSuggestions = filterSuggestionsByFetchedPosts(
    outboundSuggestions,
    fetchedPosts
  )
  const rootOutboundSuggestions = filterSuggestionsByRootThought(
    filteredOutboundSuggestions,
    fetchedPosts
  )
  const formattedOutboundSuggestions = formatSuggestions(rootOutboundSuggestions, fetchedPosts)

  const filteredInboundSuggestions = filterSuggestionsByFetchedPosts(
    inboundSuggestions,
    fetchedPosts
  )
  const rootInboundSuggestions = filterSuggestionsByRootThought(
    filteredInboundSuggestions,
    fetchedPosts
  )
  const formattedInboundSuggestions = formatSuggestions(rootInboundSuggestions, fetchedPosts, true)

  const defaultFirstSugg: SuggestedThoughtInfo = {
    rootThought: undefined,
    suggestedThought: fetchedPosts.get("-NU-mLJzKlF7FKs5Gbrr"),
    score: 0,
    timestamp: personBucket?.orientation?.enteredDoor ?? 0,
  }

  const result = [...formattedOutboundSuggestions, ...formattedInboundSuggestions].sort(
    (a, b) => b.timestamp - a.timestamp
  )
  const unique = result.reduce((allSoFar: SuggestedThoughtInfo[], nextOne) => {
    if (
      allSoFar
        .map((e) => e?.suggestedThought?.id)
        .filter((e) => e)
        .includes(nextOne.suggestedThought.id)
    ) {
      return allSoFar
    } else return [...allSoFar, nextOne]
  }, [])

  return [...unique, defaultFirstSugg]
}
