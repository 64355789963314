import { useEffect, useState } from "react"
import { backendScrapeWebsite } from "../../../../Firebase/FirebaseFunctionPointers"
import { AuthorContent } from "../../../../Types/types"
import CollectedItem from "./CollectedItem"
import EmailsFound from "./EmailsFound"
import { parseParagraphs } from "./textAnalyzer"

const Metaphor = ({ handleSelection }: { handleSelection: Function }) => {
  const [urlText, setUrlText] = useState<string>("")
  const [metaphorContents, setMetaphorContents] = useState<string>("")
  const [displayedContents, setDisplayedContents] = useState<AuthorContent[]>([])

  useEffect(() => {
    const paragraphs = parseParagraphs(metaphorContents).filter((paragraph) => {
      return paragraph.length > 75
    })
    const contents = paragraphs.map((paragraph, i) => {
      return {
        selected: false,
        text: paragraph,
        authorName: new URL(urlText).hostname,
        timestamp: Date.now(),
        id: i,
      }
    })
    setDisplayedContents(contents)
  }, [metaphorContents])

  const handleUrlChange = (e) => {
    setUrlText(e.target.value)
  }

  const callScraper = async (url: string) => {
    await backendScrapeWebsite(url)
      .then((result) => {
        result = cleanScrapedData(result)
        setMetaphorContents(result)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const cleanScrapedData = (text: string) => {
    return removeWhiteSpace(text)
  }

  // Ensures fewer than n% of the characters in a string are special
  const containsSpecialCharacters = (str: string) => {
    var regex = /[ #$%^&*()_+\-=\[\]{}.;':"\\|<>\/?\t]/g
    var count = (str.match(regex) || []).length
    return count / str.length < 0.9
  }

  // Trimming down white space
  const removeWhiteSpace = (text: string) => {
    // Separating by new line
    let a = text.split("\n")
    // Removing anything above special character threshold
    let b = a.filter((chunk) => {
      return containsSpecialCharacters(chunk) && chunk.trim().length > 5
    })
    console.log(b)
    let c = b.join("\n\n")
    return c
  }

  return (
    <div>
      <div className="getterContainer">
        <input
          className="getterInputField"
          placeholder="Url"
          value={urlText}
          onChange={handleUrlChange}
        ></input>
        <div className="buttonContainer">
          <span
            className="getterButton"
            onClick={() => {
              callScraper(urlText)
            }}
          >
            {" "}
            Load{" "}
          </span>
        </div>
      </div>
      <div className="fetchedContainer">
        <div>
          <EmailsFound text={metaphorContents} />
          {displayedContents.map((content) => {
            return (
              <div>
                <CollectedItem
                  content={content}
                  selectionHandler={handleSelection}
                  state={displayedContents}
                  setState={setDisplayedContents}
                />{" "}
              </div>
            )
          })}
          <div> Found {displayedContents.length} potential thoughts. </div>
        </div>
      </div>
    </div>
  )
}

export default Metaphor
