import { SuggestedThoughtInfo } from "../Components/Feed/GetSuggestedThoughts"
import { getFullEdgeMapArr } from "../Firebase/ReplyUtilities"
import {
  ConnectionKind,
  EdgeInfoWithConnectionData,
  PostMap,
  TextPost,
} from "../ReactContexts/PostContext"

/**
 * gets outbound suggs
 * @param thought
 * @param allThoughts
 * @returns
 */
export const getRecordedSuggestionsPerThought = (
  thought: TextPost,
  allThoughts: PostMap
): SuggestedThoughtInfo[] => {
  const suggestionEdges = getFullEdgeMapArr(
    { ...thought.connections?.outbound } ?? {},
    ConnectionKind.SUGGESTION
  )
  const defined = suggestionEdges.filter((e) => e.targetThoughtId in allThoughts)
  const unique = defined.reduce((allSoFar, nextOne) => {
    if (
      allSoFar
        .map((e: EdgeInfoWithConnectionData) => e.targetThoughtId)
        .includes(nextOne.targetThoughtId)
    )
      return allSoFar
    else return [...allSoFar, nextOne]
  }, [])

  const sorted = unique.sort((a: EdgeInfoWithConnectionData, b: EdgeInfoWithConnectionData) => {
    return (b.weight ?? 0) - (a.weight ?? 0)
  })

  const suggestions: SuggestedThoughtInfo[] = sorted
    .map((edge) => {
      return {
        //switch
        rootThought: thought,
        suggestedThought: allThoughts[edge.targetThoughtId],
        timestamp: edge.timestamp,
        score: edge.weight ?? 0,
      }
    })
    .filter((e) => e.suggestedThought.text)

  return suggestions
}
