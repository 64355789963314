import { getDatabase, onValue, ref } from "firebase/database"
import { ArrowLeft, ArrowUp } from "phosphor-react"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { createEditor, Node } from "slate"
import { withHistory } from "slate-history"
import { withReact } from "slate-react"
import AuthContext from "../../../ReactContexts/AuthContext"
import { backendWriter } from "../../App"
import EditorContainer from "../../Editor/EditorContainer"
import { resetEditor } from "../../Orientation/FirstThoughtRequest"
import { usernamex } from "../../PrivateRoute/PrivateRoute"
import { ChatShell, StupidChat, StupidMessages } from "../PeopleSection"
import "./Chat.css"
export interface StupidMessage {
  text: string
  timestamp: number
  authorName: string
  authorId: string
}

export const generateChatId = (id1: string, id2: string) => {
  let a = [id1, id2].sort()
  return a[0] + "chattingWith" + a[1]
}

const Chat = ({ chat, setChat }: { chat: ChatShell; setChat }) => {
  const { placeId } = useParams()
  const { person } = useContext(AuthContext)
  const [editor, setEditor] = useState(() => withHistory(withReact(createEditor())))
  const [empty, setEmpty] = useState(true)
  const [wholeChat, setWholeChat] = useState<{ messages: StupidMessages }>()
  const [wholeMessages, setWholeMessages] = useState<StupidMessage[]>()

  useEffect(() => {
    const db = getDatabase()

    const chatRef = ref(
      db,
      "p/" + (placeId ?? "forum") + "/chats/" + generateChatId(person.uid, chat.personId)
    ) //nodes are posts

    //get person data on the front end
    onValue(chatRef, (data) => {
      if (data.exists()) {
        const theChat: StupidChat = data.val()
        setWholeChat(theChat)
        setWholeMessages(Object.values(theChat?.messages ?? {}))
      }
    })
  })
  useEffect(() => {
    backendWriter.recordChatCheck(chat.personId)
  }, [wholeMessages])
  const messages = Object.values(wholeChat?.messages ?? {})
  return (
    <div className="chat-container">
      <div>
        <h3 className="chat-header">
          <ArrowLeft
            style={{ margin: "0 1em", cursor: "pointer" }}
            onClick={() => setChat(undefined)}
          />
          {chat.personName}
          <div style={{ width: "64px" }}></div>
        </h3>
        <hr></hr>
      </div>
      <div>
        {messages
          .filter((m) => m.text)
          .map((e) => (
            <div className={"message " + (e.authorId === person.uid ? " mine" : " theirs")}>
              <span className="message-inner">{e.text}</span>
            </div>
          ))}
        <div className="chat-editor-container">
          <EditorContainer
            {...{
              enterToSubmit: true,
              editor,
              handleChange: () => {
                if (Node.string(editor).length > 0) setEmpty(false)
                else setEmpty(true)
              },
              handleEnter: () => {
                const newMessage = Node.string(editor)
                if (!newMessage) return

                backendWriter.addMessage(
                  {
                    text: newMessage,
                    timestamp: Date.now(),
                    authorId: person.uid,
                    authorName: usernamex,
                  },
                  chat.personId
                )
                resetEditor(editor)
              },
              showSubmit: empty,
            }}
          />
          <div style={{ display: "flex" }}>
            <ArrowUp />
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chat
