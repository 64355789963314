import { useContext } from "react"
import AuthContext from "../../../../ReactContexts/AuthContext"
import { getLatestMessage, StupidChats } from "../../PeopleSection"
import "./ChatPreview.css"
import { generateChatId } from "../Chat"

const ChatPreview = ({ setChat, e, allChats }: { setChat; e; allChats: StupidChats }) => {
  const { person } = useContext(AuthContext)
  const chatId = generateChatId(e.id, person.uid)
  const thisChat = chatId in (allChats ?? {}) ? allChats[chatId] : undefined
  const latestMessage = thisChat ? getLatestMessage(thisChat) : undefined
  const chatLastChecked =
    thisChat?.lastChecked && person?.uid in thisChat.lastChecked
      ? thisChat.lastChecked[person?.uid]
      : 0
  const unread = latestMessage?.timestamp > chatLastChecked
  return (
    <div
      className={"chat-preview-container " + (unread ? " unread" : "")}
      //   onClick={() => setChat({ personId: e.id, personName: e.name })}
    >
      {" "}
      <div className={"person-preview-header"}>{e.name}</div>
    </div>
  )
}

export default ChatPreview

export const emailAuthor = (name: string, email: string, postText?: string) => {
  if (window.confirm("Send an email to " + email + "?"))
    window.location.href =
      "mailto:" +
      email +
      "?subject=interactions in plexus&body=" +
      `Your thought is awesome, ${name} ${
        postText ? '%0D%0A%0D%0A "' + postText : '"'
      }. %0D%0A%0D%0A I'm wondering...\n\n`
}
