import { useContext, useEffect, useState } from "react"
import { getAutomatedReply } from "../../../../../Firebase/OpenAi"
import { getReplyThoughtsFromParent } from "../../../../../Firebase/ReplyUtilities"
import AuthContext from "../../../../../ReactContexts/AuthContext"
import { TextPost } from "../../../../../ReactContexts/PostContext"
import { backendWriter } from "../../../../App"
import { SuggestedThoughtInfo } from "../../../GetSuggestedThoughts"
import Thought from "../../Thought"
import "./RepliesTab.css"

const RepliesTab = ({
  parentThought,
  includeTitle,
  suggestions,
}: {
  parentThought: TextPost
  includeTitle?: true
  suggestions?: SuggestedThoughtInfo[]
}) => {
  const { person } = useContext(AuthContext)

  const [replies, setReplies] = useState([])

  useEffect(() => {
    async function fetchReplies() {
      const fetchedReplies = await getReplyThoughtsFromParent(parentThought)
      setReplies(fetchedReplies)
    }

    fetchReplies()
  }, [parentThought])
  //TODO: actually write an ordering function, reverse just happens to work
  const [vis, setVis] = useState(true)
  const [autoReply, setAutoReply] = useState<string>()
  const [loadingAutoReply, setLoadingAutoReply] = useState(false)
  return (
    <div className="replies-tab-container">
      {replies.length ? (
        <>
          {includeTitle ? (
            <div
              className="mega-column-section-label"
              onClick={() => {
                setVis((x) => !x)
              }}
            >
              replies [{vis ? "-" : "+"}]
            </div>
          ) : (
            <></>
          )}

          {vis ? (
            <>
              {replies.map((reply) => (
                <Thought
                  {...{
                    parentThought,
                    post: reply,
                    lineage: [parentThought.id],
                    displayType: "connected-thought",
                  }}
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default RepliesTab
