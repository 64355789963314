import { getDatabase, onValue, ref } from "firebase/database"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { EdgeInfo, EdgeKind, PostMap, TextPost } from "../../ReactContexts/PostContext"
import { backendWriter } from "../App"
import { EdgeMap } from "../Feed/FeedItem/Thought"

export const getReplies = (post: TextPost) => {
  return Object.entries(post?.links ?? {}).filter(
    (e) => e[1]?.edgeKind === EdgeKind.REPLY && e[1].sourceId === post.id
  )
}
export const getPrompts = (post: TextPost) => {
  return Object.entries(post?.links ?? {}).filter(
    (e: [string, EdgeInfo]) => e[1]?.edgeKind === EdgeKind.REPLY && e[1].sourceId !== post.id
  )
}

export const getLinks = (post: TextPost) => {
  return Object.entries(post?.links ?? {}).filter((e) => e[1]?.edgeKind === EdgeKind.LINK)
}
export interface personCounts {
  thoughtCount: number
  outConnectionCount: number
  inConnectionCount: number
}

const HackyAdmin = () => {
  const { placeId } = useParams()
  const [allThoughts, setAllThoughts] = useState([])
  const [allThoughtsMap, setAllThoughtsMap] = useState<PostMap>({})
  const [loading, setLoading] = useState(true)
  const [exists, setExists] = useState(false)

  useEffect(() => {
    if (!placeId) return
    const db = getDatabase()
    const postsRef = ref(db, "p/" + placeId + "/nodes") //nodes are posts
    onValue(postsRef, (snapshot) => {
      if (snapshot.exists()) {
        setExists(true)
        const value = snapshot.val()
        setAllThoughtsMap(value)
        setAllThoughts(Object.values(value).reverse())
      }
      setLoading(false)
    })
  }, [placeId])

  const originals = useMemo(() => {
    return allThoughts.filter((e: TextPost) => getPrompts(e).length === 0)
  }, [allThoughts])
  const originalsWithoutReplies = useMemo(() => {
    return originals.filter((e: TextPost) => getReplies(e).length === 0)
  }, [originals])

  const contributors = allThoughts.reduce(
    (
      contributorsSoFar: {
        [id: string]: personCounts
      },
      nextThought: TextPost
    ) => {
      const email = nextThought.authorEmail
      if (!email) return contributorsSoFar
      const thoughtCount = email in contributorsSoFar ? contributorsSoFar[email].thoughtCount : 0
      const outConnectionCount =
        email in contributorsSoFar ? contributorsSoFar[email].outConnectionCount : 0
      const additionalOutConnections =
        authorsLinkKeys(nextThought?.links, nextThought.authorId) ?? []

      const inConnectionCount =
        email in contributorsSoFar ? contributorsSoFar[email].inConnectionCount : 0
      const additionalInConnections = notMyKeys(nextThought?.links, nextThought.authorId) ?? []
      return {
        ...contributorsSoFar,
        [email]: {
          thoughtCount: thoughtCount + 1,
          outConnectionCount: outConnectionCount + (additionalOutConnections?.length ?? 0),
          inConnectionCount: inConnectionCount + (additionalInConnections?.length ?? 0),
        },
      }
    },
    {}
  )
  const contributorEntriesSorted = (Object.entries(contributors) as [string, personCounts][]).sort(
    (a: [string, personCounts], b: [string, personCounts]) => {
      return b[1].thoughtCount - a[1].thoughtCount
    }
  )
  const NumDaysBack = 7
  const getLastWeekDate = (numDaysBack: number = NumDaysBack) => {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth() - 3, now.getDate())
  }

  const thoughtsThisWeek = allThoughts.filter((e) => e.timestamp > getLastWeekDate().getTime())
  const contributingEmailsThisWeek = Object.keys(
    thoughtsThisWeek.reduce((authorsSoFar, nextThought) => {
      return { ...authorsSoFar, [nextThought.authorEmail]: true }
    }, {})
  )
  const thoughtsBeforeThisWeek = allThoughts.filter(
    (e) => e.timestamp <= getLastWeekDate(5).getTime()
  )
  const contributingEmailsBeforeThisWeek = thoughtsBeforeThisWeek.reduce(
    (authorsSoFar, nextThought) => {
      return { ...authorsSoFar, [nextThought.authorEmail]: true }
    },
    {}
  )

  //Getting emails of authors who contributed for the first time this week
  const firstTimeContributorsThisWeek = contributingEmailsThisWeek.filter((email) => {
    return !(email in contributingEmailsBeforeThisWeek)
  })

  const totalWords = allThoughts.reduce(
    (totalWords, nextThought) => totalWords + (nextThought?.text ?? "").split(" ").length,
    0
  )

  const matches = allThoughts.reduce((matchesSoFar, nextThought: TextPost) => {
    const newEdges = []
    Object.entries(nextThought.edgeList ?? {}).forEach(([otherThoughtId, edgeInfo]: any[]) => {
      if ("inbound" in edgeInfo && "outbound" in edgeInfo && otherThoughtId in allThoughtsMap) {
        if (!("anti" in edgeInfo.inbound) && !("anti" in edgeInfo.outbound))
          newEdges.push({ thought1: nextThought, thought2: allThoughtsMap[otherThoughtId] })
      }
    })
    return [...matchesSoFar, ...newEdges]
  }, [])

  const uniqueMatches = matches.reduce((matchesSoFar, next) => {
    const nextThoughtIds = [next.thought1.id, next.thought2.id]
    //if already there, just return
    if (
      matchesSoFar.filter((match) => {
        return (
          nextThoughtIds.includes(match.thought1.id) && nextThoughtIds.includes(match.thought2.id)
        )
      }).length > 0
    ) {
      return [...matchesSoFar]
    } else return [...matchesSoFar, next]
  }, [])

  const ourEmails = [
    "davey@plexusnotes.com",
    "davey@plexus.earth",
    "micah@plexus.earth",
    "mcorningmyers@gmail.com",
  ]
  const matchesWoDaveyMicah = uniqueMatches.filter((e) => {
    return (
      !ourEmails.includes(e.thought1.authorEmail) && !ourEmails.includes(e.thought2.authorEmail)
    )
  })

  //finding people's thoughts that didn't find outbound connections,
  const thoughtsFromPast14Days: TextPost[] = allThoughts.filter(
    (e) => e.timestamp > getLastWeekDate(10).getTime()
  )

  //get all the thoughts without outbound connections
  const thoughtsWithoutOutbound = thoughtsFromPast14Days.filter((thought: TextPost) => {
    //get all outbound connections
    const outbound = Object.entries(thought.edgeList ?? {}).filter((entry) => {
      const doubleEdgeInfo = entry[1]
      return "outbound" in doubleEdgeInfo
    })
    return outbound.length === 0
  })

  //first thought from each author
  const uniqueAuthorThoughtsWithoutOutbound = thoughtsWithoutOutbound.reduce(
    (firstThoughtsSoFar, nextThought) => {
      if (firstThoughtsSoFar.filter((e) => e.authorId === nextThought.authorId).length > 0)
        return firstThoughtsSoFar
      else return [...firstThoughtsSoFar, nextThought]
    },
    []
  )

  const firstTimeWithoutOutbound = uniqueAuthorThoughtsWithoutOutbound.filter((e) =>
    firstTimeContributorsThisWeek.includes(e.authorEmail)
  )

  const contributorEmailsWithoutOutbound = firstTimeWithoutOutbound.map((e) => e?.authorEmail)

  const [showContrThisWeek, setShowContrThisWeek] = useState(false)

  return (
    <div style={{ padding: "1em" }}>
      <div>admin panel for p/{placeId}</div>
      {loading && !exists ? (
        <span style={{ color: "gray" }}> "loading... (or may not exist)"</span>
      ) : (
        <div>
          <div>
            {"established: " +
              moment(
                Math.min(...allThoughts.map((e) => e.timestamp ?? 0).filter((E) => E))
              ).fromNow()}
          </div>
          <div>
            <div>
              {"thoughts: " + allThoughts.length} (~{(totalWords / allThoughts.length).toFixed(0)}{" "}
              words/thought)
            </div>
            unique matches: {uniqueMatches.length}, {matchesWoDaveyMicah.length} matches w/o Davey
            or Micah
            <br></br>
            {thoughtsThisWeek.length} thoughts this week. <br></br>
            <br></br> <br></br>
            <div onClick={() => setShowContrThisWeek((x) => !x)} style={{ cursor: "pointer" }}>
              {" "}
              {contributingEmailsThisWeek.length} authors in the past {NumDaysBack} days:
            </div>
            {showContrThisWeek ? (
              contributingEmailsThisWeek.map((contr, i) => (
                <div style={{ display: "block" }} key={i}>
                  {contr}
                </div>
              ))
            ) : (
              <></>
            )}
            <br></br>
            <br></br>
            new contributors this week: {firstTimeContributorsThisWeek.length}
            <br></br>
            {firstTimeContributorsThisWeek.map((contr, i) => (
              <div style={{ display: "block" }} key={i}>
                {contr}
              </div>
            ))}
            <br></br>
            <br></br>
            people who've contributed without outbound connections (past two weeks):{" "}
            {contributorEmailsWithoutOutbound.length}
            <br></br>
            {contributorEmailsWithoutOutbound.map((contr, i) => (
              <div style={{ display: "block" }} key={i}>
                {contr}
              </div>
            ))}
            <br></br>
            Original thoughts w/o replies: {originalsWithoutReplies.length}
            {originalsWithoutReplies.map((e: TextPost, i) => (
              <div style={{ margin: "2em 0em", maxWidth: "50em" }} key={i}>
                <div style={{ fontSize: "1em" }}>"{e?.text}"</div>
                <div>written by {e.authorName}</div>
                <div>created {moment(e.timestamp).fromNow()}</div>
                <div>{getReplies(e).length + " replies"}</div>
                <div>{getPrompts(e).length + " prompts"}</div>
                <div>{getLinks(e).length + " connections"}</div>
                <div>root thought #{allThoughts.length - i}</div>
                <a href={"https://plexus.earth/p/" + placeId + "/" + e.id}>link</a>
              </div>
            ))}
            <br></br>
            <br></br>
            <br></br>
            <div>
              {Object.keys(contributors).length + " contributors:"}
              {contributorEntriesSorted.map(([e, count]: any) => (
                <div key={e}>
                  <span style={{ margin: "0 1em" }}>{e}</span>
                  <div style={{ margin: "0 2em" }}>
                    {String(count.thoughtCount)} thoughts, {String(count.outConnectionCount)} sent,{" "}
                    {String(count.inConnectionCount)} received
                  </div>
                </div>
              ))}
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          Original thoughts: {originals.length}
          {originals.map((e: TextPost, i) => (
            <div style={{ margin: "2em 0em", maxWidth: "50em" }} key={e.id}>
              <div style={{ fontSize: "1em" }}>"{e?.text}"</div>
              <div>written by {e.authorName}</div>
              <div>created {moment(e.timestamp).fromNow()}</div>
              <div>{getReplies(e).length + " replies"}</div>
              <div>{getPrompts(e).length + " prompts"}</div>
              <div>{getLinks(e).length + " connections"}</div>
              <div>root thought #{allThoughts.length - i}</div>
              <a href={"https://plexus.earth/p/" + placeId + "/" + e.id}>link</a>
            </div>
          ))}
          Replies
          {originals.map((e: TextPost, i) => (
            <div style={{ margin: "2em 0em", maxWidth: "50em" }} key={i}>
              <div style={{ fontSize: "1em" }}>"{e?.text}"</div>
              <div>written by {e.authorName}</div>
              <div>created {moment(e.timestamp).fromNow()}</div>
              <div>{getReplies(e).length + " replies"}</div>
              <a href={"https://plexus.earth/p/" + placeId + "/" + e.id}>link</a>
            </div>
          ))}
          <div>
            Secondary metrics:
            <div>{"total words: " + totalWords}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default HackyAdmin

const authorsLinkKeys = (edges: EdgeMap, personId: string = backendWriter?.personId) => {
  if (!edges) return []
  return Object.entries(edges)
    .filter(([key, val]: [string, EdgeInfo]) => {
      return val.authorId === personId
    })
    .map((e) => e[0])
}
const notMyKeys = (edges: EdgeMap, personId: string = backendWriter?.personId) => {
  if (!edges) return []
  return Object.values(edges)
    .filter((val: EdgeInfo) => {
      return val.authorId !== personId
    })
    .map((e) => e[0])
}
