import { getDatabase, onValue, ref } from "firebase/database"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { PostMap } from "../../../../ReactContexts/PostContext"
import AdminComputer from "./AdminComputer"
import "./WalkAdmin.css"

let computer: AdminComputer
const WalkAdmin = () => {
  //retrieve data
  const { placeId } = useParams()
  const [allThoughts, setAllThoughts] = useState([])
  const [allThoughtsMap, setAllThoughtsMap] = useState<PostMap>({})
  const [loading, setLoading] = useState(true)
  const [exists, setExists] = useState(false) //whether snapshot exists
  const domainId = placeId ?? "forum"

  useEffect(() => {
    if (!domainId) return
    const db = getDatabase()
    const postsRef = ref(db, "p/" + domainId + "/nodes") //nodes are posts
    onValue(
      postsRef,
      (snapshot) => {
        setLoading(false)
        if (snapshot.exists()) {
          setExists(true)
          const value = snapshot.val()
          setAllThoughtsMap(value)
          setAllThoughts(Object.values(value).reverse())
        }
      },
      (e) => console.warn(e)
    )
  }, [placeId])

  const numThoughts = AdminComputer.getTotalThoughts(allThoughtsMap ?? {}, {})
  const recentTen = AdminComputer.mostRecentTenThoughts(allThoughtsMap ?? {})

  useEffect(() => {})
  return (
    <>
      <strong>WALK DASHBOARD</strong>
      <br></br>
      <br></br>

      {loading ? (
        <div>loading p/{domainId}...</div>
      ) : (
        <div className="walk-admin-container">
          <strong title="People who have contributed a thought to the network this week">
            Contributors This Week: ?
          </strong>
          <br></br>
          <br></br>

          {/* <strong>This Week (sat midnight thru sat midnight)</strong>
          <div>Thought Contributors: ?</div>
          <div>Walk Contributors: ?</div>
          <div>Thought Contributors This Week: ?</div>
          <div>Step Contributors: ?</div>
          <div>Opened-Plexus Contributors: ?</div>
          <div>4d/wk contributors: ?</div>
          <div>Walks ?</div>
          <div>Thoughts ?</div>
          <div>Steps ?</div>
          <br></br>
          <strong>All Time</strong>
          <div>Contributors: ?</div>
          <div>Walks: ?</div>
          <div>Thoughts: {numThoughts}</div>
          <div>Steps: ?</div> */}

          {/* <div>
            Most recent ten thoughts:{" "}
            {recentTen.map((e) => (
              <div style={{ margin: "8pt" }}>
                {e.text} {moment(e.timestamp).fromNow()}
              </div>
            ))}
          </div> */}
        </div>
      )}
    </>
  )
}

export default WalkAdmin
