// Shortcut function for finding emails
const findEmail = (text: string): string[] => {
  return text.match(/\b([^\s]+@[^\s]+)\b/g)
}

const EmailsFound = ({ text }: { text: string }) => {
  const foundEmails: string[] | null = findEmail(text)

  return (
    <div>
      <div>
        {" "}
        <span> {`Found ${foundEmails ? foundEmails.length : 0} email(s):`}</span>
        {foundEmails
          ? foundEmails.map((email, i) => {
              return <b key={"emailf-" + i}> {email} </b>
            })
          : ""}
      </div>
    </div>
  )
}

export default EmailsFound
