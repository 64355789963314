import { backendGetThoughtSuggestionsByVector } from "../../../../Firebase/FirebaseFunctionPointers"
import { getOpenAiTextEmbedding } from "../../../../Firebase/OpenAi"

export const parseParagraphs = (text: string): string[] => {
  if (!text) return []
  // Removes empty paragraphs
  return text.split("\n").filter((p) => {
    return p
  })
}

export const parseSentences = (text: string): string[] => {
  if (!text) return []
  return text.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|")
}

export const getOpenAiEmbeddings = (text: string[], setState?) => {
  let embeddingPromises: any = []
  for (let i = 0; i < text.length; i++) {
    const textEmbedding = getOpenAiTextEmbedding(text[i])
    //takes a second, but that's okay I think
    embeddingPromises.push(textEmbedding)
  }
  let suggestionPromises: any = []
  Promise.all(embeddingPromises)
    .then(async (values) => {
      //@ts-ignore
      const vectors = values.map((value) => {
        return value.data.data ? value.data.data[0].embedding : undefined
      })

      for (let i = 0; i < vectors.length; i++) {
        const suggestionPromise = await backendGetThoughtSuggestionsByVector(
          vectors[i],
          50,
          "forum"
        )
        suggestionPromises.push(suggestionPromise)
      }
      Promise.all(suggestionPromises).then((values) => {
        values = values.map((value, i) => {
          return {
            originalText: text[i],
            suggestions: value.suggestions,
          }
        })
        setState(values)
      })
    })
    .catch((e) => console.warn(e))
}

// Ensures fewer than n% of the characters in a string are special
export const containsSpecialCharacters = (str: string) => {
  var regex = /[ #$%^&*()_+\-=\[]{}.;':"\\|<>\/?\t]/g
  var count = (str.match(regex) || []).length
  return count / str.length < 0.9
}

// Trimming down white space
export const removeWhiteSpace = (text: string) => {
  // Separating by new line
  let a = text.split("\n")
  // Removing anything above special character threshold
  let b = a.filter((chunk) => {
    return containsSpecialCharacters(chunk) && chunk.trim().length > 5
  })
  let c = b.join("\n\n")
  return c
}
