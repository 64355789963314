import { windowUpdate } from "../../Types/types"
import { backendWriter } from "../App"

/*
Example updateAndSurvey object
    {
      window: "prompt",
      title: "enjoyingPlexus",
      text: "Have you been enjoying Plexus?",
      timestamp: 1680902285550,
    }
*/

export const Survey = (person, alerts) => {
  // Use Date.now() in a node terminal to get current timestamp

  const updatesAndSurvey: windowUpdate[] = [
    {
      window: "prompt",
      title: "howDisappointedMay26REAL",
      text: "We're hoping to see how well Plexus is serving you.\n\nHow disappointed would you be if you couldn't use Plexus again? \n \n1 - Not so disappointed\n2 - Somewhat disappointed. \n3 - Very disappointed.",
      timestamp: 1685135480000,
    },
    {
      window: "alert",
      title: "phoneNumbersAddedREAL",
      text: "Now you can text Plexus your thoughts! \n\nJust open your Settings to see how. \n\n And let us know what you think—\n\n Davey & Micah <3     (Fri May 26th)",
      timestamp: 1685135480000,
    },
    // {
    //   window: "prompt",
    //   title: "veryDisappointedMay26",
    //   text: "How disappointed?",
    //   timestamp: 1685135480000,
    // },
  ]

  const sendWindowMessage = (alertItem: windowUpdate) => {
    if (alertItem.window === "prompt") {
      let response = alertItem
      response.response = window.prompt(alertItem.text)
      if (response.response) backendWriter.recordWindowResponse(response)
    }
    if (alertItem.window === "alert") {
      window.alert(alertItem.text)
      backendWriter.recordWindowResponse(alertItem)
    }
  }
  for (let i = 0; i < updatesAndSurvey.length; i++) {
    // Checks that person's account was made before question and that their last login was after the question was published
    if (!(person.metadata.createdAt < updatesAndSurvey[i].timestamp)) {
      continue
    }
    // If alert object does not exist and person's account was made before question timestamp, show them alert
    if (!alerts) {
      sendWindowMessage(updatesAndSurvey[i])
      // If alerts does exist but the name of the alert does not exist, send the alert
    } else if (alerts !== null && !(updatesAndSurvey[i].title in alerts)) {
      sendWindowMessage(updatesAndSurvey[i])
    }
    // Preventing alert spam
    setTimeout(() => {}, 5000)
  }
}
