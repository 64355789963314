import { useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Editor, Node } from "slate"
import AuthContext from "../../ReactContexts/AuthContext"
import PostContext, {
  ConnectionKind,
  SingleConnectionUpdateForAPerson,
  TextPost,
} from "../../ReactContexts/PostContext"
import "./Feed.css"
import { backendWriter } from "../App"
import { getPrompts } from "../HackyAdmin/HackyAdmin"
import { getDatabase, onValue, ref } from "firebase/database"
import { thoughtIsReply } from "../../Firebase/ReplyUtilities"
import UpdatesSection from "./UpdatesSection/UpdatesSection"
import ThoughtsFilterToggle from "./ThoughtsFilterToggle.tsx/ThoughtsFilterToggle"
import ThoughtsSection from "./ThoughtsSection/ThoughtsSection"
import Detail from "../Detail/Detail"
import SuggestedThreads from "./ThoughtsSection/SuggestedThreads/SuggestedThreads"
import { getUnseenSuggestions, getUnseenUpdates } from "../../UpdatesLogic"
import PeopleSection from "../PeopleSection/PeopleSection"
import Settings from "../AppBody.tsx/Settings/Settings"
import { timestamp } from "../../Logic/Utilities"

//when query changes, flip to global / personal if there are none in personal
//no need for this yet
export enum FilterMode {
  SENT,
  INBOX,
  ALL,
  CHAT,
  LIKES,
  SUGGESTIONS,
  UPDATES,
  SETTINGS,
}

/**
 * The main component in the app: a list of all the thoughts being displayed, including a tab-navigation system at the top (thoughts, sugg, matches)
 * @param appendPost function to "express" (add) a new post. used when person presses "express"
 * @param editor slate editor object, passed from above so that parent component has access
 * @param setEditorEmpty
 * @param editorEmpty whether or not there are any characters typed in the editor, or whether its blank.
 *  used to be used to determine whether to "hide" all the thoughtlist. this hiding no longer happens.
 * @returns
 */
const ThoughtList = ({
  appendPost,
  editor,
}: {
  appendPost: (any: any) => void
  editor: Editor
}) => {
  const { posts, titleThoughtId, filterMode, setFilterMode } = useContext(PostContext)
  const { person } = useContext(AuthContext)
  const { personBucket } = useContext(PostContext)
  const { placeId } = useParams()

  //array of all the posts in entire network. too much? :)
  const postArr: TextPost[] = useMemo(() => {
    const arr = Object.values(posts)
      .filter((e) => typeof e.text === "string" && e.timestamp)
      .sort((b, a) => {
        return timestamp(a) - timestamp(b)
      })

    return arr
  }, [posts])

  //whether to show the submit button (Currently labeled "express")
  const [showSubmit, setShowSubmit] = useState<boolean>(false)

  // the function to run every time the the editor is touched
  // just test whether the editor is empty every time
  const handleChange = () => {
    // check if editor is empty
    const editorText = Node.string(editor)
    const editorIsEmpty = editorText.length === 0
    setShowSubmit(!editorIsEmpty)
  }

  //get all the posts with a timestamp (apparently some posts used to not have a timestamp?)
  // I guess it's good to only get the ones with a timestamp
  const filteredPosts = useMemo(() => {
    const arrToUse: TextPost[] = postArr.filter((e) => e.timestamp)
    return arrToUse
  }, [postArr])

  // get all the posts that I wrote (that have my authorId / my email)
  // we should be able to change this back to authorId comparison, instead of email comparison
  // we did this email comparison when we were adding thoughts directly from the admin panel (w/o access to authorIds)
  // but now that thoughts' authorIds get rewritten on initialization, we probably don't need this logic anymore
  const myOwnPosts = useMemo(() => {
    //janky old way
    return filteredPosts.filter(
      (post) => backendWriter?.personId && backendWriter.personEmail === post.authorEmail
    )
  }, [filteredPosts])

  // all posts not from me in the whole network, not sure why this is needed
  const everyoneElsesPosts = useMemo(() => {
    return filteredPosts.filter((post) => {
      return post.authorId !== backendWriter?.personId
    })
  }, [filteredPosts])

  //This is the person's top-level thoughts, excluding replies. Purpose: for the getSuggestions fn.
  const myOwnRootThoughts = useMemo(() => {
    return myOwnPosts.filter((e: TextPost) => {
      const itsPrompts = getPrompts(e)
      //it's an og thought if it doesn't have any prompts
      const isOldRoot = !itsPrompts?.length || itsPrompts.length === 0
      const isNewRoot = !thoughtIsReply(e)
      return isOldRoot && isNewRoot
    })
  }, [myOwnPosts])

  // this is a janky, only sometimes-used arr. stores the final "array" to be displayed (but only used in the Sent/inbox cases, wacky)
  //worth noting that .SENT === 'thought' and .INBOX === 'suggestions'. other filtermodes self explanatory
  const postsToUse =
    filterMode === FilterMode.SENT
      ? myOwnRootThoughts
      : filterMode === FilterMode.INBOX
      ? everyoneElsesPosts
      : filterMode === FilterMode.ALL
      ? filteredPosts
      : []

  //calculate the final posts to show... this is kinda jank. this logic should be separated into separate components, one per tab, eventually
  const finalPosts: TextPost[] = (
    [FilterMode.SENT, FilterMode.ALL].includes(filterMode)
      ? postsToUse
      : [FilterMode.INBOX].includes(filterMode)
      ? []
      : []
  ).filter((e) => e.slateValue)

  const updatesUnseen = useMemo(() => {
    // takes into account suggestions and replies
    const unseen = getUnseenUpdates(person.uid, personBucket, posts)
    return unseen
  }, [posts, personBucket])

  //get unseen suggested thoughts
  const [unseenSuggestions, setUnseenSuggestions] = useState<any[]>([])
  useEffect(() => {
    const unseenSuggestionsPromise = getUnseenSuggestions(person?.uid, personBucket)
    unseenSuggestionsPromise.then((e) => {
      setUnseenSuggestions(e)
    })
  }, [person.uid, personBucket])

  //reply update logic
  const [replyConnections, setReplyConnections] = useState<SingleConnectionUpdateForAPerson[]>([])

  const [repliesRaw, setRepliesRaw] = useState<SingleConnectionUpdateForAPerson[]>()
  //get replies from firebase
  useEffect(() => {
    const db = getDatabase()
    const domainId = placeId ?? "forum"
    const incomingConnectionsRef = ref(
      db,
      "p/" + domainId + "/people/" + person.uid + "/connections/inbound" //inbound!! not incoming
    ) //nodes are posts
    onValue(incomingConnectionsRef, (data) => {
      if (data.exists()) {
        //get replies
        const connectionsUpdateMap: { [connectionId: string]: SingleConnectionUpdateForAPerson } =
          data.val()

        const connectionsArray: SingleConnectionUpdateForAPerson[] =
          Object.values(connectionsUpdateMap)

        setRepliesRaw(connectionsArray)
      }
    })
  }, [])
  //filter replies Raw
  useEffect(() => {
    if (!repliesRaw) return
    const repliesArray: SingleConnectionUpdateForAPerson[] = repliesRaw.filter(
      (connection: SingleConnectionUpdateForAPerson) =>
        connection?.edgeKind === ConnectionKind.REPLY
    )

    const repliesFromOthers: SingleConnectionUpdateForAPerson[] = repliesArray.filter(
      (reply: SingleConnectionUpdateForAPerson) => reply.authorId !== person.uid
    )
    const defined: SingleConnectionUpdateForAPerson[] = repliesFromOthers.filter(
      //not checking for source cuz may not be there yet
      (connection: SingleConnectionUpdateForAPerson) =>
        connection.targetThoughtId in posts && connection.sourceId in posts
    )

    const ordered = defined.reverse()
    setReplyConnections(ordered)
  }, [repliesRaw, posts])

  useEffect(() => {
    if (posts[titleThoughtId]?.text) document.title = posts[titleThoughtId]?.text + " (Plexus)"
    else document.title = "Plexus"
  }, [titleThoughtId])

  return (
    <div className={"search-feed"}>
      {/* set title thought id to undefined when click on the toggle */}

      <ThoughtsFilterToggle
        {...{
          setFilterMode: setFilterMode,
          filterMode: filterMode,
          unseenSuggestions: unseenSuggestions,
          updatesUnseen: updatesUnseen,
        }}
      />

      {/* What follows is a series of conditionally rendered "sub-components" (they should really be full compnoents, if the code were better organized) */}
      {/* Only one of the following sub components will render, depending on which tab is selected (thoughts/suggestions/matches) */}

      {/* my thoughts section */}
      {/* if there's a title thought, show it, otherwise, show the rest*/}

      {titleThoughtId ? (
        <Detail providedPost={posts[titleThoughtId]} postId={titleThoughtId} key={titleThoughtId} />
      ) : (
        <div style={{ flex: 1 }}>
          {FilterMode.SETTINGS === filterMode ? <Settings /> : <></>}
          {FilterMode.SENT === filterMode ? (
            <ThoughtsSection
              {...{
                finalPosts,
                editor,
                appendPost,
                handleChange,
                showSubmit,
              }}
            />
          ) : (
            <></>
          )}
          {/* suggestions section */}
          {FilterMode.UPDATES === filterMode ? (
            <UpdatesSection
              {...{
                suggestedThoughts: [],
                replies: replyConnections,
                suggestionsLoading: true,
              }}
            />
          ) : (
            <></>
          )}
          {/* matches section */}

          {FilterMode.CHAT === filterMode ? <PeopleSection {...{ myOwnPosts }} /> : <></>}
          {/* suggested section */}
          {FilterMode.SUGGESTIONS === filterMode ? <SuggestedThreads /> : <></>}
        </div>
      )}
    </div>
  )
}

export default ThoughtList
