import { TextPost } from "../../../ReactContexts/PostContext"
import AllUpdates from "./AllUpdates/AllUpdates"
import "./UpdatesSection.css"

const UpdatesSection = ({
  suggestedThoughts,
  replies,
  suggestionsLoading,
}: {
  suggestedThoughts: TextPost[]
  replies
  suggestionsLoading: boolean
}) => {
  return (
    <div className="updates-section-container">
      {/* mega updates section */}
      <div className="app-section-header">Updates</div>
      <AllUpdates />
    </div>
  )
}

export default UpdatesSection
