import { logEvent } from "firebase/analytics"
import { signInWithPopup } from "firebase/auth"
import { useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import AuthContext from "../../ReactContexts/AuthContext"
import { analytics, auth, provider } from "../App"
import "./InvitePage.css"

const InvitePage = ({ code }: { code?: string }) => {
  const { person } = useContext(AuthContext)
  const navigate = useNavigate()
  const { placeId } = useParams()
  useEffect(() => {
    if (code && person) {
      const object = {
        timestamp: Date.now(),
        personEmail: person?.email,
        personGoogleDisplayName: person?.displayName,
        personId: person?.uid,
        inviteCode: code,
      }
      logEvent(analytics, code + "-invite-page-open", object)
      localStorage.setItem(code + "-logged-in", String(Date.now()))
    }
  }, [code, person])

  return (
    <div className="invite-page-container">
      <h1> Welcome to Plexus.</h1>
      <div className="invite-page-explanation">
        It's our pleasure to invite you to our thought network,
        <br></br>built for wild thinkers and writers.
      </div>
      <a
        onClick={() => {
          if (!person) {
            logEvent(analytics, code + "-invite-page-click", {
              timestamp: Date.now(),
              personEmail: person?.email,
              personGoogleDisplayName: person?.displayName,
              personId: person?.uid,
              inviteCode: code,
            })

            //go to loading page
            navigate("/p/" + (placeId ?? "forum") + "/authenticating")
            //used to redirect
            signInWithPopup(auth, provider).then((val) => {
              //signed in
              const person = val?.user
              logEvent(analytics, code + "invite-page-sign-in", {
                timestamp: Date.now(),
                personEmail: person?.email,
                personGoogleDisplayName: person?.displayName,
                personId: person?.uid,
                person: person,
                inviteCode: code,
              })
            })
            //email verification link
          } else {
            navigate("/")
            logEvent(analytics, code + "-invite-page-click-but-already-logged-in", {
              timestamp: Date.now(),
              personEmail: person?.email,
              personGoogleDisplayName: person?.displayName,
              personId: person?.uid,
              inviteCode: code,
            })
          }
        }}
        className="login-with-google"
        style={{ width: "fit-content" }}
      >
        Come Inside
      </a>
    </div>
  )
}

export default InvitePage
