import { useState } from "react"
import DataCollectorBI from "./DataCollectorBI"
import DataProcessorBI from "./DataProcessorBI"
import InviterBI from "./InviterBI"
import "./DetailedViewBI.css"
import ScrapeBI from "./ScrapeBI"
import SuggestBI from "./SuggestBI"
import OpenAiBI from "./OpenAiBI"
import FormatBI from "./FormatBI"

const DetailedViewBI = ({ thoughts }) => {
  const [detailedTabView, setDetailedTabView] = useState<string>("invite")
  const [selectedTexts, setSelectedTexts] = useState([])

  return (
    <div className="detailedViewContainer">
      <div className="detailedViewSidebar">
        <div
          className={"menuBarItem " + `${detailedTabView === "get" ? "currentTab" : ""}`}
          onClick={() => setDetailedTabView("get")}
        >
          <span> GET </span>
        </div>
        <div
          className={"menuBarItem " + `${detailedTabView === "process" ? "currentTab" : ""}`}
          onClick={() => setDetailedTabView("process")}
        >
          <span> PROCESS </span>
        </div>
        <div
          className={"menuBarItem " + `${detailedTabView === "metaphor" ? "currentTab" : ""}`}
          onClick={() => setDetailedTabView("metaphor")}
        >
          <span> METAPHOR </span>
        </div>
        <div
          className={"menuBarItem " + `${detailedTabView === "scrape" ? "currentTab" : ""}`}
          onClick={() => setDetailedTabView("scrape")}
        >
          <span> SCRAPE </span>
        </div>
        <div
          className={"menuBarItem " + `${detailedTabView === "suggest" ? "currentTab" : ""}`}
          onClick={() => setDetailedTabView("suggest")}
        >
          <span> SUGGEST </span>
        </div>
        <div
          className={"menuBarItem " + `${detailedTabView === "openai" ? "currentTab" : ""}`}
          onClick={() => setDetailedTabView("openai")}
        >
          <span> OPENAI </span>
        </div>
        <div
          className={"menuBarItem " + `${detailedTabView === "write" ? "currentTab" : ""}`}
          onClick={() => setDetailedTabView("write")}
        >
          <span> WRITE </span>
        </div>
      </div>
      <div className="detailedViewMainArea">
        {detailedTabView === "get" ? (
          <DataCollectorBI selectedTexts={selectedTexts} setSelectedTexts={setSelectedTexts} />
        ) : (
          <></>
        )}
        {detailedTabView === "process" ? (
          <DataProcessorBI
            selectedTexts={selectedTexts}
            setSelectedTexts={setSelectedTexts}
            thoughts={thoughts}
          />
        ) : (
          <></>
        )}
        {detailedTabView === "metaphor" ? <InviterBI /> : <></>}
        {detailedTabView === "scrape" ? <ScrapeBI /> : <></>}
        {detailedTabView === "suggest" ? <SuggestBI /> : <></>}
        {detailedTabView === "openai" ? <OpenAiBI /> : <></>}
        {detailedTabView === "write" ? <FormatBI /> : <></>}
      </div>
    </div>
  )
}

export default DetailedViewBI
