import { useEffect, useState } from "react"

const PhoneVerificationForm = ({ onSave, phone }) => {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    let value = phoneNumber.replace(/\D/g, "") // remove non digits
    let zip = value.substring(0, 3)
    let middle = value.substring(3, 6)
    let last = value.substring(6, 10)

    if (value.length > 6) {
      value = `(${zip}) ${middle}-${last}`
    } else if (value.length > 3) {
      value = `(${zip}) ${middle}`
    } else if (value.length > 0) {
      value = `(${zip}`
    }

    setPhoneNumber(value)
  }, [phoneNumber])

  const handleChange = (e) => {
    if (e.target.value.replace(/\D/g, "").length <= 10) {
      // only change the state if length is <= 10
      setPhoneNumber(e.target.value)
    }
  }

  const handleSave = () => {
    // A simple phone number validation check
    const phoneRegexp = /^\(\d{3}\) \d{3}-\d{4}$/

    if (!phoneRegexp.test(phoneNumber)) {
      setError("Invalid phone number. Please enter a valid phone number.")
      return
    }

    // Clear any previous error
    setError("")

    // Remove non-digit characters before passing the phoneNumber
    const parsedPhoneNumber = phoneNumber.replace(/\D/g, "")

    // Call the onSave function with the phoneNumber
    onSave(parsedPhoneNumber)
    window.alert(
      "Welcome! Your phone number has been saved, you can now text Plexus your thoughts."
    )
    window.alert("To get started, add Plexus as a contact in your phone. \n\n+1 (234) 562-2137.")
    window.alert(
      "Once you've added Plexus as a contact \n(again: +1 (234) 562-2137)\n send Plexus a message containing a new thought you'd like to express, and it'll get added to your Thoughts page!"
    )
  }

  return (
    <div>
      <input
        type="tel"
        value={phoneNumber}
        onChange={handleChange}
        placeholder={phone ? "Current: " + phone.slice(2) : "E.g. 123-456-7890"}
      />
      <button onClick={handleSave}>Set up your cell #</button>
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  )
}

export default PhoneVerificationForm
