import { useEffect, useMemo, useState } from "react"
import {
  addInviteData,
  backendGetFirebaseDataFromPath,
  deleteInviteData,
} from "../../../../Firebase/FirebaseFunctionPointers"
import { sendBeautifulInvite } from "../../../../SendEmail"

const FormatBI = () => {
  const [queryContent, setQueryContent] = useState<string>("")
  const [invitation, setInvitation] = useState<any>([])
  const [c, setC] = useState(0)
  // Loads in initial data
  useMemo(() => {
    backendGetFirebaseDataFromPath("invites/final").then((result) => {
      setInvitation(Object.entries(result))
      console.log(Object.entries(result))
    })
  }, [])

  const handleMessageFormChange = (message) => {
    setQueryContent(message)
  }

  useEffect(() => {
    if (invitation.length > 0) {
      setQueryContent(formatInvitation(invitation[c][1]))
    }
  }, [invitation, c])

  // The invitation output seen in the textarea
  const formatInvitation = (inviteObject) => {
    console.log(inviteObject)
    let a =
      `Hello,\n\nI came across your writing and had to reach out. Specifically, this:\n
    "${inviteObject.plexus_query.trim()}" \nD${
        inviteObject.openai[0]
      }.. I’ve come across a couple writers recently who are going through similar things and writing about them too, including ${
        inviteObject.plexus_suggestions.authorName
      }:\n
    "...${inviteObject.plexus_suggestions.text}"` +
      // \n${inviteObject.openai[1]}\n\n
      `\n\nI came across this thought in Plexus - a new kind of social network I’ve been building that’s designed for authentic conversation (An invite if you’re interested: https://plexus.earth/invite12 ).` +
      " Anyway, just writing to say really enjoyed what you put out. Keep rocking\n\n—Micah"

    return a
  }

  // Handles publishing firebase object on backend
  const saveProcessedInvites = async (invitation, path: string) => {
    let invitationId = invitation[0]
    let invitationContent = invitation[1]
    invitationContent.body = queryContent
    console.log(path)
    if (path === "sents") {
      console.log(invitationContent.body, invitationContent.email_address[0])
      sendBeautifulInvite(invitationContent.email_address[0], invitationContent.body)
    }
    // Sends thought data to a new object in firebase
    console.log([invitationContent])
    await addInviteData(path, [invitationContent])
    // // Deletes thought data from
    await deleteInviteData("final", invitationId)
    setC(c + 1)
  }

  return (
    <div className="dataProcessingContainer">
      <div className="dataProcessingAreaInviter">
        <h2>
          {" "}
          Format Invites ({c + 1} / {invitation.length}){" "}
        </h2>
        <div className="analyisTextAreaContainer">
          <textarea
            className="analysisTextArea"
            placeholder="Completed Invite Data Will Appear Here"
            value={queryContent}
            onChange={(e) => handleMessageFormChange(e.currentTarget.value)}
          ></textarea>
        </div>
        {c < invitation.length ? (
          <>
            <div className="inviteEmailContainer">
              {` ${invitation[c][1].email_address[0]}`} <a href={invitation[c][1].url}>Link</a>
            </div>

            <div className="inviteButtonContainer">
              <div className="processingButtonContainer">
                <button
                  onClick={() => {
                    saveProcessedInvites(invitation[c], "sents")
                  }}
                >
                  {" "}
                  Send{" "}
                </button>
              </div>
              <div className="processingButtonContainer">
                <button
                  onClick={() => {
                    saveProcessedInvites(invitation[c], "discarded")
                  }}
                >
                  {" "}
                  Discard{" "}
                </button>
              </div>
              {
                <div className="processingButtonContainer">
                  <button
                    onClick={() => {
                      if (c) {
                        setC(c - 1)
                      }
                    }}
                  >
                    {" "}
                    Prev{" "}
                  </button>
                </div>
              }
              {c < invitation.length - 1 ? (
                <div className="processingButtonContainer">
                  <button
                    onClick={() => {
                      setC(c + 1)
                    }}
                  >
                    {" "}
                    Next{" "}
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default FormatBI
