//reply utilities
//this is functionality for queries related to threading/replying (written 2/8 in the context of threading)

import { backendWriter } from "../Components/App"
import { getEdgeAuthor } from "../Logic/ConnectionLogic"
import {
  ConnectionKind,
  ConnectionMap,
  DirectionalEdgeMap,
  EdgeInfo,
  EdgeInfoWithConnectionData,
  EdgeKind,
  PostMap,
  TextPost,
} from "../ReactContexts/PostContext"

/**
 * Get all the thought ids for replies to the given parent thought
 * @param thought
 * @returns
 */
export function getReplyThoughtIdsFromParent(thought: TextPost): string[] {
  return getIncomingThoughtIdsFromParent(thought, ConnectionKind.REPLY)
}

const getIncomingThoughtIdsFromParent = (thought: TextPost, edgeKind: ConnectionKind): string[] => {
  //all outbound connections, (outbound is direction toward the replies/children)
  const inbound: DirectionalEdgeMap = thought?.connections?.inbound ?? {}
  //for all the outbound edges, get all the ones of type reply.
  const thoughtIdArr = Object.entries(inbound)
    .map((nextThoughtEntry: [string, ConnectionMap]): string => {
      const [nextThoughtId, nextThoughtConnections] = nextThoughtEntry
      //only return nextThoughtId if nextThoughtConnections contains replies
      //set up a testing environment
      if (connectionMapContainsEdgeKind(nextThoughtConnections, edgeKind)) return nextThoughtId
      return null
    })
    .filter((e) => e)
  return thoughtIdArr
}

/**
 * Get all the thought ids for replies to the given parent thought
 * @param thought
 * @returns
 */
export function getPromptThoughtIdFromChild(thought: TextPost): string {
  //all outbound connections, (outbound is direction toward the replies/children)
  const outbound: DirectionalEdgeMap = thought?.connections?.outbound ?? {}
  //for all the outbound edges, get all the ones of type reply.
  const thoughtIdArr = Object.entries(outbound)
    .map((nextThoughtEntry: [string, ConnectionMap]): string => {
      const [nextThoughtId, nextThoughtConnections] = nextThoughtEntry
      if (
        Object.values(nextThoughtConnections).filter((e) => e.edgeKind === ConnectionKind.REPLY)
          .length > 0
      ) {
        return nextThoughtId
      } else return undefined
    })
    .filter((e) => e)
  return thoughtIdArr.length > 0 ? thoughtIdArr[0] : undefined
}

export function getMostRecentPrompt(thought: TextPost):
  | {
      thoughtId: string
      type: ConnectionKind | EdgeKind
      edge: EdgeInfo
    }
  | undefined {
  //all outbound connections, (outbound is direction toward the reply-parents)
  const inbound: DirectionalEdgeMap = thought?.connections?.outbound ?? {}
  //for all the outbound edges, get all the ones of type reply.
  let edges: EdgeInfo[] = []
  Object.values(inbound).forEach((nextThoughtEntry: ConnectionMap) => {
    const theseConnections = Object.values(nextThoughtEntry).filter(
      (e) => e.edgeKind === ConnectionKind.REPLY
    )
    edges = [...edges, ...theseConnections]
  })

  const sortedEdges = edges
    .filter((e) => e)
    .sort((a: EdgeInfo, b: EdgeInfo) => b.timestamp - a.timestamp)

  const theOne = sortedEdges.length > 0 ? sortedEdges[0] : undefined
  //sourceId is the node id from which the edge was drawn... which is the "breadcrumb context" for this node
  //type depends
  return theOne
    ? { type: theOne.edgeKind, thoughtId: theOne.targetThoughtId, edge: theOne }
    : undefined
}

/**
 * Get textposts from thought id arr
 * @param ids
 * @param posts
 * @returns
 */
export function getThoughtsFromIds(ids: string[], posts: PostMap) {
  return ids.map((e) => getThoughtFromId(e, posts)).filter((e) => e)
}

export const getThoughtFromId = (id: string, posts: PostMap) =>
  id in posts ? posts[id] : undefined

/**
 * This function is used to get all of the reply thoughts to a given thought
 *  Written, for first use, in the deleteThoughtsAndReplies function
 * @param thought the parent thought, for which we want to retrieve replies
 * @param posts a list of all the posts in the whole shebang (helpful for ensuring each id corresponds to a real post still)
 * @returns an arr of replies
 */
export const getReplyThoughtsFromParent = async (thought: TextPost): Promise<TextPost[]> => {
  let replies = await backendWriter.queryByIds(getReplyThoughtIdsFromParent(thought) ?? [])
  return replies.reverse()
}

export function thoughtIsReply(thought: TextPost): boolean {
  return thought?.isReply
}

const connectionMapContainsEdgeKind = (
  map: ConnectionMap,
  edgeKind: ConnectionKind = ConnectionKind.REPLY
) => {
  return Object.values(map ?? {}).filter((edge) => edge.edgeKind === edgeKind).length > 0
}

export function getFlatEdgeMapFull(
  dirEdgeMap: DirectionalEdgeMap,
  edgeKind?: ConnectionKind
): {
  [edgeId: string]: EdgeInfoWithConnectionData
} {
  const flatOne: { [edgeId: string]: EdgeInfoWithConnectionData } = Object.entries(
    dirEdgeMap
  ).reduce((obj, otherThoughtEntry) => {
    //see if there's a reply in there
    const [otherThoughtId, otherConnectionMap] = otherThoughtEntry

    //get all edges from the map
    const edgesOfDirThoughtPair = Object.entries(otherConnectionMap)

    const repliesOfDirThoughtPair = edgesOfDirThoughtPair.filter(
      (edgeMap) => !edgeKind || edgeMap[1].edgeKind === edgeKind
    )
    const allReplies = repliesOfDirThoughtPair.reduce((map, next) => {
      //edge id
      return { ...map, [next[0]]: { ...next[1] } }
    }, {})
    return { ...obj, ...allReplies }
  }, {})
  return flatOne
}

export const getEdgeArrFromThought = (
  thought: TextPost,
  edgeKind?: ConnectionKind,
  inbound?: "inbound" | "outbound"
) => {
  return thought?.connections ? getFullEdgeMapArr(thought?.connections[inbound], edgeKind) : []
}
export const getEdgeMapFromThought = (
  thought: TextPost,
  edgeKind?: ConnectionKind,
  inbound?: "inbound" | "outbound"
) => {
  return thought?.connections ? getFlatEdgeMapFull(thought?.connections[inbound], edgeKind) : []
}

export const getFullEdgeMapArr = (dirEdgeMap: DirectionalEdgeMap, edgeKind?: ConnectionKind) =>
  Object.values(getFlatEdgeMapFull(dirEdgeMap, edgeKind))

// export const getFullEdgeMapEntries = (dirEdgeMap: DirectionalEdgeMap, edgeKind?: ConnectionKind) =>
// Object.entries(getFlatEdgeMapFull(dirEdgeMap, edgeKind))

//flatten connection map
export function getFlatEdgeMap(
  dirEdgeMap: DirectionalEdgeMap,
  edgeKind?: ConnectionKind
): {
  [otherThoughtId: string]: EdgeInfo
} {
  const flatOne: { [otherThoughtId: string]: EdgeInfo } = Object.entries(dirEdgeMap).reduce(
    (obj, otherThoughtEntry) => {
      //see if there's a reply in there
      const [otherThoughtId, otherConnectionMap] = otherThoughtEntry

      //get all edges from the map
      const edgesOfDirThoughtPair = Object.entries(otherConnectionMap)

      const repliesOfDirThoughtPair = edgesOfDirThoughtPair.filter(
        (e) => !edgeKind || e[1].edgeKind === edgeKind
      )
      const firstReply: EdgeInfo =
        repliesOfDirThoughtPair.length > 0 ? repliesOfDirThoughtPair[0][1] : undefined //get the first
      // const mapForTheseReplies = convertArrToObj(repliesOfDirThoughtPair)
      if (firstReply) return { ...obj, [otherThoughtId]: firstReply }
      else return obj
    },
    {}
  )
  return flatOne
}

export function convertArrToObj(arr: any[]): Object {
  return arr.reduce((mapSoFar, nextOne) => ({ ...mapSoFar, [nextOne[0]]: nextOne[1] }), {})
}
