import { useEffect, useState } from "react"
import "./App.css"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import LandingPage, { inviteCodes, stringContainsAnyOf } from "./LandingPage/LandingPage"
import AuthContext from "../ReactContexts/AuthContext"
// Initialize Firebase within the app
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import firebaseConfig from "../Firebase/FirebaseConfig"

import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  User,
} from "firebase/auth"
import PrivateRoute from "./PrivateRoute/PrivateRoute"
import Demos from "./LandingPage/Demos"

import HackyAdmin from "./HackyAdmin/HackyAdmin"
import HackyAdminLastWeek from "./HackyAdmin/HackyAdminLastWeek"
import ThoughtImporter from "./HackyAdmin/ThoughtImporter"
import Orientation from "./Orientation/Orientation"
import Authenticating from "./PrivateRoute/Authenticating"
import InvitePage from "./InvitePage/InvitePage"
import FirebaseWriter from "../Firebase/FirebaseWriter"
import ConnectionAdminStats from "./HackyAdmin/ConnectionAdminStats"
import FirstThoughtRequest from "./Orientation/FirstThoughtRequest"
import WalkAdmin from "./HackyAdmin/components/WalkAdmin/WalkAdmin"

export const localStorageInviteCodeKey = "plexus-invite-code"

//Firebase global variables
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth()
export const provider = new GoogleAuthProvider()

export interface SingleThoughtBreadcrumb {
  id: string
  // authorId: string
  // thoughtPreviewText: string
  // authorName: string
}
export type ThoughtBreadcrumbsI = SingleThoughtBreadcrumb[]
export let ThoughtBreadcrumbs: ThoughtBreadcrumbsI = []

//for email verification link setup
export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "https://plexus.earth/",
  // This must be true.
  handleCodeInApp: true,
}
//initialize it but don't define it yet
export let backendWriter: FirebaseWriter = new FirebaseWriter(
  undefined,
  undefined,
  undefined,
  undefined,
  undefined
)

export const emailSignInProcess = () => {
  let email = window.prompt("What's your email?")
  if (email.includes("@")) {
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email)
        console.log(
          "Email sent! If you don't see it immediately, make sure to check your spam folder."
        )
        // ...
      })
      .catch((error) => {
        const errorMessage = error.message
        console.warn("errorMessage: " + errorMessage)

        // ...
      })
  }
}

//a global variable storing data about the logged-in person (tend to avoid "user" language. only two industries where customers are called "users" are high tech and hard drugs.)
// export let person: User

// top level plexus react component
function App() {
  const [person, setPerson] = useState<User>()
  const [personName, setPersonName] = useState<string>()

  //authentication callback

  //three steps:
  //get the user from the auth callback
  //use the user object to get the name info
  //once we have the name info

  //auth > setPerson
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("auth state change result", Date.now())
        console.log({ user })
        setPerson(user)
      }
    })
  }, [])

  useEffect(() => {
    //check if person exists already. if so, let them in directly. if not, onboarding flow.
    if (stringContainsAnyOf(window.location.href, inviteCodes))
      localStorage.setItem(localStorageInviteCodeKey, inviteCodes[0])
  }, [])
  //const personbucket

  return (
    <AuthContext.Provider value={{ person, setPerson, personName, setPersonName }}>
      <BrowserRouter>
        <Routes>
          {/* /p is the path for any bounded domain, including the main domain */}
          <Route path="/p/:placeId/authenticating" element={<Authenticating person={person} />} />
          <Route path="/p" element={<PrivateRoute {...{ person, setPerson, personName }} />}>
            <Route
              path=":placeId"
              element={<PrivateRoute {...{ person, setPerson, personName }} />}
            >
              <Route
                path="orientation"
                element={<PrivateRoute {...{ person, setPerson, personName, orientation: true }} />}
              />
              <Route
                path="oldChats"
                element={<PrivateRoute {...{ person, setPerson, personName }} />}
              />

              <Route
                path=":thoughtId"
                element={<PrivateRoute {...{ person, setPerson, personName }} />}
              />
              <Route
                path="chat/:conversationIdLink"
                element={<PrivateRoute {...{ person, setPerson }} />}
              />
            </Route>
          </Route>
          {/* <Route */}
          <Route path="/" element={<LandingPage />} />
          {/* a path that locks on the landing page, even if the person is already logged in 
          helpful if you're logged in but, as a dev, are trying to make / observe landing page changes */}
          <Route path="landing" element={<LandingPage />} />
          <Route path="invite13" element={<LandingPage />} />
          <Route path="invite12" element={<LandingPage />} />

          <Route path="micah-invite" element={<InvitePage code={"micah-invite"} />}></Route>
          <Route path="invite/micah" element={<InvitePage code={"micah"} />} />

          {/* <Route path="/import" element={<LiveImports />}></Route> */}
          <Route path="admin" element={<WalkAdmin />}>
            <Route path=":placeId" element={<WalkAdmin />} />
          </Route>
          <Route path="hackyAdmin" element={<HackyAdmin />}>
            <Route path=":placeId" element={<HackyAdmin />} />
          </Route>
          <Route path="connectionAdminStats" element={<ConnectionAdminStats />} />

          <Route path="adminLastWeek" element={<HackyAdminLastWeek />}>
            <Route path=":placeId" element={<HackyAdminLastWeek />} />
          </Route>
          <Route path="thoughtImporter" element={<ThoughtImporter />}>
            <Route path=":placeId" element={<ThoughtImporter />} />
          </Route>
          <Route path="adminPanel" element={<ThoughtImporter />}>
            <Route path=":placeId" element={<ThoughtImporter />} />
          </Route>
          {/* a page with a list of loom Plexus demos (including one for r/auntienetwork :)  */}
          <Route path="demos" element={<Demos />} />
          <Route path="tc" element={<Navigate to="/p/tc" />} />
          <Route path="orientation" element={<Orientation />}></Route>
          <Route
            path="riddle"
            element={<FirstThoughtRequest {...{ actuallyAttemptingToGainEntry: true }} />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  )
}

export default App
