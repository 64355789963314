import { TextPost } from "../ReactContexts/PostContext"

// return a strng with ellipsis at a cut-off point
export const abbreviate = (text: string, maxChars: number) =>
  text?.length > maxChars ? text.slice(0, maxChars) + "..." : text

export const replyIsSuggestable = (thought: TextPost) => {
  return thought.text.split(" ").length > 8
}

export const timestamp = (post: TextPost) => {
  return post.timestamp ?? 0
}
