import moment from "moment"
import { useEffect, useState } from "react"
import { TextPost } from "../../../ReactContexts/PostContext"
import { getAllConnectionsInTimeWindow } from "../ConnectionAdminStats"
import { retrieveAuthorEmail } from "../functions/sortData"
import "./AdminMatchesMadeViewer.css"

export default function AdminMatchesMadeViewer({ thoughts }: any) {
  const [minInputTime, setMinInputTime] = useState<number>(0)
  const [maxInputTime, setMaxInputTime] = useState<number>(moment().valueOf())
  const [showContributorEmails, setShowContributorEmails] = useState<boolean>(false)
  const [showThoughtContributorEmails, setShowThoughtContributorEmails] = useState<boolean>(false)

  const [showInactiveThoughtEmails, setShowInactiveThoughtEmails] = useState<boolean>(false)

  const [showActiveThoughtEmails, setShowActiveThoughtEmails] = useState<boolean>(false)

  const [weeksAgo, setWeeksAgo] = useState<number>(0)
  const [weeksAgoTimestamp, setWeeksAgoTimestamp] = useState<number>(604800000 * weeksAgo)

  // Default values
  useEffect(() => {
    setMinInputTime(lastSaturday)
    setMaxInputTime(Infinity)
  }, [])
  useEffect(() => {
    const oneWeekEpoch = 604800000
    setWeeksAgoTimestamp(oneWeekEpoch * weeksAgo)
  }, [weeksAgo])

  const today = moment().valueOf()
  const lastSaturday = moment().startOf("isoWeek").subtract(2, "d").valueOf()
  const twoSaturdaysAgo = moment().startOf("isoWeek").subtract(9, "d").valueOf()

  const getTotalUniqueContributors = (thoughts: TextPost[]): string[] => {
    const unique: string[] = [...new Set(thoughts.map((thought: TextPost) => thought.authorId))] // [ 'A', 'B']
    return unique
  }

  // Returns the combined (outer-joined) contributors
  const getAllContributors = (thoughts, minTimestamp, maxTimestamp) => {
    // Collecting array of unique authorIds of thought contributors
    const thoughtContributors = getTotalUniqueContributors(
      thoughts.filter((thought) => {
        return thought.timestamp > minTimestamp && thought.timestamp < maxTimestamp
      })
    )
    // Combined unique author Ids
    return [...new Set(thoughtContributors)]
  }

  const getActiveContributors = (getInactive?: boolean, weeksAgoModifier: number = 0) => {
    let todayStartDate = today
    if (weeksAgoModifier) {
      todayStartDate = moment()
        .startOf("isoWeek")
        .subtract(2, "d")
        .subtract(weeksAgo - 1, "w")
        .valueOf()
    }

    const thisWeekContributors = getTotalUniqueContributors(
      thoughts.filter((thought: TextPost) => {
        return (
          thought.timestamp > lastSaturday - weeksAgoModifier && thought.timestamp < todayStartDate
        )
      })
    )

    const lastWeekContributors = getTotalUniqueContributors(
      thoughts.filter((thought: TextPost) => {
        return (
          thought.timestamp > twoSaturdaysAgo - weeksAgoModifier &&
          thought.timestamp < lastSaturday - weeksAgoModifier
        )
      })
    )

    // Removes all people from lastWeekContributors that exist in this thisWeekContributors
    // Thus keeping all people who were active last week but not this week.
    if (getInactive) {
      return lastWeekContributors.filter((element) => !thisWeekContributors.includes(element))
    }

    // Only returns elements that are present in both arrays
    return thisWeekContributors.filter((element) => lastWeekContributors.includes(element))
  }

  return (
    <>
      <div>
        <h2> Dynamic: </h2>
        <span>Start Date: </span>
        <input
          type="date"
          id="startDateInput"
          onChange={(event) => setMinInputTime(moment(event.target.value).valueOf())}
        />
        <br></br>
        <span>End Date: </span>
        <input
          type="date"
          id="endDateInput"
          onChange={(event) => setMaxInputTime(moment(event.target.value).valueOf())}
        ></input>
        <br></br>
        <br></br>
        <span
          className="interactableAdminElement"
          onClick={() => {
            setShowThoughtContributorEmails(!showThoughtContributorEmails)
          }}
        >
          {" "}
          Total number of thought contributors:{" "}
        </span>
        {showThoughtContributorEmails ? (
          getTotalUniqueContributors(
            thoughts.filter((thought) => {
              return thought.timestamp > minInputTime && thought.timestamp < maxInputTime
            })
          ).map((authorId, i) => {
            return <div>{retrieveAuthorEmail(thoughts, authorId)}</div>
          })
        ) : (
          <></>
        )}
        {getAllContributors(thoughts, minInputTime, maxInputTime).length}
        {showContributorEmails ? (
          getAllContributors(thoughts, minInputTime, maxInputTime).map((authorId, i) => {
            return (
              <div>
                {i + 1}. {retrieveAuthorEmail(thoughts, authorId)}
              </div>
            )
          })
        ) : (
          <></>
        )}
        <br></br>
        Total number of thoughts:{" "}
        {
          thoughts.filter((thought) => {
            return thought.timestamp > minInputTime && thought.timestamp < maxInputTime
          }).length
        }{" "}
        <br></br>
        Total number of connections:{" "}
        {getAllConnectionsInTimeWindow(thoughts, minInputTime, maxInputTime).length}
        <h2> Active over 2 weeks (Weeks start on Saturday)</h2>
        <b>
          {" "}
          Showing activity from{" "}
          {weeksAgo === 0 ? " this week." : weeksAgo + ` week${weeksAgo !== 1 ? "s" : ""} ago.`}
        </b>
        <div
          className="interactableAdminElement"
          onClick={() => {
            setShowActiveThoughtEmails(!showActiveThoughtEmails)
          }}
        >
          Total number of thought contributors:
          {getActiveContributors(false, weeksAgoTimestamp).length}
        </div>
        {showActiveThoughtEmails
          ? getActiveContributors(false, weeksAgoTimestamp).map((authorId, i) => {
              return (
                <div key={"thots-active-" + i}> {retrieveAuthorEmail(thoughts, authorId)} </div>
              )
            })
          : ""}
        <button
          onClick={() => {
            setWeeksAgo(weeksAgo + 1)
          }}
        >
          {" "}
          prev{" "}
        </button>{" "}
        <button
          onClick={() => {
            setWeeksAgo(weeksAgo - 1)
          }}
        >
          {" "}
          next{" "}
        </button>
        <h2> Thought Contributors Who Contributed Last Week But Not this Week</h2>
        <div
          className="interactableAdminElement"
          onClick={() => {
            setShowInactiveThoughtEmails(!showInactiveThoughtEmails)
          }}
        >
          {" "}
          Total number of thought contributors:
          {getActiveContributors(true).length}
        </div>
        {showInactiveThoughtEmails
          ? getActiveContributors(true).map((authorId, i) => {
              return <div key={"thots-" + i}>{retrieveAuthorEmail(thoughts, authorId)} </div>
            })
          : ""}
      </div>
    </>
  )
}
