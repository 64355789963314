import { useContext, useEffect, useMemo, useState } from "react"
import AppBody from "./AppBody.tsx/AppBody"
import PostContext, { PostMap, WordMap } from "../ReactContexts/PostContext"
import { getDatabase, ref, onValue, set, query, orderByChild, equalTo } from "firebase/database"

import { DisplaySettings, PersonFirebaseBucket } from "../Firebase/FirebaseWriter"
import { withHistory } from "slate-history"
import { withReact } from "slate-react"
import { createEditor } from "slate"
import { useParams } from "react-router-dom"
import AuthContext from "../ReactContexts/AuthContext"

import { getPlaceholder } from "./Editor/EditorContainer"
import { app, backendWriter } from "./App"
import { FilterMode } from "./Feed/ThoughtList"
import { runOnboardingMessages, usernamex } from "./PrivateRoute/PrivateRoute"

// Other than routing logic (in App.tsx), this component contains the entire web app
// the primary function of this component: loading down data from firebase
function FirebaseDataContainer() {
  const [posts, setPosts] = useState<PostMap>({})
  const [myNodes, setMyNodes] = useState<PostMap>({})

  const [dictionary, setDictionary] = useState<WordMap>({})
  const [loadingPosts, setLoadingPosts] = useState(true)
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.SENT)

  //domainID
  const { placeId, thoughtId } = useParams()
  const { person } = useContext(AuthContext)

  //get whether the person is oriented already
  const [isOriented, setIsOriented] = useState(false)
  const [alerts, setAlerts] = useState()

  //every time thoughtId changes, record it
  const [allBreadcrumbs, setAllBreadcrumbs] = useState([])
  useEffect(() => {
    setAllBreadcrumbs((x) => [...x, { id: thoughtId }])
  }, [thoughtId])

  useEffect(() => {
    if (!person) return
    const domainId = placeId ?? "forum"
    const db = getDatabase()
    const orientationRef = ref(
      db,
      "p/" + domainId + `/people/${person.uid}/orientation/enteredDoor`
    ) //nodes are posts
    onValue(orientationRef, (data) => {
      //get whether the person is oriented
      if (data.exists) {
        setIsOriented(data.val())
      }
    })
    const alertsRef = ref(db, `p/${domainId}/people/${person.uid}/surveys`)
    onValue(alertsRef, (data) => {
      // Get timestamp of last login
      if (data.exists) {
        setAlerts(data.val())
      }
    })
  }, [person.uid, placeId])

  const [personBucket, setPersonBucket] = useState<PersonFirebaseBucket>()

  //update person from firebase
  //person > get firebase info (do this in data container)
  const [displaySettings, setDisplaySettings] = useState<DisplaySettings>(
    personBucket?.displaySettings
  )
  useEffect(() => {
    const db = getDatabase()
    if (!person || !("uid" in person)) return

    const personRef = ref(db, "p/" + (placeId ?? "forum") + "/people/" + person.uid)

    //get person data on the front end
    onValue(personRef, (data) => {
      if (data.exists()) {
        setPersonBucket(data.val())
        setDisplaySettings(data.val()?.displaySettings ?? {})
      } else {
        //otherwise, initiali
        const personBucket: PersonFirebaseBucket = {
          personName: null,
          personEmail: person.email,
        }
        set(personRef, personBucket)

        //otherwise, write to this location with enough stuff
      }
    })

    //grab firebase display name etc.
  }, [person])

  //once person bucket data exist, initialize firebase

  useEffect(() => {
    if (!personBucket) return

    //otherwise, ensure name exists
    let theName: string
    if (personBucket.personName) {
      //either grab the person name from firebase, or ask for a new one
      theName = personBucket.personName
    }
    if (!theName) {
      if (usernamex.length > 1) {
        theName = usernamex
      } else {
        theName = runOnboardingMessages()
      }
    }

    //then, set up firebase writer
    const domainId = placeId ?? "forum"
    const db = getDatabase()
    const domainRef = ref(db, "p/" + domainId) //nodes are posts

    //finally, initialize backend writer
    if (person.uid && person.email) {
      backendWriter.initialize(
        domainRef,
        person.uid,
        person.email,
        theName,
        getPlaceholder(placeId)
      )
      setWriterInitialized(true)
    }
  }, [personBucket])
  const [backendWriterInitialized, setWriterInitialized] = useState(false)

  //update posts from firebase
  useEffect(() => {
    const domainId = placeId ?? "forum"

    const db = getDatabase()
    const postsRef = ref(db, "p/" + domainId + "/nodes") //nodes are posts
    // This runs every time post changes
    onValue(postsRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        setPosts(value)
      }
      setLoadingPosts(false)
    })
  }, [])

  // Updates only posts from the person who is logged in
  useEffect(() => {
    const domainId = placeId ?? "forum"
    const db = getDatabase(app)
    // Create a reference to the "nodes" location in the database
    const nodesRef = ref(db, "p/" + domainId + "/nodes")
    // Query the database to get only the nodes with a specific authorId
    const nodesByAuthor = query(nodesRef, orderByChild("authorId"), equalTo(person.uid))

    // Listen for value changes on the query result
    onValue(nodesByAuthor, (snapshot) => {
      // Get an array of the node objects that match the query
      const nodesArray: PostMap = snapshot.val()
      // Set the state with the array of nodes
      setMyNodes(nodesArray)
    })
  }, [])

  useEffect(() => {
    if (backendWriter && person?.uid) backendWriter.setPersonId(person?.uid)
  }, [person])
  const [editor, setEditor] = useState(() => withHistory(withReact(createEditor())))
  const [personalFilter, setPersonalFilter] = useState(true)
  const [relevanceFilter, setRelevanceFilter] = useState(false)

  const todaysPrompt = useMemo(() => {
    //set in firebase
    const prompt = getPlaceholder(placeId)
    return prompt
  }, [placeId])
  const [testRunComplete, setTestRunComplete] = useState<boolean>(false)
  useEffect(() => {
    if (!testRunComplete) {
      setTestRunComplete(true)
    }
  }, [])
  const [titleThoughtId, setTitleThoughtId] = useState<string>()

  //update every time react changes
  useEffect(() => {
    if (displaySettings) backendWriter.rememberSettings(displaySettings)
  }, [displaySettings])

  return (
    <PostContext.Provider
      value={{
        posts,
        setPosts,
        myNodes,
        setMyNodes,
        dictionary,
        setDictionary,
        personalFilter,
        setPersonalFilter,
        relevanceFilter,
        setRelevanceFilter,
        loadingPosts,
        setLoadingPosts,
        todaysPrompt,
        titleThoughtId,
        setTitleThoughtId,
        filterMode,
        setFilterMode,
        personBucket,
        setPersonBucket,
        allBreadcrumbs,
        setAllBreadcrumbs,
        displaySettings,
        setDisplaySettings,
      }}
    >
      {/* show old chats if url says so */}
      <div className={"App column"}>
        {/* orientation happens inside here, if at all */}
        {backendWriterInitialized ? (
          <AppBody editor={editor} isOriented={isOriented} alerts={alerts} />
        ) : (
          <></>
        )}
      </div>
    </PostContext.Provider>
  )
}

export default FirebaseDataContainer
