import { useEffect, useState } from "react"
import "./PostButton.css"

export const PostButtonDefault = ({ appendPost }: { appendPost: Function }) => {
  const [clicked, setClicked] = useState(false)
  //turn back after two seconds
  useEffect(() => {
    if (clicked) setTimeout(() => setClicked(false), 2000)
  }, [clicked])
  return (
    <div className="post-button-container">
      <div
        className={"post-button" + (clicked ? " clicked" : "")}
        onClick={() => {
          appendPost()
          setClicked(true)
        }}
        title="send: alt + enter"
      >
        {clicked ? "expressing..." : "express"}
      </div>
    </div>
  )
}
