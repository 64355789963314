import { backendWriter } from "../Components/App"
import { getMostRecentPrompt, getThoughtFromId } from "../Firebase/ReplyUtilities"
import { EdgeInfo, PostMap, TextPost } from "../ReactContexts/PostContext"

//get all most recent connections in a row, from given thought
interface EdgeThoughtPair {
  edge: EdgeInfo
  sourceThought: TextPost
}
export const getPathToCurrentThoughtFromEdges = async (
  thought: TextPost
): Promise<EdgeThoughtPair[]> => {
  let crumbs: EdgeThoughtPair[] = []
  let lastThought: TextPost = thought
  let i = 0
  let idsSoFar: { [thoughtId: string]: any } = { [thought.id]: true }
  while (lastThought && i < 100) {
    //get most recent edge if exists
    //replies in particular
    const nextCrumbRaw = getMostRecentPrompt(lastThought)
    if (!nextCrumbRaw) return crumbs
    const [nextCrumbThought] = await backendWriter.queryByIds(nextCrumbRaw.thoughtId)
    if (nextCrumbThought && nextCrumbRaw.edge && !(nextCrumbThought.id in idsSoFar)) {
      //add it
      crumbs.unshift({ edge: nextCrumbRaw.edge, sourceThought: nextCrumbThought })
      lastThought = nextCrumbThought
      idsSoFar[lastThought.id] = true
    } else lastThought = undefined
    i++
  }
  //recursively get a string of the most recent connections

  return crumbs
}
