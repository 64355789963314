import moment from "moment"
import { AuthorContent } from "../../../../Types/types"

const CollectedItem = ({
  content,
  selectionHandler,
  state,
  setState,
}: {
  content: AuthorContent
  selectionHandler: Function
  state: AuthorContent[]
  setState: Function
}) => {
  return (
    <div
      className={"contentContainer " + (content.selected ? `selectedContent` : ``)}
      onClick={() => {
        selectionHandler(content, state, setState)
      }}
    >
      <div className="contentContainerTopRow">
        <span className="contentNameText">
          {" "}
          {"authorName" in content ? content.authorName : ""}{" "}
        </span>
        <span className="contentTimeText"> {moment(content.timestamp).fromNow()} </span>
      </div>
      <div>{content.text}</div>
    </div>
  )
}

export default CollectedItem
