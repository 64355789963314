import { useMemo, useState } from "react"
import {
  backendGetFirebaseDataFromPath,
  backendMetaphorReturnLinks,
  backendScrapeWebsiteAddSuggestions,
} from "../../../../Firebase/FirebaseFunctionPointers"
import { MetaphorScraped } from "../../../../Types/types"

const SuggestBI = () => {
  const [items, setItems] = useState<MetaphorScraped>()
  useMemo(() => {
    backendGetFirebaseDataFromPath("invites/scraped").then((result) => {
      setItems(result)
    })
  }, [])

  const getSuggestions = async () => {
    // if (typeof(url) === "object") url.splice(10)
    await backendScrapeWebsiteAddSuggestions().catch((e) => {
      console.error(e)
    })
  }

  return (
    <div className="dataProcessingContainer">
      <div className="dataProcessingAreaInviter">
        <h2> Suggestion Grabber </h2>
        <div className="processingButtonContainer">
          <button
            onClick={() => {
              getSuggestions().then((result) => {
                console.log("result")
              })
            }}
          >
            {" "}
            Get Suggestions{" "}
          </button>
        </div>
        <ol>
          {items
            ? Object.values(items).map((item) => {
                return (
                  <div className="scrapedDataWrapper">
                    <ul>
                      <li>{item.base_url}</li>
                      <li>{item.email_address[0]} </li>
                      <li>{item.plexus_query}</li>
                    </ul>
                  </div>
                )
              })
            : ""}
        </ol>
      </div>
    </div>
  )
}

export default SuggestBI
