import { useContext, useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import "./LandingPage.css"

//auth 1
import AuthContext from "../../ReactContexts/AuthContext"
import { logEvent } from "firebase/analytics"
import { analytics, auth, provider } from "../App"
import { signInWithPopup } from "firebase/auth"
import { getDatabase, onValue, ref } from "firebase/database"
import { PersonFirebaseBucket } from "../../Firebase/FirebaseWriter"
import { ArrowLeft, ArrowRight } from "phosphor-react"
export const inviteCodes = ["invite13", "invite12", "mutualthought"]
export const stringContainsAnyOf = (str: string, arr: string[]) => {
  let contains = false
  if (!str) return contains
  arr.forEach((item) => {
    if (str.includes(item)) contains = true
  })
  return contains
}

export const alreadyHereLogic = (personBucket: PersonFirebaseBucket) => {
  const alreadyHere =
    "orientation" in (personBucket ?? {}) &&
    (personBucket.orientation.clickedWelcomePage || personBucket.orientation.enteredDoor)
  return alreadyHere
}
const LandingPage = () => {
  const navigate = useNavigate()
  const url = useLocation()
  const { person } = useContext(AuthContext)

  const { placeId, thoughtId } = useParams()
  const hasInvite = stringContainsAnyOf(window.location.href, inviteCodes)
  const [localPersonBucket, setLocalPersonBucket] = useState<PersonFirebaseBucket>()

  const alreadyHere = alreadyHereLogic(localPersonBucket)
  //check if person is here already
  useEffect(() => {
    const db = getDatabase()
    if (!person || !("uid" in person)) return

    const personRef = ref(db, "p/" + (placeId ?? "forum") + "/people/" + person.uid) //nodes are posts

    //get person data on the front end
    onValue(personRef, (data) => {
      if (data.exists()) setLocalPersonBucket(data.val())
    })
    // "orientation" in (personBucket /?? {})
  }, [person?.uid])

  //If on the home page
  const [triedToEnter, setTried] = useState(false)
  return person && (triedToEnter || alreadyHere) && url.pathname !== "/landing" && !thoughtId ? (
    <Navigate to="/p" />
  ) : (
    <div className="landing-page">
      <div className="landing-page-text">
        <div className="top-part">
          <div className="first-line">a wiki of human experience</div>

          <div
            className="enter-plexus "
            onClick={() => {
              const code = "test"
              setTried(true)
              if (!person) {
                //brings them to orietnation directly
                // if (hasInvite) navigate("/orientation/" + inviteCodes[0])
                // //brings them to riddle page, where they have to type a thought to get in
                // else navigate("/orientation")

                //nothing else here mattters after navigation...
                // if (
                //   !hasInvite &&
                //   !stringContainsAnyOf(window.prompt("Enter invitation code:"), inviteCodes)
                // ) {
                //   window.alert(
                //     "Not the right code! Send a note to davey@plexus.earth if you'd like early access."
                //   )
                //   return
                // }

                //go to loading page
                navigate("/p/" + (placeId ?? "forum") + "/authenticating")
                //used to redirect
                signInWithPopup(auth, provider).then((val) => {
                  //signed in
                  const person = val?.user
                  logEvent(analytics, code + "invite-page-sign-in", {
                    timestamp: Date.now(),
                    personEmail: person?.email,
                    personGoogleDisplayName: person?.displayName,
                    personId: person?.uid,
                    person: person,
                    inviteCode: code,
                  })
                })
                //email verification link
              } else {
                navigate("/")
                logEvent(analytics, code + "-invite-page-click-but-already-logged-in", {
                  timestamp: Date.now(),
                  personEmail: person?.email,
                  personGoogleDisplayName: person?.displayName,
                  personId: person?.uid,
                  inviteCode: code,
                })
              }
            }}
          >
            enter plexus&nbsp;
            <ArrowRight />
          </div>
        </div>

        <br></br>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <br></br>
          <div className="link-row">
            <a href="https://igtqvlvn0xr.typeform.com/to/JlenpCcN">waitlist</a>
            <a href="https://plexus.substack.com/p/scaling-intimacy">purpose</a>
            {/* <Link to={"/demos"}>demos</Link> */}
            <a href="https://innovative-phosphorus-3ab.notion.site/Joining-Plexus-370dd63ea16d463e8b34ec629625d344">
              careers
            </a>
            {/* <div
              onClick={() => {
                if (!person) {
                  navigate("/invite13")
                } else navigate("/")
              }}
              className="log-on-text"
              style={{ width: "fit-content" }}
            >
              login
            </div> */}
          </div>
        </div>
      </div>
      <span></span>
    </div>
  )
}

export default LandingPage
