import { useContext } from "react"
import AuthContext from "../../../ReactContexts/AuthContext"
import PostContext from "../../../ReactContexts/PostContext"
import { auth, backendWriter } from "../../App"
import "./Settings.css"
import PhoneVerificationForm from "./PhoneVerificationForm"

const Settings = () => {
  const { person } = useContext(AuthContext)
  const { displaySettings, setDisplaySettings, personBucket } = useContext(PostContext)
  let authorObject = {
    authorEmail: person.email,
    authorName: backendWriter.personName,
    authorId: person.uid,
  }
  const savePhoneNumber = (phoneNumber) => {
    backendWriter.recordPersonPhone(phoneNumber, authorObject)
  }
  return (
    <div className="settings-container">
      <div className="app-section-header">Settings</div>

      <button
        onClick={() => {
          if (window.confirm("log out of " + person.email + "?")) {
            auth
              .signOut()
              .then(() => console.log(person.uid, person.email, "signed out"))
              .catch(() => console.warn(person.uid, person.email, "error signing out"))
            window.location.reload()
          }
        }}
      >
        Log out ({person.email})
      </button>
      <button
        onClick={() => {
          window.location.href = "mailto:davey@plexus.earth?subject=Plex Feedback&body=Hey Davey,"
        }}
      >
        Share Feedback
      </button>
      <button
        onClick={() => {
          window.location.href = "https://plexus.substack.com"
        }}
      >
        See Plexus Blog
      </button>
      <button
        onClick={() => {
          window.location.href = "https://plexus.earth/p/forum/-NU-mLJzKlF7FKs5Gbrr"
        }}
      >
        Plexus Feedback Thread
      </button>
      <button
        onClick={() => {
          setDisplaySettings((x) => ({ ...x, showThoughtTitles: !x?.showThoughtTitles }))
        }}
      >
        {!displaySettings?.showThoughtTitles ? "Show thought titles" : "Hide thought titles"}
      </button>
      {/* <span className="phone-info-container">
        {" "}
        Try out Plexting! Just save your phone number below and you can begin texting Plexus to save
        your thoughts to your account and suggestions for them. <br></br>
      </span> */}
      <PhoneVerificationForm onSave={savePhoneNumber} phone={personBucket?.personPhone} />
      <div
        style={{
          color: "gray",
          fontStyle: "italic",
          fontSize: ".9em",
          marginTop: "-.9em",
          width: "20em",
          textAlign: "center",
        }}
      >
        Add a 10-digit cell number to text thoughts to your Plexus account.
        <br></br> <br></br>
        Plexus: <u>234-562-2137 </u>
      </div>
      {/* <div className="column"></div> */}
    </div>
  )
}

export default Settings
