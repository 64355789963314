import { Navigate, useParams } from "react-router-dom"
import loading from "../../assets/plexusLoader2.gif"

const Authenticating = ({ person }) => {
  const { placeId } = useParams()
  return person ? (
    (() => {
      return <Navigate to={"/p/" + (placeId ?? "")} replace={true} />
    })()
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
      }}
    >
      <img alt="loader" style={{ width: "60px", margin: "4em 1em" }} src={loading}></img>
      waking up...
    </div>
  )
}

export default Authenticating
