import { useEffect, useState } from "react"
import {
  backendMetaphorReturnLinks,
  backendScrapeWebsite,
} from "../../../../Firebase/FirebaseFunctionPointers"

const InviterBI = () => {
  const [queryContent, setQueryContent] = useState<string>("")
  const [inputContent, setInputContent] = useState<string>("")
  const [invitation, setInvitation] = useState<any>([])
  const [c, setC] = useState(0)

  const handleMessageFormChange = (message) => {
    setQueryContent(message)
  }

  const callScraper = async (url: string | string[]) => {
    // if (typeof(url) === "object") url.splice(10)
    await backendScrapeWebsite(url)
      .then((result) => {
        // result = removeWhiteSpace(result)
        setInvitation(result)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  useEffect(() => {
    if (invitation.length > 0) {
      setQueryContent(formatInvitation(invitation[c]))
    }
  }, [invitation])

  const formatInvitation = (inviteObject) => {
    console.log(inviteObject)
    let a = `Hello,\n\nI came across your writing and I had to reach out. Specifically, this struck me:\n
    "${inviteObject.plexus_query.trim()}" ${
      inviteObject.openai_queries[0]
    }.. It immediately reminded me of something my friend ${
      inviteObject.plexus_suggestions.thought.authorName
    } said on Plexus:\n
    "${inviteObject.plexus_suggestions.thought.text}"
    ${inviteObject.openai_queries[1]}

    Sign off message.\n\n
    Micah
    `

    return a
  }

  return (
    <div className="dataProcessingContainer">
      <div className="dataProcessingAreaInviter">
        <h2> Metaphor </h2>
        <div className="analyisTextAreaContainer">
          <textarea
            className="analysisTextArea"
            placeholder="Query metaphor to add potential links to the database."
            value={queryContent}
            onChange={(e) => handleMessageFormChange(e.currentTarget.value)}
          ></textarea>
        </div>
        <div className="processingButtonContainer">
          <button
            onClick={() => {
              backendMetaphorReturnLinks(`${queryContent}`)
            }}
          >
            {" "}
            Go{" "}
          </button>
        </div>
        {c > 0 ? (
          <div className="processingButtonContainer">
            <button
              onClick={() => {
                setC(c - 1)
              }}
            >
              {" "}
              Prev{" "}
            </button>
          </div>
        ) : (
          ""
        )}
        {c < invitation.length ? (
          <div className="processingButtonContainer">
            <button
              onClick={() => {
                setC(c + 1)
              }}
            >
              {" "}
              Next{" "}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default InviterBI
